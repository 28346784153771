// Package imports:
import React from 'react';
import cx from 'classnames';

type LabelTypes = 'origin' | 'disclaimer' | 'delay' | 'closed' | 'beta' | 'new' | 'yes' | 'no' | 'news-release';

interface IProps {
    text: string,
    fullText?: string,
    labelType: LabelTypes,
    size?: 'sm' | 'md' | 'lg',
    onClick?(): void,
    url?: string
};

const Label: React.FC<IProps> = ({
    text,
    labelType,
    size = 'md',
    onClick,
    url
}) => {
    return (
        <div className={cx('KCL_label', `${labelType}-label`, size)} onClick={onClick}>
            {url
                ? <a
                    href={url}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <span>
                        {text}
                    </span>
                </a>
                : <>
                    <span>
                        {text}
                    </span>
                </>
            }
        </div>
    );
}

export default Label;