import React, { useEffect } from "react";
import { DuplicateCheckKeysToDisplay, OrderSubmitRequest } from "../../Types/private-data/NewOrderSubmitRequest";
import Button from "../UI-Elements/Button";
import CheckBox from "../UI-Elements/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { formatNumber } from "../../Utils/Formatting";
import { TranslationManager } from "../../Translation/Translation";
import { FatFingerCheck } from "../../Types/Websocket";
import Alert from "../UI-Elements/Alert";
import { AppStateManager } from "../../StateManager";
import { capitalizeFirstChar, generateUUID } from "../../Utils/Common";
import { toast } from "react-toastify";
import { ICONS } from "../../Utils/Definitions";
import { LMDInterface } from "../../KodiInterface/LMD";
import { PDSErrorHandler } from "../../Utils/PDSUtils";

interface IProps {
    goBack: () => void;
    confirm: (values: OrderSubmitRequest, callback: (result: any) => void) => Promise<void>;
    data: OrderSubmitRequest;
    fatFingerCheck: FatFingerCheck | "no_data" | undefined;
    close: (value: string | null) => void;
    brokerName: string;
}

interface ErrorResponse {
    input: string;
    reason: string;
    text: string;
}
interface SuccessResponse {
    reference_id: string;
    response: string;
}

const DoubleCheckModal: React.FC<IProps> = ({
    goBack,
    confirm,
    data,
    fatFingerCheck,
    close,
    brokerName,
}) => {
    const [checked, setChecked] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [CB_error_type, setCB_error_type] = React.useState<"error" | "success">("error");
    const [showTotal, setShowTotal] = React.useState(true);
    const total = data.price as number * Number(data.quantity);

    const handleClick = async (result: ErrorResponse | SuccessResponse) => {
        let text: string, type: "error" | "success";
        if ('reason' in result && result.reason.includes("ERROR")) {
            text = PDSErrorHandler(result.reason);
            type = "error";
        } else if ('response' in result && result.response === "success") {
            text = TranslationManager.getTranslation().New_Order_Modal.Success_Message;
            type = "success";
            setTimeout(() => {
                close(null);
            }, 1500);
        } else if ('response' in result && result.response === "updated") {
            text = TranslationManager.getTranslation().New_Order_Modal.Update_Message;
            type = "success";
            setTimeout(() => {
                close(null);
            }, 1500);
        } else if ('text' in result) {
            text = result.text;
            type = "error";
        } else {
            text = TranslationManager.getTranslation().New_Order_Modal.Error_Message;
            type = "error";
        }
        const resolver = new Promise(resolve => {
            if (type === null) return;
            if (type === 'success') {
                resolve('success')
            }
            else {
                throw new Error('error')
            }
        }
        )
        toast.promise(resolver, {
            pending: text,
            success: text,
            error: text,
        }, {
            autoClose: type === "success" ? 2000 : false,
            icon: ICONS[type],
        })
        if (type === "success") {
            setCB_error_type("success");
        }
        setLoading(false);
    };
    const checkBody = <>
        <div style={{ margin: '7px 0' }}>
            <CheckBox
                name="activeUsers"
                id="activeUsers"
                value={checked ? 'true' : 'false'}
                onChange={(e) => setChecked(e.target.checked)}
                label={<span style={{ maxWidth: '200px', display: 'inherit' }}>Ignore fat finger check</span>}
            />
        </div>
        <Button showLoader={loading} disabled={!checked || CB_error_type === "success"} buttonType="primary" size="lg" fullWidth noMargin onClick={async () => { setLoading(true); await confirm(data, handleClick) }}>{TranslationManager.getTranslation().Buttons.Confirm_order}</Button>
    </>
    const displayItem = (value: any, key: string) => {
        if (key === 'visible_quantity' || key === 'quantity' || key === 'price') {
            // format numbers
            return formatNumber(value);
        }
        else if (key === 'portfolio_id') {
            //change portfolio id to portfolio name
            return AppStateManager.customerInfo?.portfolios.find(x => x.id === value)?.name;
        }
        else if (key === 'account_id') {
            return AppStateManager.customerInfo?.portfolios[0].accounts.find(x => x.id === value)?.name;
        }
        return value;
    }
    useEffect(() => {
        const fetchData = async () => {
            if (!data.symbol) return;
            const resp = await LMDInterface.getAutocompleteBonds();
            if (!resp) return;
            const symbolExists = resp.some(x => x.Symbol === data.symbol);
            setShowTotal(!symbolExists);
        };
        fetchData();
    }, [data.symbol]);

    const renderAlertComponent = () => {
        let alertComponent;
        if (fatFingerCheck === "no_data") {
            alertComponent = (
                <>
                    <Alert type={"error"} text={"Unable to execute fat finger check"} headText={'Fat finger check'} />
                    {checkBody}
                </>
            );
        } else if (fatFingerCheck !== undefined && fatFingerCheck?.message !== "ok") {
            alertComponent = (
                <>
                    <Alert type={"error"} text={capitalizeFirstChar(fatFingerCheck?.message.replaceAll('_', ' '))} headText={'Fat finger check'} />
                    {checkBody}
                </>
            );
        } else {
            alertComponent = (
                <Button showLoader={loading} disabled={CB_error_type === "success"} size="lg" noMargin onClick={async () => { setLoading(true); await confirm(data, handleClick) }}>{TranslationManager.getTranslation().Buttons.Confirm_order}</Button>
            );
        }
        return alertComponent;
    };

    return (
        <>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
            }}>
                <div style={{ cursor: 'pointer', marginBottom: '15px', color: 'var(--blue-600)', width: 'fit-content' }} onClick={goBack}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                    <span style={{ marginLeft: '5px' }}>{TranslationManager.getTranslation().Buttons.Back}</span>
                </div>
                {DuplicateCheckKeysToDisplay.map((key) => {
                    if (data[key] === undefined || data[key] === "" || data[key] === null) return null;
                    return (
                        <div key={generateUUID()}
                            style={{
                                display: 'flex',
                                minWidth: 250,
                                justifyContent: 'space-between',
                                borderBottom: '1px solid var(--dark-100)',
                                paddingBottom: '5px',
                            }}>
                            <span style={{
                                fontWeight: 'bold',
                                textTransform: 'capitalize'
                            }}>{TranslationManager.getTranslation().NewOrderModal_DoubleCheck[key]}</span>
                            <span style={{ textTransform: 'capitalize' }}>{
                                displayItem(data[key], key)
                            }</span>
                        </div>
                    );
                })}
                {/* BROKER */}
                <div style={{
                    display: 'flex',
                    minWidth: 250,
                    justifyContent: 'space-between',
                    borderBottom: '1px solid var(--dark-100)',
                    paddingBottom: '5px',

                }}>
                    <span style={{
                        fontWeight: 'bold',
                        textTransform: 'capitalize'
                    }}>{TranslationManager.getTranslation().NewOrderModal_DoubleCheck['broker']}</span>
                    <span>{brokerName}</span>
                </div>
                <div style={{
                    display: 'flex',
                    minWidth: 250,
                    justifyContent: 'space-between',
                }}>
                    { //dont display total if order type is market
                        showTotal && data.order_type !== 'market' &&
                        <>
                            <span style={{
                                fontWeight: 'bold',
                                textTransform: 'capitalize'
                            }}>{TranslationManager.getTranslation().New_Order_Modal.Total}:</span>
                            <span>{formatNumber(total)}</span>
                        </>
                    }
                </div>
                <div style={{ minWidth: '464px', display: 'flex', flexDirection: 'column', gap: '15px', marginTop: '20px' }} >
                    {renderAlertComponent()}
                </div>
            </div>
        </>
    );
}
export default DoubleCheckModal;
