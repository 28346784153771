import { INewsLmdNewsItem, NewsItemFeedIdMapToBoolean, NewsRefreshConfig, NotificationSetters } from "../../../../Types/NewsType";

export const updateNotifications = (
    newsItems: INewsLmdNewsItem[],
    setters: NotificationSetters,
    config: NewsRefreshConfig
) => {
    const { trueMap, falseMap } = createNotificationMaps(newsItems);

    setters.setHighlight(prev => ({ ...prev, ...trueMap }));
    setters.setNew(prev => ({ ...prev, ...trueMap }));

    setTimeout(() => {
        setters.setHighlight(prev => ({ ...prev, ...falseMap }));
    }, config.highlightDuration);

    setTimeout(() => {
        setters.setNew(prev => ({ ...prev, ...falseMap }));
    }, config.newItemDuration);
};

const createNotificationMaps = (newsItems: INewsLmdNewsItem[]) => {
    const trueMap: NewsItemFeedIdMapToBoolean = {};
    const falseMap: NewsItemFeedIdMapToBoolean = {};

    newsItems.forEach(newsItem => {
        if (newsItem.id) {
            trueMap[newsItem.id] = true;
            falseMap[newsItem.id] = false;
        }
    });

    return { trueMap, falseMap };
};