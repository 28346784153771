import React, { useRef } from "react";
import * as Highcharts from "highcharts/highstock";
import { EndOfDayPrice } from "../../../Types/RetailTradingTypes";
import {
    BaseChartComponent,
    baseTheme,
    getBaseChartConfig,
    setupMouseEvents,
    getAreaSeriesConfig
} from "./BaseChart";

// Set global theme
Highcharts.setOptions(baseTheme);

export function RetailTradingChart({ data }: { data: EndOfDayPrice[] | undefined }) {
    const divRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<Highcharts.Chart | null>(null);

    const initChart = (width: number, height: number) => {
        if (!divRef.current) return;

        const chartData = data?.map((d) => [new Date(d.date).getTime(), d.value]) || [];

        const chartOptions: Highcharts.Options = {
            chart: {
                ...getBaseChartConfig(width, height).chart,
                renderTo: divRef.current as HTMLElement,
                events: {
                    load: function (this: Highcharts.Chart) {
                        setupMouseEvents(this);
                        this.yAxis[0].setExtremes(
                            this.yAxis[0].getExtremes().dataMin,
                            this.yAxis[0].getExtremes().dataMax
                        );
                    }
                }
            },
            navigator: {
                enabled: false,
            },
            scrollbar: {
                enabled: false,
            },
            rangeSelector: {
                enabled: false,
            },
            yAxis: {
                labels: {
                    style: {
                        color: "var(--chart-axis-text)",
                        fontFamily: "Roboto",
                        fontSize: "9px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "normal",
                    },
                },
                minTickInterval: 0,
                gridLineDashStyle: "Dash",
                gridLineWidth: 1,
                gridLineColor: "#D3D3D6",
                opposite: true,
                height: "100%",
            },
            series: [getAreaSeriesConfig(chartData)]
        };

        if (chartRef.current) {
            chartRef.current.setSize(width, height, false);
        } else {
            chartRef.current = Highcharts.stockChart(chartOptions);
        }
    };

    React.useEffect(() => {
        Highcharts.setOptions({
            lang: {
                resetZoomTitle: "",
            }
        });

        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
                chartRef.current = null;
            }
        };
    }, []);

    React.useEffect(() => {
        if (chartRef.current && data) {
            const chartData = data.map((d) => [new Date(d.date).getTime(), d.value]);
            chartRef.current.series[0].setData(chartData, true);
        }
    }, [data]);

    return (
        <BaseChartComponent>
            {(dimensions) => {
                initChart(dimensions.width, dimensions.height);
                return <div className="chart" ref={divRef} />;
            }}
        </BaseChartComponent>
    );
}