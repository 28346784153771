import { ErrorMessage, FieldInputProps, useField } from "formik";
import { SymbolSelector } from "../../Windows/MarketDepth/Components";
interface IProps extends FieldInputProps<string> {
    label?: string;
    labelId?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    type?: 'bonds' | 'default';
    selectedSymbol: string | undefined;
    onSelect: (symbol: string) => void;
    size?: 'sm' | 'md';
    dontShowErrorText?: boolean;
    placeholder?: string;
}
const SymbolSelectorWrapper: React.FC<IProps & { menuItems: { value: string, title: string }[] }> = ({ menuItems, label, disabled, type, selectedSymbol, onSelect, size, dontShowErrorText, ...props }) => {
    const [, meta] = useField({ ...props, name: props.name });
    return (
        <>
            <SymbolSelector
                size={size}
                placeHolder={props.placeholder}
                disableClearable={false}
                selectedSymbol={selectedSymbol}
                onSelect={onSelect}
                autoFocus={props.autoFocus}
                selectWithTab={true}
                disabled={disabled}
                type={type}
                hasError={meta.touched && meta.error ? true : false}
            />
            {!dontShowErrorText && meta.touched && meta.error && (
                <ErrorMessage className="formik-error-message" name={props.name} component="div" />
            )}
        </>
    );
};
export default SymbolSelectorWrapper;