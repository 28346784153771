import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { filter } from "rxjs/internal/operators/filter";
import { first } from "rxjs/internal/operators/first";
import { MessageType, SubscriptionCommands } from "../../Types/Websocket";

export const WEBSOCKET_AUTHENTICATION_TIMEOUT = 10000;

export class WaitableVariable<T> {
    behaviorSubject: BehaviorSubject<T | null>;
    constructor() {
        this.behaviorSubject = new BehaviorSubject(null as T | null);
    }

    // Only returns non null values
    waitForValue(): Promise<T> {
        return new Promise((resolve) =>
            this.behaviorSubject
                .pipe(
                    filter((value) => value !== null),
                    first()
                )
                .subscribe((value) => resolve(value as T))
        );
    }

    value(): T | null {
        return this.behaviorSubject.value;
    }

    set(value: T) {
        this.behaviorSubject.next(value);
    }

    clear() {
        this.behaviorSubject.next(null);
    }
}

export type ManageSubscriptionMessage = {
    type: MessageType;
    command: SubscriptionCommands;
    message?: any;
};