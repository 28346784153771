import React, { useRef, useState, useEffect } from 'react';
import * as Highcharts from 'highcharts/highstock';
import { determineColor, generateDefaultTooltip, generateDragTooltip, getDiffColor } from './ChartHelpers';


// Shared theme configuration
export const baseTheme = {
    chart: {
        backgroundColor: "var(--white)",
        style: {
            color: "white",
            stroke: "none",
        },
    },
    lang: {
        decimalPoint: ".",
        thousandsSep: ",",
        numericSymbols: ["k", "M", "B", "T"],
        rangeSelectorZoom: "",
    },
    rangeSelector: {
        labelStyle: {
            opacity: 1,
        },
        buttonTheme: {
            r: 6,
            fill: "var(--blue-100)",
            style: {
                fontFamily: "Roboto",
                fontSize: "11px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                color: "var(--blue-900)",
            },
            textTransform: "uppercase",
            states: {
                hover: {
                    fill: "var(--blue-200)",
                },
                select: {
                    style: {
                        fill: "var(--blue-900)",
                    },
                    fill: "var(--blue-200)",
                },
            },
        },
    },
    scrollbar: { enabled: false },
    tooltip: {
        label: "",
        enabled: true,
        opacity: 0,
        formatter: function () {
            const chart = this.series.chart;
            const pointer = chart.pointer;
            const isValidDrag = pointer.hasDragged && !pointer.runChartClick;

            if (!pointer.hasDragged) {
                chart.startX = this.x;
                chart.startY = this.y;
                chart.endX = undefined;
                chart.endY = undefined;
            }

            const xAxis = chart.xAxis[0];

            if (isValidDrag) {
                chart.endX = this.x;
                chart.endY = this.y;
                xAxis.crosshair = false;

                let isViceVersa = chart.startX > chart.endX;
                const color = determineColor(chart.startY, chart.endY, isViceVersa);

                xAxis.removePlotLine('start-plot-line-x');
                xAxis.removePlotLine('end-plot-line-x');
                xAxis.removePlotBand('plot-band-x');

                xAxis.addPlotBand({
                    color: color,
                    from: chart.startX,
                    to: chart.endX,
                    id: 'plot-band-x',
                });

                xAxis.addPlotLine({
                    value: chart.startX,
                    dashStyle: 'dash',
                    color: 'var(--blue-600)',
                    width: 2,
                    id: 'start-plot-line-x',
                });

                xAxis.addPlotLine({
                    value: chart.endX,
                    dashStyle: 'dash',
                    color: 'var(--blue-600)',
                    width: 2,
                    id: 'end-plot-line-x',
                });
            } else {
                xAxis.crosshair = true;
                xAxis.removePlotLine('start-plot-line-x');
                xAxis.removePlotLine('end-plot-line-x');
                xAxis.removePlotBand('plot-band-x');
            }

            let isViceVersa = chart.startX > chart.endX;
            let diff = isViceVersa ? chart.startY - chart.endY : chart.endY - chart.startY;
            let ratio = isViceVersa ? diff / chart.endY : diff / chart.startY;
            let formattedDiff = diff > 0 ? `+${diff.toFixed(2)}` : diff.toFixed(2);
            const diffColor = getDiffColor(diff);

            return !isValidDrag
                ? generateDefaultTooltip(this)
                : generateDragTooltip(chart, formattedDiff, diffColor, ratio, isViceVersa);
        },
        borderWidth: 0,
        shadow: false,
        useHTML: true,
        shared: true,
    },
};
// Base chart component configuration
export const getBaseChartConfig = (width: number, height: number): Highcharts.Options => {
    return {
        chart: {
            panning: {
                enabled: false,
            },
            animation: false,
            width,
            height,
            reflow: true,
            style: {
                fontFamily: "Roboto",
            },
            selectionMarkerFill: "#4569EE18",
            plotBackgroundColor: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                    [0.05, "#FFF"],
                    [0.95, "#F8F8FA"],
                ],
            } as Highcharts.GradientColorObject,
        },
        credits: {
            enabled: false,
        },
        title: {
            text: "",
        },
        xAxis: {
            type: "datetime",
            minTickInterval: 1000 * 60 * 60 * 24 * 1,
            tickWidth: 0,
            lineWidth: 0,
        },
        plotOptions: {
            series: {
                color: "#4569EE",
                enableMouseTracking: true,
                marker: {
                    enabled: false,
                },
                animation: false,
                turboThreshold: 0,
                dataGrouping: {
                    enabled: false,
                },
            },
        },
        legend: {
            enabled: false,
        },
    };
};


// Base chart component
export const BaseChartComponent: React.FC<{
    children: (dimensions: { width: number; height: number }) => React.ReactNode;
    className?: string;
}> = ({ children, className = '' }) => {
    const refChartContainer = useRef<HTMLDivElement>(null);
    const [dimensions, setDimensions] = useState({ width: 100, height: 100 });

    useEffect(() => {
        const element = refChartContainer.current;
        if (!element) return;

        const resizeObserver = new ResizeObserver(() => {
            const { height, width } = element.getBoundingClientRect();
            setDimensions({ width, height });
        });

        resizeObserver.observe(element);
        return () => resizeObserver.unobserve(element);
    }, []);

    return (
        <div
            className={`fill ${className}`}
            ref={refChartContainer}
        >
            {children(dimensions)}
        </div>
    );
};

// Mouse event handler setup
export const setupMouseEvents = (chart: Highcharts.Chart) => {
    const chartContainer = chart.container;

    Highcharts.addEvent(chartContainer, 'mousedown', function (e: MouseEvent) {
        //@ts-ignore
        chart.pointer.runChartClick = true;

        if (e.shiftKey) {
            chart.update({
                chart: {
                    panning: {
                        enabled: true,
                        type: 'x'
                    }
                }
            });
        } else {
            //@ts-ignore
            chart.pointer.runChartClick = false;
            chart.update({
                chart: {
                    panning: {
                        enabled: false,
                    }
                }
            });
        }
    });
};

// Shared series configuration
export const getAreaSeriesConfig = (data: number[][]): Highcharts.SeriesAreaOptions => ({
    type: 'area' as const,
    name: "Price",
    color: "#4569EE",
    lineWidth: 2,
    fillColor: {
        linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
        },
        stops: [
            [0, "rgba(69, 105, 238, 0.20)"],
            [0.8469, "rgba(69, 105, 238, 0.00)"],
        ],
    },
    data,
});