// Package imports:
import React, { useState } from 'react';
import { Formik, Form, FormikProps, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
// Component imports:
import "./NewPasswordForm.scss";
import { Environment } from "../../Config/Environment";
import Input from '../UI-Elements/Input';
import Button from '../UI-Elements/Button';
import Alert from '../UI-Elements/Alert';
import { FormControl } from "@mui/material";
// Service imports:
import { LMDInterface } from '../../KodiInterface/LMD';
import { Config } from '../../Config/Config';
import { TranslationManager } from '../../Translation/Translation';
import Dropdown from '../UI-Elements/DropDownWrapper';

interface IFormProps extends FormikProps<any> {
    values: IFormValues,
    hasError?: boolean,
}
interface IFormValues {
    email: string,
}


const NewPasswordForm: React.FC = () => {
    const [response, setResponse] = useState<React.JSX.Element>();
    const [loading, setLoading] = useState(false);
    const [backend, setBackend] = React.useState<"production" | "staging">(Environment === 'production' ? 'production' : 'staging');

    const handleSubmit = async (values: IFormValues) => {
        setLoading(true)
        try {
            const requestQuery = ({
                username: values.email,
            });
            if (Environment !== 'development') {
                Config.changeBackendType(Environment);
            } else {
                Config.changeBackendType(backend);
            }
            const url = LMDInterface.createURL('KeldanUser', '/api/reset_password');
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestQuery)
            });

            if (response.ok) {
                setLoading(false)
                const responseBody = await response.text();

                if (responseBody.includes('<h1>Error</h1>')) {
                    setResponse(
                        <Alert
                            closeAlert={() => setResponse(<></>)}
                            withCloseIcon
                            type="error"
                            headText='Villa kom upp.'
                            text={<>
                                <span>Vinsamlegast reynið aftur eða hafið samband við </span>
                                <a href='mailto:info@keldan.is'>info@keldan.is</a>
                            </>}
                        />
                    );
                } else {
                    setResponse(
                        <Alert
                            closeAlert={() => setResponse(<></>)}
                            withCloseIcon
                            type="success"
                            headText={'Tölvupóstur hefur verið sendur á netfangið ' + values.email}
                        />
                    );
                }
            } else {
                setLoading(false)
                setResponse(
                    <Alert
                        closeAlert={() => setResponse(<></>)}
                        withCloseIcon
                        type="error"
                        headText={'Fyrirspurn mistókst'}
                    />);
            }
        } catch (e) {
            setLoading(false)
            setResponse(
                <Alert
                    closeAlert={() => setResponse(<></>)}
                    withCloseIcon
                    type="error"
                    headText={'Network Error'}
                />);
        }
    }



    return (
        <div className={'KCL_new_password-form'}>
            <Formik
                initialValues={{
                    email: ''
                }}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Ógilt netfang')
                        .required('Netfang verður að fylgja'),
                })}
                onSubmit={handleSubmit}
            >
                {(formikProps) => <Form>
                    <div className="form__section">
                        <div className="form__body">
                            <div className="form__row">
                                <div className="form__col">
                                    <FormControl fullWidth>
                                        <Field
                                            as={Input}
                                            type="text"
                                            value={formikProps.values.email}
                                            name="email"
                                            placeHolder={TranslationManager.getTranslation().Login_Modal.Username}
                                            setFieldValue={formikProps.setFieldValue}
                                            hasError={!!(formikProps.errors.email && formikProps.submitCount > 0)}
                                        />
                                    </FormControl>
                                    <ErrorMessage className="formik-error-message" name="email" component="div" />
                                </div>
                            </div>
                        </div>
                        {
                            response && <div style={{ marginBottom: '20px' }}>
                                {response}
                            </div>
                        }
                        <div className="form__actions">
                            <Button
                                showLoader={loading}
                                type='submit'
                                buttonType="primary"
                                size="lg"
                            >
                                {TranslationManager.getTranslation().New_Password.Send_new_password}
                            </Button>
                        </div>
                        {
                            Environment === 'development' && <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '10px',
                                marginTop: '10px'
                            }}>
                                <span style={{ fontWeight: 'bold' }}>
                                    Backend connection:
                                </span>
                                <Dropdown
                                    width="120px"
                                    initialValue={backend}
                                    onChange={(e) => { setBackend(e as "production" | "staging") }}
                                    size="small"
                                    menuItems={[
                                        { value: "production", title: "Production" },
                                        { value: "staging", title: "Staging" }
                                    ]}
                                />
                            </div>
                        }
                    </div>
                </Form>
                }
            </Formik>
        </div>
    );
}

export default NewPasswordForm;