import { ColumnInfo } from "../../../Types/MUITable";

export const positionColumns: ColumnInfo[] = [
    { name: "symbol", defaultHidden: false, flex: 1, dataType: 'string', alignment: 'left' },
    { name: "instrument", defaultHidden: false, flex: 2, dataType: 'string', width: 150, alignment: 'left' },
    { name: "currency", defaultHidden: false, flex: 1, dataType: 'string', alignment: 'left' },

    { name: "holdings", defaultHidden: false, flex: 1, dataType: 'number', alignment: 'right' },
    { name: "position_total_value", defaultHidden: false, flex: 1, dataType: 'number', alignment: 'right' },
    { name: "profit_loss_amount", defaultHidden: false, flex: 1, dataType: 'number', alignment: 'right' },
    { name: "profit_loss_percent", defaultHidden: false, flex: 1, dataType: 'number', alignment: 'right' },
    { name: "reference_price", defaultHidden: false, flex: 1, dataType: 'number', alignment: 'right' },
    { name: "average_purchase_price", defaultHidden: false, flex: 1, dataType: 'number', alignment: 'right' },

    { name: "asset_class", defaultHidden: true, flex: 1, dataType: 'string', alignment: 'left' },


    { name: "can_over_sell", defaultHidden: true, flex: 1, dataType: 'boolean' },

    { name: "unsettled_buy_quantity", defaultHidden: true, flex: 1, dataType: 'number', alignment: 'right' },
    { name: "unsettled_sell_quantity", defaultHidden: true, flex: 1, dataType: 'number', alignment: 'right' },
    { name: "has_access", defaultHidden: true, flex: 1, dataType: 'boolean' },
    { name: "active_buy_quantity", defaultHidden: true, flex: 1, dataType: 'number', alignment: 'right' },
    { name: "active_sell_quantity", defaultHidden: true, flex: 1, dataType: 'number', alignment: 'right' },
    { name: "unallocated_buy_quantity", defaultHidden: true, flex: 1, dataType: 'number', alignment: 'right' },
    { name: "unallocated_sell_quantity", defaultHidden: true, flex: 1, dataType: 'number', alignment: 'right' },
    { name: "shares_on_hand", defaultHidden: true, flex: 1, dataType: 'number', alignment: 'right' },
    { name: "from_date", defaultHidden: true, flex: 1, dataType: 'string', alignment: 'left' },
    { name: "to_date", defaultHidden: true, flex: 1, dataType: 'string', alignment: 'left' },
    { name: "contribution", defaultHidden: true, flex: 1, dataType: 'number', alignment: 'right' },
    { name: "in_flow", defaultHidden: true, flex: 1, dataType: 'number', alignment: 'right' },
    { name: "out_flow", defaultHidden: true, flex: 1, dataType: 'number', alignment: 'right' },
    { name: "net_in_flow", defaultHidden: true, flex: 1, dataType: 'number', alignment: 'right' },
    { name: "net_profit", defaultHidden: true, flex: 1, dataType: 'number', alignment: 'right' },
    { name: "start_of_period_total_value", defaultHidden: true, flex: 1, dataType: 'number', alignment: 'right' },
    { name: "profit", defaultHidden: true, flex: 1, dataType: 'number', alignment: 'right' },
    { name: "realized_profit", defaultHidden: true, flex: 1, dataType: 'number', alignment: 'right' },
    { name: "tax", defaultHidden: true, flex: 1, dataType: 'number', alignment: 'right' }
];