// React
import { ReactNode } from "react";
// Custom
import { MarketDepthType, PriceInfo } from "../../../Types/Websocket";
import { SymbolLinkableWindowComponent } from "../AbstractWindow";
import { BondPaymentInfo, IBondCalculation, IBondPrerequisites, LMDInterface } from "../../../KodiInterface/LMD";

import { MUITableState } from "../../../Types/MUITable";
import { MBLInfo } from "../../../KodiInterface/WebSocket/Handlers/MBLHandler";
import { formatDate, getAPIDate } from "../../../Utils/Date";
import { AutocompleteBonds, MoreTradeableInfo } from "../../../Types/LMDTypes";
import BondInfo from "./BondInfo";
import { getHandler } from "../../../StateManager";
import { MUITable } from "../../Tables/MUIProTable";
import { BondPaymentInfoColumns } from "./Columns";
import { formatNumber } from "../../../Utils/Formatting";
import { CalculatorForm } from "./CalculatorForm";

type SaveState = {
  toggleAlignment: MarketDepthType;
  tableState: MUITableState | undefined;
  availableBonds: AutocompleteBonds[];
  bondInfo: IBondPrerequisites[];
  symbolInfo: MoreTradeableInfo | undefined;
  tableData: BondPaymentInfo[];
  nominalValue: number;

  calculation: IBondCalculation | undefined;
  settlementdate: string;
};

type RunState = {
  lastPrice: number | undefined;
  dirtyPrice: number | undefined;
  yield: number | undefined;
  currency: string;
  rows: MBLInfo;
  containerWidth?: number;
  containerHeight?: number;
};

export class BondsCalculator extends SymbolLinkableWindowComponent<RunState, SaveState> {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      lastPrice: undefined,
      dirtyPrice: undefined,
      yield: undefined,
      currency: "",
      rows: {
        asks: [],
        bids: [],
      },
      availableBonds: [],
      bondInfo: [],
      symbolInfo: undefined,
      tableData: [],
      nominalValue: 1000000,
      settlementdate: "",

      calculation: undefined,
      containerWidth: props.containerWidth,
      containerHeight: props.containerHeight,
    };
  }

  componentDidMount(): void {

    const fetchAvailableBonds = async () => {
      const availableBonds = await LMDInterface.getAutocompleteBonds();
      availableBonds.sort((a, b) => a.Symbol.localeCompare(b.Symbol))
      this.setState({
        availableBonds: availableBonds
      });
    }
    const fetchSettlementDate = async () => {
      const response = await LMDInterface.getNextSettlementDay();
      //the value is of the form "2021-09-2" - so we need to make sure months and days are always 2 digits
      this.setState({
        settlementdate: formatDate(response.value)
      });
    }
    fetchSettlementDate()
    fetchAvailableBonds();
    if (this.state.symbol !== undefined) this.setNewSymbol(this.state.symbol);
  }

  /************ 
   SET NEW SYMBOL
  **************/
  async setNewSymbol(symbol: string) {
    //clear everything
    this.clearWindow();
    //set symbol
    let backupAutocomplete = this.state.availableBonds;
    //only update symbol if it exists in available bonds
    if (backupAutocomplete.length === 0) {
      await LMDInterface.getAutocompleteBonds().then((item) => {
        backupAutocomplete = item;
        item.sort((a, b) => a.Symbol.localeCompare(b.Symbol))
        this.setState({
          availableBonds: item
        });
      });
    }
    if (!backupAutocomplete.map(item => item.Symbol).includes(symbol)) return;
    else this.setState({ symbol: symbol });
    // Get clean price
    await LMDInterface.getSymbolLastPrice(symbol).then((response) =>
      this.setState({ lastPrice: response.length > 0 ? response[0].OfficialLast : 100 }));

    // Get prerequisites left side of bondInfo
    await LMDInterface.getBondsPrerequisites(symbol).then((item) => {
      this.setState({ bondInfo: item });
    });

    await LMDInterface.getMoreTradeableInfo(symbol).then((item) => {
      this.setState({ symbolInfo: item });
    });
  }

  componentWillUnmount(): void {
    this.clearSubscriptions();
  }

  private clearSubscriptions() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }
  calculateTableHeight = () => {
    if (!this.state.containerHeight) return 0;
    const header = document.getElementById('KMW_BondCalculator-header');
    const table = document.getElementById('KMW_BondCalculator-table');
    const headerHeight = header ? header.clientHeight : 0;
    const tableHeight = table ? table.clientHeight : 0;
    return this.state.containerHeight - headerHeight - tableHeight - 12;
  };
  componentDidUpdate(prevProps, prevState, snapshot?: any): void {
    if (
      prevProps.containerWidth !== this.props.containerWidth ||
      prevProps.containerHeight !== this.props.containerHeight
    ) {
      this.setState({
        containerWidth: this.props.containerWidth,
        containerHeight: this.props.containerHeight
      });
    }
    // Save state on each change
    this.saveState({
      tableState: this.state.tableState,
      toggleAlignment: this.state.toggleAlignment,
      linked: this.state.linked,
      symbol: this.state.symbol,

      availableBonds: this.state.availableBonds,
      bondInfo: this.state.bondInfo,
      symbolInfo: this.state.symbolInfo,
      tableData: this.state.tableData,
      nominalValue: this.state.nominalValue,
      calculation: this.state.calculation,
      settlementdate: this.state.settlementdate
    });
  }
  submitForm = async (values) => {
    try {
      let valueToSend;
      if (values.activeRadio === 'price') {
        valueToSend = values.clean_price;
      } else if (values.activeRadio === 'dirty') {
        valueToSend = values.dirty_price;
      } else {
        valueToSend = values.yield;
      }
      const response = await LMDInterface.calculateBond(values.symbol, getAPIDate(values.date), values.activeRadio, valueToSend.toLocaleString('en-US'));
      const filteredCashflow = response.cashflow.filter(item => item.year_fract >= 0);
      this.setState({ calculation: response, tableData: filteredCashflow, nominalValue: Number(values.nominal_value.replace(',', '')), dirtyPrice: response.dirty_price, yield: response.yield });
    } catch (error) {
      console.log(error)
    }
  }
  formatCell(column, row: BondPaymentInfo): React.JSX.Element {
    let value = row[column];
    const jc_flexEnd = column !== 'date' ? { justifyContent: 'flex-end', display: 'flex' } : {};

    if (column === 'principal' || column === 'payment') {
      value = formatNumber((value / 100) * this.state.nominalValue, 0)
    }
    else if (column === 'interests' || column === 'total') {
      value = formatNumber(value * 10000, 0)
    }
    else if (column === 'present_value') { value = formatNumber(value * 10000, 0) }
    else if (column === 'year_fract') { value = formatNumber(value) }
    else if (column === 'index_adjusted') { value = formatNumber(value * 10000, 0) }
    else if (column === 'date') { value = formatDate(Date.parse(value)) }

    return <div style={{ ...jc_flexEnd }} className="fill" >{value}</div>;
  }
  clearWindow = () => {
    this.setState({
      calculation: undefined,
      tableData: [],
      nominalValue: 1000000,
      symbolInfo: undefined,
      bondInfo: [],
      settlementdate: this.state.settlementdate,
      lastPrice: undefined,
      dirtyPrice: undefined,
      yield: undefined,
    });
  };

  render(): ReactNode {
    return (
      <div className="KMW_BondCalculator window">
        {this.linkedButton()}
        <div id="KMW_BondCalculator-header" className="header">
          <div>
            <CalculatorForm
              width={this.state.containerWidth}
              settlementdate={this.state.settlementdate}
              clearWindow={this.clearWindow}
              symbolInfo={this.state.symbolInfo}
              lastPrice={this.state.lastPrice}
              dirtyPrice={this.state.dirtyPrice}
              yieldPrice={this.state.yield}
              symbol={this.state.symbol}
              symbolChange={async (symbol) => await this.setNewSymbol(symbol)}
              onSubmit={(values) => this.submitForm(values)}
            />
            <BondInfo
              principal={this.state.nominalValue}
              calculation={this.state.calculation}
              prerequisites={this.state.bondInfo}
              symbolInfo={this.state.symbolInfo} />
          </div>
        </div>
        <div style={{ maxHeight: this.calculateTableHeight() + 'px', height: this.calculateTableHeight() + 'px' }}>
          <div className="fill" style={{ overflow: "auto" }}>
            {this.state.tableData.length > 0 &&
              <MUITable
                rows={this.state.tableData.map((item, index) => { return { ...item, id: JSON.stringify(item) } })}
                columns={BondPaymentInfoColumns}
                cell={(column, row) => this.formatCell(column, row as unknown as BondPaymentInfo)}
                tableState={this.state.tableState}
                saveState={state => this.setState({ tableState: state })}
                loading={false}
              />
            }
          </div>
        </div>
      </div >
    );
  }
}
