import { INewsLmdNewsItem, SearchResultsInfo, SourceFilterToResultsMap } from "../../../../Types/NewsType";
import { combineOldAndNewNews, getSourceListFromSourceString, getSourceStringFromSourceList } from "../../../../Utils/NewsUtils";

export class NewsRefreshService {
    static async fetchNewNewsRecursively(
        fetchNews: (count: number) => Promise<INewsLmdNewsItem[]>,
        stopItem: INewsLmdNewsItem,
        initialCount: number,
        maxAttempts: number,
        currentAttempt = 0
    ): Promise<INewsLmdNewsItem[]> {
        try {
            const items = await fetchNews(initialCount * Math.pow(2, currentAttempt));

            const indexWhereOldNewsBegins = this.findIndexOfOldNews(items, stopItem);

            if (indexWhereOldNewsBegins === -1 && currentAttempt < maxAttempts) {
                return this.fetchNewNewsRecursively(
                    fetchNews,
                    stopItem,
                    initialCount,
                    maxAttempts,
                    currentAttempt + 1
                );
            }

            return indexWhereOldNewsBegins === -1 ? items : items.slice(0, indexWhereOldNewsBegins);
        } catch (error: any) {
            console.error('Error fetching news:', error);
            throw error;
        }
    }

    static findIndexOfOldNews(
        newNewsItems: INewsLmdNewsItem[],
        stopItem: INewsLmdNewsItem
    ): number {
        return newNewsItems.findIndex(newsItem => (
            (newsItem.id !== null && newsItem.id === stopItem.id) ||
            (newsItem.publish_timestamp !== null &&
                stopItem.publish_timestamp !== null &&
                newsItem.publish_timestamp < stopItem.publish_timestamp)
        ));
    }

    static processSourceFilters(
        newNewsItems: INewsLmdNewsItem[],
        sourceFilterToResultsMap: SourceFilterToResultsMap,
        category: string,
        sourceFilters: any,
        currentPageIndex: number,
        usingSearchWord: boolean
    ): {
        newSourceFilterToResultsMap: SourceFilterToResultsMap;
        newsThatGetNotification: INewsLmdNewsItem[];
    } {
        const newSourceFilterToResultsMap = { ...sourceFilterToResultsMap };
        const currentSourceFilterString = `${getSourceStringFromSourceList(sourceFilters, 'on')}`;
        let newsThatGetNotification: INewsLmdNewsItem[] = [];

        Object.keys(sourceFilterToResultsMap).forEach(sourceFilterString => {
            const searchResultInfo = sourceFilterToResultsMap[sourceFilterString];
            if (!searchResultInfo) return;

            const { results, totalCount, waitingQueue } = searchResultInfo;
            const newsToAddTo = (!usingSearchWord &&
                currentSourceFilterString === sourceFilterString &&
                currentPageIndex !== 0) ? waitingQueue : results;

            if (newsToAddTo instanceof Error || !Array.isArray(newsToAddTo)) return;

            const { combinedNews, addedNews } = combineOldAndNewNews(
                newNewsItems,
                newsToAddTo,
                getSourceListFromSourceString(sourceFilterString)
            );

            const newResultsInfo: SearchResultsInfo = {
                results: currentPageIndex === 0 ? combinedNews : results,
                waitingQueue: currentPageIndex === 0 ? [] : combinedNews,
                totalCount: totalCount + addedNews.length
            };

            if (!usingSearchWord && currentSourceFilterString === sourceFilterString) {
                newsThatGetNotification = addedNews;
            }

            newSourceFilterToResultsMap[sourceFilterString] = newResultsInfo;
        });

        return { newSourceFilterToResultsMap, newsThatGetNotification };
    }
}