import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

import Bugsnag from "@bugsnag/js";
import { toast } from "react-toastify";
import { AppStateManager } from "../../StateManager";
import { TranslationManager } from "../../Translation/Translation";
import { WSStatus, AuthenticationMessage, UnAuthorizedMessage, StreamerError } from "../../Types/Websocket";
import { ICONS } from "../../Utils/Definitions";
import { WaitableVariable, WEBSOCKET_AUTHENTICATION_TIMEOUT } from "./WebsocketHelpers";

export interface WebSocketHandlers {
    [key: string]: any;
}
export abstract class BaseWebSocket<T extends WebSocketHandlers> {
    public socket: WaitableVariable<WebSocket>;
    public url: string;
    public name: string;
    public lastHeartBeat: BehaviorSubject<Date>;
    public status: BehaviorSubject<WSStatus>;
    protected error: string | null;
    protected handlers: T;
    protected getHandler: (type: keyof T) => T[keyof T];
    protected token: string | null;
    protected multiple_session_detected: boolean;
    public retryTimer: {
        active: boolean;
        intervalSeconds: number;
        timer: number | null;
        networkLost: boolean;
    };

    constructor(
        url: string,
        name: string,
        token: string | null,
        handlers: T,
        getHandler: (type: keyof T) => T[keyof T],
        lastHeartBeat?: BehaviorSubject<Date>
    ) {
        this.url = url;
        this.name = name;
        this.token = token;
        this.socket = new WaitableVariable<WebSocket>();
        this.status = new BehaviorSubject<WSStatus>("disconnected");
        this.error = null;
        this.handlers = handlers;
        this.getHandler = getHandler;
        this.lastHeartBeat = lastHeartBeat || new BehaviorSubject(new Date());
        this.multiple_session_detected = false;
        this.retryTimer = {
            active: true,
            intervalSeconds: 0,
            timer: null,
            networkLost: false,
        };

        this.initializeSocket();
    }

    protected initializeSocket(): void {
        this.status.subscribe((status) => {
            this.handleStatusChange(status);
        });
    }

    protected handleStatusChange(status: WSStatus): void {
        if (status === 'disconnected') {
            this.handleDisconnected();
        } else if (status === 'connected' || status === 'connecting') {
            this.retryTimer.timer = null;
            this.retryTimer.networkLost = false;
        }
    }

    protected handleDisconnected(): void {
        if (!this.retryTimer.active || this.retryTimer.timer) return;

        if (this.retryTimer.intervalSeconds > 60) {
            this.status.next('closed');
            return;
        }

        this.retryTimer.timer = window.setTimeout(() => {
            this.retryTimer.intervalSeconds += 5;
            this.retryTimer.active = true;
            this.retryTimer.timer = null;
            this.socket.clear();
            this.socket.set(this.createSocket());
        }, this.retryTimer.intervalSeconds * 1000);
    }

    protected createSocket(): WebSocket {
        this.multiple_session_detected = false;
        this.status.next("connecting");
        const socket = new WebSocket(this.url);

        socket.addEventListener("open", () => this.openSocket(socket));
        socket.addEventListener('close', () => this.closeSocket());
        socket.addEventListener('error', this.handleError.bind(this));
        socket.addEventListener('message', (event) => this.handleMessage(JSON.parse(event.data)));

        this.setAuthenticationTimeout();

        return socket;
    }

    protected setAuthenticationTimeout(): void {
        window.setTimeout(() => {
            if (this.status.value === 'connecting') {
                this.retryTimer.active = false;
                this.status.next("disconnected");
                this.error = "Websocket connection timeout";
            }
        }, WEBSOCKET_AUTHENTICATION_TIMEOUT);
    }

    protected openSocket(socket: WebSocket | null): void {
        if (!socket) return;

        const authMessage = this.createAuthenticationMessage();
        socket.send(JSON.stringify(authMessage));
    }

    protected createAuthenticationMessage(): any {
        return {
            type: 'authenticate', // Default to 'authenticate' for market feed
            command: "logon",
            message: { token: this.token }
        };
    }

    public closeSocket(): void {
        const socket = this.socket.value();
        if (socket) {
            socket.onclose = () => { };
            socket.close();
            this.retryTimer.active = true;
            this.retryTimer.intervalSeconds = 0;
            this.retryTimer.timer = null;
            if (!this.multiple_session_detected) {
                this.status.next('closed');
            }
        }
    }

    protected handleAuthentication(message: AuthenticationMessage | UnAuthorizedMessage): void {
        if (message.authenticated) {
            this.handleSuccessfulAuthentication();
        } else {
            this.handleFailedAuthentication(message as UnAuthorizedMessage);
        }
    }

    protected handleSuccessfulAuthentication(): void {
        this.retryTimer.intervalSeconds = 0;
        this.error = null;
        this.status.next("connected");
    }

    protected handleFailedAuthentication(message: UnAuthorizedMessage): void {
        const unAuthMsg = message.text ?? message.reason;

        if (unAuthMsg === StreamerError.MultipleSessions) {
            this.handleMultipleSessions();
        } else if (unAuthMsg === StreamerError.TokenExpired || unAuthMsg === StreamerError.PDSTokenExpired) {
            AppStateManager.logout();
        } else if (this.isOMSError(unAuthMsg)) {
            Bugsnag.notify(new Error(unAuthMsg));
        } else if (unAuthMsg === StreamerError.SystemNotAllowed) {
            this.closeSocket();
        }

        this.status.next("disconnected");
    }

    protected isOMSError(error: string): boolean {
        return [
            StreamerError.OMSTimeout,
            StreamerError.OMSUnavailable,
            StreamerError.OMSUserNotFound,
            StreamerError.OMSOtherError
        ].includes(error);
    }

    protected handleStatus(status: "offline" | "online" | "catchup"): void {
        switch (status) {
            case "online":
                this.error = null;
                this.status.next("connected");
                break;
            case "offline":
                this.error = "Server is offline";
                this.status.next("closed");
                break;
            case "catchup":
                this.error = "Server is in catchup";
                this.status.next("connecting");
                break;
        }
    }

    protected handleError(event: Event): void {
        console.error("Error on socket:", event);
    }

    protected showToast(status: WSStatus): void {
        const active = toast.isActive(this.name);

        switch (status) {
            case 'closed':
                this.showClosedToast(active);
                break;
            case 'connected':
                this.showConnectedToast(active);
                break;
            case 'connecting':
                this.showConnectingToast();
                break;
        }
    }

    protected showClosedToast(isActive: boolean): void {
        const toastMessage = `Streamer ${this.name} has been closed`;
        const toastOptions = {
            toastId: this.name,
            closeButton: true,
            autoClose: 2000,
            closeOnClick: false,
            icon: ICONS.error,
            type: "error" as const
        };

        if (isActive) {
            toast.update(this.name, {
                render: toastMessage,
                ...toastOptions
            });
        } else {
            toast.error(toastMessage, toastOptions);
        }
    }

    protected showConnectedToast(isActive: boolean): void {
        const message = `${TranslationManager.getTranslation().ToastMessage.Connected_to} ${this.name}`;
        const toastOptions = {
            autoClose: 2000,
            hideProgressBar: true,
            icon: ICONS.success,
            type: "success" as const
        };

        if (isActive) {
            toast.update(this.name, {
                render: message,
                ...toastOptions
            });
        } else {
            toast.success(message, {
                ...toastOptions,
                toastId: this.name
            });
        }
    }

    protected showConnectingToast(): void {
        toast.update(this.name, {
            icon: ICONS.reconnecting,
            render: `${TranslationManager.getTranslation().ToastMessage.Reconnecting_to} ${this.name}`,
            autoClose: false,
            type: "warning" as const
        });
    }

    // Abstract methods that must be implemented by child classes
    protected abstract handleMessage(message: any): void;
    protected abstract handleMultipleSessions(): void;
}