// Package imports:
import React, { memo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import cx from 'classnames';
import Link from '../../UI-Elements/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { INewsLmdNewsItem } from '../../../Types/NewsType';
import { getNewsDateOrTime, newsTypeMap } from '../../../Utils/NewsUtils';
import Label from '../../UI-Elements/Label';

interface IProps {
    item: INewsLmdNewsItem,
    isNew: boolean, // the yellow notifier next to the title
    isHighlighted: boolean, // The highlighted color.
    isSeen: boolean,
    onOpen(): void
}
const NewsItem: React.FC<IProps> = ({
    item: {
        title,
        body,
        publish_date,
        source,
        fullSource,
        link,
        // paywall,
        feedMeta
    },
    isNew,
    isHighlighted,
    isSeen,
    onOpen
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const expandNews = () => {
        setIsOpen(!isOpen);
        onOpen();
    }

    const isExchangeNotice = feedMeta?.feedType === 'exchange_notice';

    return (
        <div
            className={cx('frettavakt-item', {
                'is-open': isOpen,
                'is-seen': isSeen,
                'is-new': isNew,
                'is-highlighted': isHighlighted
            })}
        >
            <div
                className="news-item__head"
                onClick={expandNews}
            >
                <div
                    className="news-item__trigger"
                >
                    {isExchangeNotice && (
                        <FontAwesomeIcon icon={faBullhorn} className='news-item__icon' />
                    )}
                    <span className="news-item__title">{title}</span>
                    <div className='notify-wrapper'>
                        {!isSeen && isNew && (
                            <div className="news-item__notify"></div>
                        )}
                    </div>
                </div>


                <div className="news-item__meta">
                    <span className='news-item__source'>{source}</span>
                    {/* //TODO BETTER ERROR HANDLING */}
                    <span className="news-item__time"><strong>{getNewsDateOrTime(new Date(publish_date ?? ''))}</strong></span>
                </div>
            </div>

            <AnimateHeight
                className="news-item__content"
                duration={300}
                height={isOpen ? 'auto' : 0}
            >
                <div className="news-item__body">
                    <div className="news-item__entry" dangerouslySetInnerHTML={{ __html: body ?? '' }} />

                    <div className="news-item__actions">
                        <Link linkSize='12' url={link ?? '#'} target='_blank'><FontAwesomeIcon icon={faChevronRight} style={{ fontSize: '11px' }} />Skoða frétt</Link>
                        <div className="news-item__labels">
                            {(feedMeta?.feedName || fullSource) && <Label
                                labelType='origin'
                                text={feedMeta?.feedName ?? fullSource ?? ''}
                                size='sm'
                            />}
                            {(feedMeta?.feedType) && <Label
                                labelType={isExchangeNotice ? 'beta' : 'origin'}
                                text={newsTypeMap[feedMeta.feedType]}
                                size='sm'
                            />}
                        </div>
                    </div>
                </div>
            </AnimateHeight>
        </div>
    )
}

export default memo(NewsItem);