// React
import React, { KeyboardEvent } from "react";
import cx from "classnames";
// MUI
import {
  Autocomplete,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Box,
} from "@mui/material";

// Custom
import { LMDInterface } from "../../../KodiInterface/LMD";
import { MarketDepthType, PriceInfo } from "../../../Types/Websocket";
import { formatNumber } from "../../../Utils/Formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faDownLong,
  faUpLong,
} from "@fortawesome/pro-solid-svg-icons";
import { Field } from "formik";

export function SymbolSelector({
  selectedSymbol,
  onSelect,
  width,
  disableClearable = true,
  size = "sm",
  placeHolder,
  autoFocus = false,
  selectWithTab = false,
  disabled = false,
  type = 'default',
  hasError = false,
}: Readonly<{
  selectedSymbol: string | undefined;
  onSelect: (symbol: string) => void;
  width?: number;
  disableClearable?: boolean;
  size?: "sm" | "md";
  placeHolder?: string;
  autoFocus?: boolean;
  selectWithTab?: boolean;
  disabled?: boolean;
  type?: 'bonds' | 'default';
  hasError?: boolean;
}>) {
  const [symbols, setSymbols] = React.useState<string[]>([]);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [highlightedOption, setHighlightedOption] = React.useState<string>("");

  if (symbols.length === 0)
    type === 'bonds' ? LMDInterface.getAutocompleteBonds().then((symbols) => setSymbols(symbols.map(item => item.Symbol))) : LMDInterface.getAllSymbols().then((symbols) => setSymbols(symbols));
  const optionStyles = {
    color: "var(--dark-900, #232530)",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  if (size === "md") {
    optionStyles.fontSize = "13px";
  }
  if (size === "sm") {
    optionStyles.fontSize = "11px";
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Autocomplete
        disabled={disabled}
        selectOnFocus
        autoHighlight
        onHighlightChange={(_, option) => {
          selectWithTab && setHighlightedOption(option);
        }}
        onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
          if (selectWithTab && event.key === 'Tab') {
            if (highlightedOption === "") onSelect(selectedSymbol ?? "")
            else {
              onSelect(highlightedOption)
            }
          }
        }}
        fullWidth
        className={size}
        popupIcon={
          <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: "10px" }} />
        }
        options={symbols}
        sx={{
          padding: 0,
          "& input": {
            maxWidth: 200,
            height: (size === 'sm') ? 20 : 30,
          },
        }}
        // open
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        renderInput={(params) => (
          (placeHolder) ?
            <Field
              as={TextField}
              label={placeHolder}
              {...params}
              style={{ width: width ?? "100%" }}
              autoFocus={autoFocus}
              className={cx("autocomplete-custom", size, { isOpen: isOpen, 'hasError': hasError })}
            />
            :
            <TextField
              // placeholder={placeHolder}
              {...params}
              style={{ width: width ?? "100%" }}
              className={cx("autocomplete-custom", size, { isOpen: isOpen, 'hasError': hasError })}
            />
        )}
        disableClearable={disableClearable}
        value={selectedSymbol ?? (null as any)} /*•ᴗ•*/
        size={size === "sm" ? "small" : "medium"}
        renderOption={(props, symbol) => (
          <Box
            className={size}
            component="li"
            {...props}
            sx={optionStyles}
          >
            {symbol}
          </Box>
        )}
        onChange={(event: any, newValue: string) => onSelect(newValue)}
      />
    </div>
  );
}

export function MarketDepthSelector({
  currentToggle,
  onChange,
}: Readonly<{
  currentToggle: MarketDepthType;
  onChange: (value: MarketDepthType) => void;
}>) {
  return (
    <ToggleButtonGroup
      value={currentToggle}
      size="small"
      exclusive={true}
      sx={{
        height: "fit-content",
        borderRadius: "6px",
        background: "var(--blue-100)",
        //buttons
        "& .MuiToggleButton-root": {
          padding: "0px",

          borderRadius: "6px",
          border: "none",
          background: "var(--blue-100)",
          color: "var(--blue-900)",
          fontSize: "11px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
          textTransform: "uppercase",
          "> div.toggle_buttons": {
            padding: "3px 5px",
          },
          "&.Mui-selected": {
            fontWeight: "500",
            color: "var(--blue-900)",
            background: "var(--blue-200)",
            borderRadius: "6px",
            "&:hover": {
              background: "var(--blue-200)",
            },
          },
        },
      }}
      onChange={(
        event: React.MouseEvent<HTMLElement>,
        toggleAlignment: MarketDepthType
      ) => {
        if (toggleAlignment !== null) onChange(toggleAlignment);
      }}
    >
      <ToggleButton value="market_by_level" key="market_by_level">
        <div className="toggle_buttons">MBL</div>
      </ToggleButton>
      <ToggleButton value="market_by_order" key="market_by_order">
        <div className="toggle_buttons">MBO</div>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export function MarketDepthHeaderInfo({
  priceInfo,
  currency,
}: Readonly<{
  priceInfo: PriceInfo;
  currency: string;
}>) {
  const pcl = priceInfo.previous_closing_price,
    last = priceInfo.last_price,
    mid = priceInfo.intraday_mid_price,
    volume = priceInfo.intraday_accumulated_volume || 0;
  let bDelta;
  if (!isNaN(last) && !isNaN(pcl)) bDelta = last - pcl;

  let change: "noChange" | "down" | "up";
  if (bDelta === 0 || bDelta === undefined) {
    change = "noChange";
  } else if (bDelta < 0) {
    change = "down";
  } else {
    change = "up";
  }
  const color = {
    noChange: "white",
    down: "var(--red-600)",
    up: "var(--green-600)",
  }[change];
  const deltaIcon = {
    noChange: null,
    down: (
      <FontAwesomeIcon
        icon={faDownLong}
        style={{
          fill: color,
          color: color,
          marginLeft: "4px",
          height: "8px",
          width: "7px",
          marginBottom: "2px",
        }}
      />
    ),
    up: (
      <FontAwesomeIcon
        icon={faUpLong}
        style={{
          fill: color,
          color: color,
          marginLeft: "4px",
          height: "8px",
          width: "7px",
          marginBottom: "2px",
        }}
      />
    ),
  }[change];

  return (
    <div className="header-info">
      <div>
        <div>{currency}</div>
      </div>
      <div className="header-info-item">
        <span className="header-text">Last</span>
        {bDelta !== undefined && (
          <span className="delta_value">
            {formatNumber(last)}
            {deltaIcon}
            {bDelta !== 0 && (
              <span style={{ color }}>{formatNumber(Math.abs(bDelta))}</span>
            )}
          </span>
        )}
      </div>
      <div className="header-info-item">
        <span className="header-text">Mid</span>
        {formatNumber(mid)}
      </div>
      <div className="header-info-item">
        <span className="header-text">Volume</span>
        {formatNumber(volume)}
      </div>
    </div>
  );
}
