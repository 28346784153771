import { TranslationManager } from "../Translation/Translation";
import { format, startOfDay } from 'date-fns';

// Time
type IDate = (date: Date | string | undefined | null) => string;

type NullableNumber = number | undefined | null;

export const formatTime: IDate = (date) => {
  if (date === undefined || date === null) return "";
  if (typeof date === "string") {
    date = new Date(date);
  }
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
};

export const formatTimeWithDate: IDate = (date) => {
  if (date === undefined || date === null) return "";
  if (typeof date === "string") {
    date = new Date(date);
  }

  const now = new Date();
  const diffInHours = Math.abs(now.getTime() - date.getTime()) / 1000 / 60 / 60;

  if (diffInHours < 24) {
    // If the date is within the last 24 hours, display the time
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  } else {
    // If the date is more than 24 hours ago, display the date
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    return `${day}.${month}.${year}`;
  }
};
export const formatDetailedTime = (date: Date | string | undefined | null) => {
  //return time in format dd.mm.yyyy hh:mm:ss
  if (date === undefined || date === null) return "";
  if (typeof date === "string") {
    date = new Date(date);
  }
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear()).slice(-2);
  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
};

const getLocale = () =>
  TranslationManager.getActiveLanguage() === "IS" ? "is-IS" : "en-US";


export function formatNumber(
  num: NullableNumber,
  maxFractionDigits?: number,
  minFractionDigits?: number,
): string {
  if (num === undefined || num === null) return "";

  let decimalPlaces = maxFractionDigits;

  // If max is not specified, use default max of 4
  if (decimalPlaces === undefined) {
    if (!Number.isInteger(num)) {
      decimalPlaces = Math.min((num.toString().split(".")[1] || "").length, 4);
    } else {
      decimalPlaces = 0;
    }
  }

  // Ensure minFractionDigits doesn't exceed maxFractionDigits
  const minDigits = minFractionDigits ?? 0;
  const maxDigits = Math.max(decimalPlaces, minDigits);

  return num.toLocaleString(getLocale(), {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  });
}

export const formatChartNumber = (
  num: NullableNumber,
  fallBackSymbol: string | null = "-",
  fractionDigits: number = 2,
  suffix = ""
) => {
  if (num === null || num === undefined || isNaN(num)) {
    return fallBackSymbol;
  }

  const formattedNumber = new Intl.NumberFormat(getLocale(), {
    minimumFractionDigits: 0,
    maximumFractionDigits: fractionDigits,
  }).format(num);
  return formattedNumber + suffix;
};

export function formatWithLetters(num: number): string {
  if (num > 1_000_000_000) return `${Math.round(num / 1_000_000_000)}B`;
  if (num > 1_000_000) return `${Math.round(num / 1_000_000)}M`;
  if (num > 1_000) return `${Math.round(num / 1_000)}K`;
  else return `${Math.round(num)}`;
}

export function formatPercent(num: NullableNumber): string {
  const activeLanguage = TranslationManager.getActiveLanguage();
  if (num === undefined || num === null) return "";
  return `${Math.round(num * 10000) / 100}%`.replace(".", activeLanguage === 'IS' ? "," : '.');
}

export function formatTradeID(id: number | string): string {
  if (typeof id === 'string' && id.length >= 9) {
    return id;
  } else {
    return id.toString().padStart(9, '0');
  }
}

export function formatTableDate(date: string | Date | undefined | null): string {
  if (!date) return "";

  const parsed = typeof date === "string" ? new Date(date) : date;
  if (isNaN(parsed.getTime())) return "Invalid date";

  const isToday = startOfDay(new Date()).getTime() === startOfDay(parsed).getTime();
  let formatString: "HH:mm:ss" | "dd/MM/yy";
  if (isToday) {
    formatString = "HH:mm:ss";
  } else {
    formatString = "dd/MM/yy";
  }
  return format(parsed, formatString);
}