import { MessageHandler } from "./AbstractHandler";
import {
  MessageType,
  SubscriptionCommands,
  TradeMessage,
} from "../../../Types/Websocket";
import { ManageSubscriptionMessage } from "../WebsocketHelpers";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { Subscription } from "rxjs/internal/Subscription";

export class ViewTradesHandler extends MessageHandler<TradeMessage[]> {
  private trades: BehaviorSubject<TradeMessage[]>;

  constructor() {
    super();
    this.trades = new BehaviorSubject<TradeMessage[]>([]);
  }

  handleMessage(message: TradeMessage[]): void {
    // Get the current trades
    const currentTrades = this.trades.value;
    let hasNewTrades = false;

    // Iterate over the new trades
    message.forEach((trade) => {
      // Check if the trade already exists
      const exists = currentTrades.find(
        (currentTrade) =>
          currentTrade.execution_id === trade.execution_id &&
          currentTrade.symbol === trade.symbol &&
          currentTrade.side === trade.side
      );

      // If the trade doesn't exist, add it to the trades
      if (!exists) {
        currentTrades.push(trade);
        hasNewTrades = true;
      }
    });

    // Update the trades only if there are new trades
    if (hasNewTrades) {
      this.trades.next(currentTrades);
    }
  }

  //counter sem telur hvað eru margir bunir að subscribe-a

  subscribe(callback: (trade: TradeMessage[]) => void): Subscription {

    const subscription = this.trades.subscribe(callback);
    return subscription;
  }

  regenerateSubscriptionMessage(): ManageSubscriptionMessage {
    return { type: MessageType.time, command: SubscriptionCommands.Subscribe };

  }
}
