import { AppStateManager } from "../../../StateManager";
import { OrderSubmitRequest } from "../../../Types/private-data/NewOrderSubmitRequest";
import { OrderType } from "../../../Types/private-data/orderType";
import { SideTypes } from "../../../Types/private-data/side";
import { TimeInForceTypes } from "../../../Types/private-data/timeInForce";
import { randomString16Char } from "../../../Utils/Common";
import { IPriceInfoCallBack } from "./SymbolInfo";

// utils/orderBuilder.ts
interface BuildOrderParams {
    values: any;
    priceInfo: IPriceInfoCallBack;
    visibleQty?: number;
}

const parseQuantity = (quantityString: string | null | undefined): number | undefined => {
    if (!quantityString) return undefined;
    return Number(parseFloat(quantityString.replace(/,/g, '.').replace(/\./g, '')));
};

const getRetailFields = (values: any) => {
    if (!AppStateManager.hasAccessToRetail.getValue()) return {};

    return {
        ...(values.portfolio_id && { portfolio_id: Number(values.portfolio_id) }),
        ...(values.account_id && { account_id: Number(values.account_id) })
    };
};

const getOptionalFields = (
    values: any,
    visibleQty: number | undefined,
    price: number | undefined
) => {
    return {
        ...(visibleQty !== undefined && { visible_quantity: visibleQty }),
        ...(values.quantity && { quantity: parseQuantity(values.quantity) }),
        ...(price && { price })
    };
};

export const buildOrderRequest = ({
    values,
    priceInfo,
    visibleQty
}: BuildOrderParams): OrderSubmitRequest => {
    // Core fields that are always required
    const coreFields = {
        reference_id: randomString16Char(),
        side: values.side as SideTypes,
        symbol: values.symbol as string,
        order_type: values.order_type as OrderType,
        time_in_force: values.time_in_force as TimeInForceTypes,
        broker: values.broker
    };

    // Reference prices from market data
    const referenceFields = {
        reference_best_ask: priceInfo.ask,
        reference_best_bid: priceInfo.bid,
        reference_last_price: priceInfo.last
    };

    // Build the complete order request
    return {
        ...coreFields,
        ...referenceFields,
        ...getRetailFields(values),
        ...getOptionalFields(values, visibleQty, values.price)
    };
};
