import React from 'react';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TranslationManager } from '../../../Translation/Translation';
import { formatPercent, formatTableDate, formatTime, formatWithLetters, formatNumber } from '../../../Utils/Formatting';

interface TradeRow {
    time_executed_utc?: string;
    aggressive_party?: string;
}

// Formatting helpers
const formatters = {
    percent: (value: number) => formatPercent(value),
    date: (value: string | Date) => formatTableDate(value),
    time: (value: string | Date) => formatTime(value),
    number: (value: number, column: string) =>
        column === 'value' ? formatWithLetters(value) : formatNumber(value)
};
// Define column types for better type safety
enum ColumnType {
    Timestamp = 'timestamp',
    Yield = 'yield',
    DateTime = 'datetime',
    AggressiveParty = 'aggressiveParty',
    Time = 'time'
}

// Separate configuration for special columns
const COLUMN_TYPES: Record<string, ColumnType> = {
    settlement_date: ColumnType.Timestamp,
    datetime_exec: ColumnType.DateTime,
    time_executed: ColumnType.Time,
    trade_cancellation_time: ColumnType.Time,
    buyer: ColumnType.AggressiveParty,
    seller: ColumnType.AggressiveParty,
} as const;

// Utility function to determine column type
const getColumnType = (column: string): ColumnType | null => {
    if (column.includes('timestamp')) return ColumnType.Timestamp;
    if (column.includes('yield')) return ColumnType.Yield;
    if (column.includes('datetime')) return ColumnType.Timestamp;
    return COLUMN_TYPES[column] || null;
};

// Separate handlers for different value types
const formatBoolean = (value: boolean): React.ReactNode => (
    value ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />
);

const formatAggressiveParty = (
    column: 'buyer' | 'seller',
    value: any,
): React.ReactNode => {
    const displayValue = value ?? getDefaultPartyValue(column);
    const color = column === 'buyer' ? 'var(--green-600)' : 'var(--red-600)';

    return <div style={{ color }}>{displayValue}</div>;
};

const getDefaultPartyValue = (column: 'buyer' | 'seller'): string => {
    const isIcelandic = TranslationManager.getActiveLanguage() === "IS";
    if (column === 'buyer') {
        return isIcelandic ? "K" : "B";
    }
    return "S";
};

// Main formatter function with reduced complexity
const formatValue = (column: string, value: any, row: TradeRow): React.ReactNode => {
    const columnType = getColumnType(column);
    // Handle datetime_exec special case
    if (column === 'datetime_exec' && value === undefined) {
        value = row.time_executed_utc;
    }

    // Handle value based on column type
    switch (columnType) {
        case ColumnType.Timestamp:
            return formatters.date(value);
        case ColumnType.Yield:
            return formatters.percent(value);
        case ColumnType.Time:
            return formatters.time(value);
        case ColumnType.AggressiveParty:
            return formatAggressiveParty(column as 'buyer' | 'seller', value);
        default:
            // Handle basic types
            if (typeof value === 'number') return formatters.number(value, column);
            if (typeof value === 'boolean') return formatBoolean(value);
            return value;
    }
};

export default formatValue;