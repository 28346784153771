// Package imports:
import React, { memo } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle } from '@fortawesome/pro-solid-svg-icons';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

interface IProps extends InputProps {
    id: string,
    name: string,
    children: any,
    className?: string,
    value: string,
    defaultValue: string,
    onChange(event: any): any,
    radioSize?: 'xsm' | 'sm' | 'lg',
};

const Radio = memo(function Radio({
    id,
    name,
    children,
    className,
    value,
    defaultValue,
    onChange,
    radioSize,
    ...props
}: Readonly<IProps>) {

    const handleOnChange = (event: any) => {
        onChange(event);
    };

    return (
        <div
            className={cx('radio', className, radioSize, { 'is-disabled': props.disabled })}
        >
            <input
                id={id}
                type="radio"
                name={name}
                className="radio__input"
                value={value}
                onChange={handleOnChange}
                checked={value === defaultValue}
                {...props}
            />

            <label htmlFor={id} className="radio__label">
                <span className="radio__icon">
                    <span>
                        <FontAwesomeIcon icon={faDotCircle} />
                    </span>
                </span>

                <span>
                    {children}
                </span>
            </label>
        </div>
    );
});

export default Radio;
