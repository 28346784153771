// React
import { ReactNode, useEffect, useState } from "react";

// Custom
import { MarketDepthType, PriceInfo } from "../../../Types/Websocket";
import { SymbolLinkableWindowComponent } from "../AbstractWindow";
import { LMDInterface } from "../../../KodiInterface/LMD";

import {
  MarketDepthHeaderInfo,
  SymbolSelector,
} from "../MarketDepth/Components";
import { isIcelandic } from "../../../Utils/Definitions";
import { MUITableState } from "../../../Types/MUITable";
import { AppStateManager } from "../../../StateManager";
import { MBLInfo } from "../../../KodiInterface/WebSocket/Handlers/MBLHandler";
import { MarketStatus } from "../../UI-Elements/StatusLights";
import LadderStep from "./LadderStep";

type SaveState = {
  toggleAlignment: MarketDepthType;
  tableState: MUITableState | undefined;
};

type RunState = {
  priceInfo: PriceInfo | undefined;
  currency: string;
  rows: MBLInfo;
};

export class PriceLadder extends SymbolLinkableWindowComponent<RunState, SaveState> {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      priceInfo: undefined,
      currency: "",
      rows: {
        asks: [],
        bids: [],
      },
    };
  }

  componentDidMount(): void {
    if (this.state.symbol !== undefined) this.setNewSymbol(this.state.symbol);
  }

  async setNewSymbol(symbol: string) {
    // Update symbol
    this.setState({ symbol });

    // Set toggle variables
    const toggleEnabled = await isIcelandic(symbol);
    let toggleAlignment = this.state.toggleAlignment;
    if (!toggleEnabled) toggleAlignment = "market_by_level";
    this.setState({ toggleAlignment });

    // Get currency
    LMDInterface.getTradeableInfo(symbol).then(({ TradingCurrency }) =>
      this.setState({ currency: TradingCurrency })
    );

    // Update subscriptions
    this.changeMarketDepthType(symbol, toggleAlignment);
  }

  changeMarketDepthType(symbol: string, marketDepthType: MarketDepthType) {
    this.setState({ toggleAlignment: marketDepthType });
    this.clearSubscriptions();

    // Add symbol price info subscription
    this.subscriptions.push(
      AppStateManager.MF.getHandler("price_info").subscribe(
        symbol,
        (priceInfo: PriceInfo | undefined) => this.setState({ priceInfo })
      )
    );

    // Add symbol marketDepthType subscription
    this.subscriptions.push(
      AppStateManager.MF.getHandler("market_by_level").subscribe(
        symbol,
        (rows) => {
          this.setState({ rows });
        }
      )
    );
  }

  private clearSubscriptions() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  componentDidUpdate(prevProps, prevState, snapshot?: any): void {
    // Save state on each change
    this.saveState({
      tableState: this.state.tableState,
      toggleAlignment: this.state.toggleAlignment,
      linked: this.state.linked,
      symbol: this.state.symbol,
    });
  }

  render(): ReactNode {
    const state = this.state.priceInfo?.orderbook_state ?? "undefined";
    return (
      <div className="market-depth window">
        {this.linkedButton()}
        <div className="autocomplete_wrapper">
          <SymbolSelector
            width={120}
            selectedSymbol={this.state.symbol}
            onSelect={(symbol) => this.setNewSymbol(symbol)}
          />
          {this.state.priceInfo !== undefined && (
            <MarketStatus status={state} />
          )}
        </div>
        <div className="header-container">
          {this.state.priceInfo !== undefined && (
            <MarketDepthHeaderInfo
              priceInfo={this.state.priceInfo}
              currency={this.state.currency}
            />
          )}
        </div>
        <div className="fill" style={{ overflow: "hidden" }}>
          <PriceLadderBody orderbook={this.state.rows} />
        </div>
      </div>
    );
  }
}


const Light: React.FC<{ color: "red" | "green" }> = ({ color }) => (
  <div
    style={{
      width: "12px",
      height: "12px",
      borderRadius: "50%",
      background: `var(--${color}-200)`,
    }}
  ></div>
);
const PriceLadderBody: React.FC<{ orderbook: MBLInfo }> = ({ orderbook }) => {
  const [maxItems, setMaxItems] = useState<number>(1);


  const asks = Object.values(orderbook.asks)
    .sort((a, b) => a.price - b.price)
    .slice(
      0,
      maxItems && maxItems / 2 > Object.values(orderbook.asks).length
        ? Object.values(orderbook.asks).length
        : maxItems / 2
    )
    .sort((a, b) => b.price - a.price);

  const bids = Object.values(orderbook.bids)
    .sort((a, b) => b.price - a.price)
    .slice(
      0,
      maxItems && maxItems / 2 > Object.values(orderbook.bids).length
        ? Object.values(orderbook.bids).length
        : maxItems / 2
    );

  //find the highest volume in buy and sell orders
  const maxVolume = Math.max(
    ...Object.values(asks).map((order) => order.volume || 0),
    ...Object.values(bids).map((order) => order.volume || 0)
  );
  //get the height of element with id priceLadder

  useEffect(() => {
    const priceLadderElement = document.getElementById("priceLadder");

    if (priceLadderElement) {
      const resizeObserver = new ResizeObserver(() => {
        const priceLadderHeight = priceLadderElement.clientHeight - 32;
        setMaxItems(Math.floor(priceLadderHeight / 18));
      });

      resizeObserver.observe(priceLadderElement);

      // Clean up function
      return () => {
        resizeObserver.unobserve(priceLadderElement);
      };
    }
  }, [orderbook]);

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "5px 0",
          borderBottom: "1px solid var(--dark-200)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            color: "var(--dark-900)",
            fontSize: "12px",
            lineHeight: "12px",
            fontWeight: 500,
          }}
        >
          <Light color={"green"} />
          Buy orders
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
            color: "var(--dark-900)",
            fontSize: "12px",
            lineHeight: "12px",
            fontWeight: 500,
          }}
        >
          Sell orders
          <Light color={"red"} />
        </div>
      </div>
      <div style={{ height: "100%" }} id="priceLadder">
        {/* //sort after price */}
        {asks.map((order, index) => {
          return (
            <LadderStep
              maxVol={maxVolume}
              key={order.price}
              color={"red"}
              price={order.price}
              volume={order.volume}
            />
          );
        })}

        {/* //sort after price */}
        {bids.map((order, index) => {
          return (
            <LadderStep
              maxVol={maxVolume}
              key={order.price}
              color={"green"}
              price={order.price}
              volume={order.volume}
            />
          );
        })}
      </div>
    </div>
  );
};
