import { LMDInterface } from "../../../KodiInterface/LMD";
import { AppStateManager } from "../../../StateManager";
import { OrderSubmitRequest } from "../../../Types/private-data/NewOrderSubmitRequest";
import { excludeKeys } from "../../../Utils/Common";

interface OrderResponse {
    response?: string;
    reason?: string;
}

interface ErrorResponse {
    text?: string;
}

interface UpdateOrderParams {
    orderId: string;
    broker?: string;
    price?: number;
    quantity?: number;
}

class OrderService {
    private static getOrderType(): 'retail' | 'dma' {
        return AppStateManager.hasAccessToRetail.getValue() ? 'retail' : 'dma';
    }

    private static sanitizeValues(values: OrderSubmitRequest): OrderSubmitRequest {
        const cleanedValues = { ...values };
        Object.keys(cleanedValues).forEach(key => {
            if ([null, "", undefined].includes(cleanedValues[key])) {
                delete cleanedValues[key];
            }
        });
        return cleanedValues;
    }

    private static async buildRequestBody(values: OrderSubmitRequest, orderType: string): Promise<string> {
        const cleanedValues = this.sanitizeValues(values);
        if (orderType === 'retail') {
            cleanedValues.client_reference = AppStateManager.customerInfo?.ssn?.toString();
        }
        return JSON.stringify(excludeKeys(cleanedValues, ['broker']));
    }

    static async sendOrder(values: OrderSubmitRequest, callback: (result: OrderResponse) => void): Promise<void> {
        try {
            const orderType = this.getOrderType();
            const body = await this.buildRequestBody(values, orderType);
            const url = `https://${values.broker}/kodi/pds/${orderType}/1.0.0/api/orders`;

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await AppStateManager.getToken()}`
                },
                body
            });

            callback(await response.json());
        } catch {
            callback({ reason: 'Something went wrong' });
        }
    }

    static async updateOrder(values: UpdateOrderParams, callback: (result: OrderResponse) => void): Promise<void> {
        const orderType = this.getOrderType();

        try {
            const data = await LMDInterface.updateOrder(
                orderType,
                values.broker,
                values.orderId,
                values.price,
                values.quantity
            );

            if (data.response === "success") {
                data.response = "updated";
            }

            callback(data);
        } catch (error) {
            callback(this.handleUpdateError(error));
        }
    }

    private static handleUpdateError(error: unknown): OrderResponse {
        if (typeof error !== 'string') {
            return { reason: (error as ErrorResponse)?.text || 'Unknown error' };
        }

        try {
            return JSON.parse(error);
        } catch {
            const match = /"text":"(.*?)"/.exec(error);
            return { reason: match?.[1] || 'Unknown error' };
        }
    }
}

export default OrderService;