// React and related libraries
import React from "react";
import { Subscription } from "rxjs";
import cx from "classnames";
import { styled } from '@mui/system';
import { Autocomplete, Box, TextField } from "@mui/material";

// FontAwesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";

// Types
import { ListInfo } from "../../../Types/LMDTypes";
import { Trade } from "../../../Types/Websocket";
import { MUITableState } from "../../../Types/MUITable";

// Components and utilities
import { WindowComponent, getGlobalLinkedWindowManager } from "../AbstractWindow";
import { LMDInterface } from "../../../KodiInterface/LMD";
import { isIcelandic } from "../../../Utils/Definitions";
import { MUITable } from "../../Tables/MUIProTable";
import { TradeTickerColumnsInfo } from "./Columns";
import { getHandler } from "../../../StateManager";
import { eventBus } from '../WatchList/EventBus';
import formatValue from "./FormatValue";

type SaveState = {
    linked: boolean;
    symbolOrList: ListInfo | string | undefined;
    tableState: MUITableState | undefined;
};

type RunState = {
    trades: Trade[];
    symbols: string[];
    lists: ListInfo[];
    loading: boolean;
    isOpen: boolean;
}
export class TradeTicker extends WindowComponent<RunState, SaveState> {
    linkSubscriptions: Subscription[];
    refreshSubscription: Subscription;
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            trades: [], symbols: [], lists: [], loading: false,
            isOpen: false,
        };
        this.linkSubscriptions = [];
        this.refreshSubscription = new Subscription();
    }

    componentDidMount(): void {
        LMDInterface.getAllLists().then(lists => {
            this.setState({ lists });
            if (this.state.symbolOrList) {
                const list = lists.find(list => this.state.symbolOrList && typeof this.state.symbolOrList !== 'string' && list.symbol === this.state.symbolOrList?.symbol);
                if (!list && typeof this.state.symbolOrList !== 'string') {
                    // If we set list to undefined it creates weird behaviour in autocomplete component
                    // So we set it to an empty instance of ListInfo
                    this.setSymbolOrList({ count: 0, name: '', symbol: '', country: '' });
                }
            }
        });
        LMDInterface.getAllSymbols().then(symbols => this.setState({ symbols }));

        this.refreshSubscription = eventBus.subscribe((event) => {
            if (event === 'triggerRefresh') {
                this.refreshList();
            }
        });
        if (this.props.state.linked) this.subscribeGLWM();
        if (this.state.symbolOrList !== undefined) this.setSymbolOrList(this.state.symbolOrList);
    }

    async setSymbolOrList(symbolOrList: ListInfo | string) {
        let oldTrades = this.state.trades;
        this.setState({ symbolOrList, trades: [], loading: true });
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];

        // Get all symbols
        const symbols: string[] =
            typeof symbolOrList === 'string' ?
                [symbolOrList] :
                await LMDInterface.getTradeableListSymbols(symbolOrList);
        const icelandicSymbols = await LMDInterface.getTradeableListSymbols('[all_icelandic_tradables;all_icelandic_shares]');
        // Can't use isIcelandic here because filter has to be synchronous for some reason
        oldTrades = oldTrades.filter(trade => symbols.includes(trade.symbol) && !icelandicSymbols.includes(trade.symbol));
        // If one icelandic symbol get initial trades
        if (symbols.some(async symbol => await isIcelandic(symbol))) {
            //get all trades for the icelandic market
            let trades: Trade[] = oldTrades.concat(await LMDInterface.getTrades());
            // If there are any trades
            if (trades.length > 0) {
                // filter out all trades that have symbols that are not in the symbols array
                trades = trades.filter(trade => symbols.includes(trade.symbol));
                // Sort initial trades if any
                trades.sort((a, b) => -a.time_executed.getTime() + b.time_executed.getTime());
                this.setState({ trades });
            }
        } else {
            this.setState({ trades: oldTrades });
        }

        // Subscribe to any incoming messages
        symbols.forEach(symbol => this.subscriptions.push(
            getHandler('trade').subscribe(symbol, trade => {
                const trades = this.state.trades;
                trades.unshift(trade);
                this.setState({ trades: trades.slice(0, 200) });
            })
        ));
        this.setState({ loading: false })
    }

    refreshList = () => {
        setTimeout(() => {
            LMDInterface.getAllLists().then(lists => {
                this.setState({ lists });
                if (this.state.symbolOrList) {
                    const list = lists.find(list => this.state.symbolOrList && typeof this.state.symbolOrList !== 'string' && list.symbol === this.state.symbolOrList?.symbol);
                    if (!list) {
                        // If we set list to undefined it creates weird behaviour in autocomplete component
                        // So we set it to an empty instance of ListInfo
                        this.setSymbolOrList({ count: 0, name: '', symbol: '', country: '' });
                    } else if (list.country === 'My Lists') {
                        // Causes a state update so that watchlist table refreshes
                        this.setSymbolOrList(list);
                    }
                }
            });
        }, 1000);
    };

    private subscribeGLWM() {
        const GLWM = getGlobalLinkedWindowManager();
        const subscription = GLWM.listenerSubscribe(symbol => this.setSymbolOrList(symbol))
        this.linkSubscriptions.push(subscription);
    }

    link() {
        this.setState({ linked: true as any });
        this.subscribeGLWM();
    }

    unlink() {
        this.setState({ linked: false as any });
        this.linkSubscriptions.forEach(subscription => subscription.unsubscribe());
        this.linkSubscriptions = [];
    }

    componentDidUpdate(prevProps, prevState, snapshot?: any): void {
        // Save state on each change
        this.saveState({
            tableState: this.state.tableState,
            linked: this.state.linked,
            symbolOrList: this.state.symbolOrList
        });
    }

    render() {
        const value = this.state.symbolOrList;
        return (
            <div className='window'>
                <Autocomplete
                    autoHighlight
                    popupIcon={<FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '10px' }} />}

                    options={[...this.state.lists, ...this.state.symbols]}
                    sx={{
                        width: '200px', padding: 0,
                        '& input': {
                            height: 10,
                        },
                    }}
                    onOpen={() => this.setState({ isOpen: true })}
                    onClose={() => this.setState({ isOpen: false })}
                    getOptionLabel={(option) => typeof option !== 'string' ? option.name : option}
                    renderGroup={(params) => {
                        const GroupItems = styled('ul')({ padding: 0 });
                        return <li key={params.key} style={{ paddingTop: 6, paddingBottom: 4 }}>
                            <div style={{
                                paddingLeft: 8, paddingRight: 8, paddingTop: 0, paddingBottom: 4,
                                background: 'var(--white)',
                                textTransform: 'uppercase',
                                fontSize: '10px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: 'normal',
                                color: 'var(--dark-400)',

                            }}>{params.group}</div>
                            <GroupItems>{params.children}</GroupItems>
                        </li>
                    }}
                    groupBy={(option) => typeof option !== 'string' ? option.country as string : 'Symbols'}
                    renderOption={(props, option) => {
                        if (typeof option === 'string')
                            return <Box component="li" {...props}
                                sx={{
                                    color: 'var(--dark-900, #232530)',
                                    fontSize: '11px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >{option}</Box>
                        else return (
                            <Box component="li" {...props} sx={{
                                color: 'var(--dark-900, #232530)',
                                fontSize: '11px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }} >
                                <div style={{
                                    maxWidth: '85%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'
                                }}>{option.name}
                                </div>
                                <div style={{ width: '15%', paddingLeft: 10 }}>({option.count})</div>
                            </Box >);
                    }}
                    renderInput={(params) => <TextField
                        {...params}
                        className={cx('autocomplete-custom', { 'isOpen': this.state.isOpen })} />
                    }
                    disableClearable
                    value={value || null as any}
                    size='small'
                    isOptionEqualToValue={(a, b) => JSON.stringify(a) === JSON.stringify(b)}

                    onChange={(event: any, newValue: ListInfo | string) => this.setSymbolOrList(newValue)}
                />
                {this.linkedButton()}
                <MUITable
                    rows={this.state.trades.map(trade => { return { ...trade, id: JSON.stringify(trade) } })}
                    columns={TradeTickerColumnsInfo}
                    cell={(column, row) => formatCell(column, row as unknown as Trade)}
                    newLineFlicker
                    tableState={this.state.tableState}
                    saveState={state => this.setState({ tableState: state })}

                    loading={this.state.loading}
                />
            </div>
        );
    }
}

// Define types for better type safety
type TradeRow = Record<string, any>;
type CellStyle = React.CSSProperties;

// Style determination helpers
const getJustification = (type: string): CellStyle => {
    switch (type) {
        case 'number': return { justifyContent: 'flex-end' };
        case 'string': return { justifyContent: 'flex-start' };
        case 'boolean': return { width: '100%' };
        default: return { justifyContent: 'center' };
    }
};

const getAggressivePartyStyle = (column: string, aggressiveParty?: string): CellStyle => {
    if (!aggressiveParty) return {};

    const styleMap = {
        buyer: {
            condition: aggressiveParty === 'B',
            background: 'var(--green-200)',
            color: 'var(--green-600)'
        },
        seller: {
            condition: aggressiveParty === 'S',
            background: 'var(--red-200)',
            color: 'var(--red-600)'
        }
    };

    const partyStyle = styleMap[column as keyof typeof styleMap];
    return partyStyle?.condition
        ? {
            background: partyStyle.background,
            width: '100%',
            height: '100%'
        }
        : {};
};

function formatCell(column: string, row: TradeRow): React.JSX.Element {
    // Get the raw value
    let value = row[column];

    // Determine base style
    const type = typeof value;
    const style: CellStyle = {
        justifyContent: 'center',
        ...getJustification(type),
        ...getAggressivePartyStyle(column, row.aggressive_party)
    };

    // Format the value
    const formattedValue = formatValue(column, value, row);

    return (
        <div className="center-container fill" style={style}>
            {formattedValue}
        </div>
    );
}

export default formatCell;