import { formatPercent, formatNumber, formatWithLetters } from '../../../Utils/Formatting';
import { TranslationManager } from '../../../Translation/Translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownLong, faUpLong } from '@fortawesome/pro-solid-svg-icons';

interface CellStyle {
    display: 'flex';
    alignItems: 'center';
    height: '100%';
    justifyContent?: 'flex-end';
    color?: string;
    gap?: string;
    animationName?: string;
    animationTimingFunction?: string;
    animationDuration?: string;
}

function createBaseStyle(): CellStyle {
    return {
        display: 'flex',
        alignItems: 'center',
        height: '100%'
    };
}

function getOrderbookStateColor(state: string): string {
    if (state === "closed") return 'var(--red-600)';
    if (state === "not_available") return 'var(--red-600)';
    if (state === "trading") return 'var(--green-600)';
    return 'var(--orange)';
}

function formatAmount(value: number, column: string): string {
    const largeAmountColumns = [
        'market_capital',
        'intraday_accumulated_turnover',
        'intraday_accumulated_volume'
    ];

    if (largeAmountColumns.includes(column)) {
        return formatWithLetters(value);
    }

    const isYieldColumn = column.includes('yield') &&
        column !== 'last_yield_diff_closing_basispoints';

    if (isYieldColumn) {
        return formatPercent(value);
    }

    return formatNumber(value);
}

function createArrowIcon(value: number, color: string): React.JSX.Element {
    let icon = faUpLong;
    if (value < 0) {
        icon = faDownLong;
    }

    return (
        <FontAwesomeIcon
            icon={icon}
            style={{
                color,
                fontSize: '9px',
                lineHeight: 'normal'
            }}
        />
    );
}

function handleNumberValue(
    value: number,
    column: string,
    prevValue: number | undefined,
    lastAnimationID: number,
    flicker: boolean
): React.JSX.Element {
    const style = createBaseStyle();
    style.justifyContent = 'flex-end';

    if (column === 'last_price_diff_closing_percent') {
        if (value === 0) return <div></div>;
        const formattedValue = formatPercent(value);
        style.color = 'var(--red)';
        if (value > 0) {
            style.color = 'var(--green-600)';
        }
        style.gap = '5px';
        return (
            <div style={style}>
                {createArrowIcon(value, style.color)}
                {formattedValue}
            </div>
        );
    }

    if (flicker && prevValue !== undefined && prevValue !== value) {
        const direction = prevValue < value ? 'up' : 'down';
        style.animationName = `${direction}-animation-${lastAnimationID}`;
        style.animationTimingFunction = 'ease';
        style.animationDuration = '1.75s';
    }

    return <div style={style}>{formatAmount(value, column)}</div>;
}

function handleStringValue(
    value: string,
    column: string,
    row: Record<string, any>
): React.JSX.Element {
    const style = createBaseStyle();

    if (['Name', 'orderbook_state', 'symbol'].includes(column) &&
        'orderbook_state' in row) {
        let displayValue = value;
        if (column === 'orderbook_state') {
            displayValue = TranslationManager.getTranslation().OrderbookState[value || ''];
        }
        style.color = getOrderbookStateColor(row.orderbook_state);
        return <div style={style}>{displayValue}</div>;
    }

    if (column === 'change') {
        const priceDiff = row.last_price_diff_closing_percent;
        if (priceDiff === undefined) {
            return <div />;
        }
        let color = 'var(--red-600)';
        if (priceDiff > 0) {
            color = 'var(--green-600)';
        }
        if (priceDiff === 0) {
            return <div />;
        }
        return (
            <div className='center-container fill'>
                {createArrowIcon(priceDiff, color)}
            </div>
        );
    }

    return <div style={style}>{value}</div>;
}

export function formatWatchListCell(
    column: string,
    row: Record<string, any>,
    prevValue: any,
    lastAnimationID: number,
    flicker: boolean
): React.JSX.Element {
    const value = row[column];

    if (typeof value === 'number') {
        return handleNumberValue(value, column, prevValue, lastAnimationID, flicker);
    }

    return handleStringValue(value, column, row);
}