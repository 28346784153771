import { TranslationManager } from "../../../Translation/Translation";
import Button from "../../UI-Elements/Button";
import ClearAllButton from "../../UI-Elements/ClearAllButton";
import { OrderFormValues } from "../NewOrderModalFormComponents";
import OrderSubmitButton from "./OrderSubmitButton";
import { SymbolInfo } from "./SymbolInfo";

// OrderFormFooter.tsx
export const OrderFormFooter: React.FC<{
    onClose: () => void;
    values: OrderFormValues;
    validateForm: () => Promise<any>;
    setErrors: (errors: any) => void;
    setTouched: (touched: any) => void;
    setPhaseTwo: (value: any) => void;
    setCheck: (value: any) => void;
    priceInfo: any;
    setPriceInfo: (value: any) => void;
}> = ({ onClose, values, validateForm, setErrors, setTouched, setPhaseTwo, setCheck, priceInfo, setPriceInfo }) => (
    <div className="button-container">
        <div>
            <ClearAllButton>
                <span style={{ position: 'relative', zIndex: '1', color: 'var(--blue-600)' }}>
                    {TranslationManager.getTranslation().Buttons.Reset_form}
                </span>
            </ClearAllButton>
            <OrderSubmitButton
                values={values}
                validateForm={validateForm}
                setErrors={setErrors}
                setTouched={setTouched}
                setPhaseTwo={setPhaseTwo}
                setCheck={setCheck}
                priceInfo={priceInfo}
            />
            <Button size="sm" type="button" buttonType="secondary" onClick={onClose}>
                {TranslationManager.getTranslation().Buttons.Cancel}
            </Button>
        </div>
        <div className="symbol-info-section">
            <SymbolInfo
                onPriceInfoChange={setPriceInfo}
                symbol={values.symbol}
            />
        </div>
    </div>
);