import "./Header.scss";
import cx from "classnames";
import React from "react";
import { SettingsDropDown } from "./Settings";
import { WorkspaceDropDown } from "./Workspace";
import { TranslationManager } from "../../Translation/Translation";
import { KELDAN_LOGO } from "../../svgs";
import { TradingDropDown } from "./Trading";
import { AppStateManager } from "../../StateManager";
import { Environment } from "../../Config/Environment";
import RetailTradingButton from "../UI-Elements/RetailTradingButton";
import { Config } from "../../Config/Config";
import LanguageSelectDropdown from "../UI-Elements/LanguageSelectDropdown";

interface HeaderProps {
  active: boolean;
  login?: boolean;
}
export const Header: React.FC<HeaderProps> = ({ active, login }) => {

  return (
    <header className={cx({ login_header: login })}>
      <div className="logoSection">
        <div>{KELDAN_LOGO}</div>
        <div className="vertical-ruler"></div>
        <span className="marketWrapper">
          {TranslationManager.getTranslation().Header.Market}
        </span>
        <div className="beta_label">
          <span>Beta</span>
        </div>
      </div>
      {
        Environment !== 'production' && <div style={{ position: 'absolute', right: '50%', top: '8px', background: 'yellow', textTransform: 'uppercase', padding: '0 4px', transform: 'translate(50%, 0)' }}>
          <h3>{window.location.host.includes('staging') ? 'Staging' : 'Development'} - {Config.getBackendType()}</h3>
        </div>
      }
      {active && (
        <div className="rightSection">
          {/* {!AppStateManager.hasAccessToPDS && <> <RetailTradingButton />
            <div className="vertical-ruler"></div>
          </>} */}
          <WorkspaceDropDown />
          <div className="vertical-ruler"></div>
          {
            AppStateManager.hasAccessToPDS && <>
              <TradingDropDown tradingType="DMA" />
              <div className="vertical-ruler"></div>
            </>
          }
          <SettingsDropDown />
        </div>
      )}
      {
        login && <div>
          <LanguageSelectDropdown />
        </div>
      }
    </header>
  );
};
