import { AppStateManager } from "../StateManager";
import { v4 as uuidv4 } from 'uuid';

export function capitalizeWord(word: string): string {
    if (word === null || word === undefined || word.length < 1) return '';
    return word[0].toUpperCase() + word.slice(1);
}



export function setComparison<T>(set1: Set<T>, set2: Set<T>): boolean {
    return set1.size === set2.size && [...set1].every(x => set2.has(x));
}



export function setDifference<T>(set1: Set<T>, set2: Set<T>): [set1notin2: Set<T>, set2notin1: Set<T>] {
    return [
        new Set([...set1].filter(item => !set2.has(item))),
        new Set([...set2].filter(item => !set1.has(item)))
    ]
}


// ChatGPT
export function flatten(array: any[]): any[] {
    let flatArray: any[] = [];

    array.forEach(item => {
        if (Array.isArray(item))
            flatArray = flatArray.concat(flatten(item));
        else
            flatArray.push(item);
    });

    return flatArray;
}

// Mainly used for strict typing
export function mapExpressionToValue<T extends string, T2>(expressionLookup: Record<T, T2>, expression: T): T2 {
    return expressionLookup[expression];
}

// Easy way to expose the resolve, reject of a promise
export function createPromise<T>() {
    let resolve, reject;
    const promise = new Promise<T>((_resolve, _reject) => { resolve = _resolve; reject = _reject; });
    return [promise, resolve, reject];
}

const CHAR_CODE_CORRECTION: {
    [T in number]: number | undefined
} = {
    225: 97.5,      // á => milli a & b
    240: 100.5,     // ð => milli d & e
    233: 101.5,     // é => milli e & f
    237: 105.5,     // í => milli i & j
    243: 111.5,     // ó => milli o & p
    250: 117.5,     // ú => milli u & v
    253: 121.5,     // ý => milli y & z
    254: 122.2,     // þ => aðeins meira en z
    230: 122.4,     // æ => aðeins meira en þ
    246: 122.6,     // ö => aðeins meira en æ
}

export const sortIcelandic = (a: string | null, b: string | null): number => {
    if (a === null && b === null) return 0;
    if (a === null) return -1;
    if (b === null) return 1;
    const lowercaseA = a.toLowerCase();
    const lowercaseB = b.toLowerCase();
    const charCodeA = lowercaseA.charCodeAt(0);
    const charCodeB = lowercaseB.charCodeAt(0);
    const correctedCharCodeA = CHAR_CODE_CORRECTION[charCodeA] ?? charCodeA;
    const correctedCharCodeB = CHAR_CODE_CORRECTION[charCodeB] ?? charCodeB;
    if (correctedCharCodeA === correctedCharCodeB) return sortIcelandic(a.slice(1) || null, b.slice(1) || null);
    return correctedCharCodeA - correctedCharCodeB;
}

export const updateDoubleMapValue = <T extends unknown>(
    doubleMap: {
        [key1 in string]?: {
            [key2 in string]?: T
        }
    },
    key1: string,
    key2: string,
    newData: T
) => {
    // Copy Double Map.
    const doubleMapCopy = { ...doubleMap };
    // Set if empty.
    if (doubleMapCopy?.[key1] === undefined) {
        doubleMapCopy[key1] = {};
    }
    // Copy Single map.
    const nestedMapCopy = { ...doubleMapCopy[key1] };
    // Set data.
    nestedMapCopy[key2] = newData;
    // Put the copies back.
    doubleMapCopy[key1] = nestedMapCopy;
    // Return copy.
    return doubleMapCopy;
}

export function getCurrencySymbol(currencyCode: string | null): string {
    switch (currencyCode) {
        case "ISK": return "kr";
        case "EUR": return "€";
        case "USD": return "$";
        case "GBP": return "£";
        case "JPY": return "¥";
        case "CHF": return "Fr";
        case "DKK": return "kr";
        case "SEK": return "kr";
        case "NOK": return "kr";
        case "CAD": return "C$";
        case "PLN": return "zł";
        case null: return "";
        default: return currencyCode; // Default to empty string if currency code is not recognized
    }
}

export function randomString16Char() {
    return (f => f() + f())(() => Math.random().toString(36).substring(2, 10));
}
export function excludeKeys(obj: Record<string, any>, keysToExclude: string[]): Record<string, any> {
    return Object.keys(obj)
        .filter(key => !keysToExclude.includes(key))
        .reduce((newObj: Record<string, any>, key: string) => {
            newObj[key] = obj[key];
            return newObj;
        }, {});
}

export function getPDSURLFromName(name?: string) {
    return AppStateManager.userProfile.access.find(x => x.name === name)?.url
}

export function isNullOrUndefined(data: any): data is null | undefined {
    return typeof data === 'undefined' || data === null;
}

export function capitalizeFirstChar(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function generateUUID(): string {
    return uuidv4();
}