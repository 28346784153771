import { useState, useCallback } from 'react'
import cx from 'classnames';
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import ISLocale from 'date-fns/locale/is';
import enGB from 'date-fns/locale/en-GB';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDay, faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import { useField, useFormikContext } from 'formik';
import { TranslationManager } from '../../../Translation/Translation';

interface IProps {
    [x: string]: any,
    name: string,
    value: string | null,
    label?: string,
    size?: 'lg' | 'sm' | 'xsm',
    lang?: string,
    maxDate?: Date,
    minDate?: Date,
    disabled?: boolean,
    id?: string,
    max_width?: boolean,
    dateFormat?: string
}

export const DatePickerField = ({
    label,
    size = 'lg',
    lang = 'en',
    maxDate,
    minDate,
    dateFormat = 'dd.MM.yyyy',
    max_width,
    disabled = false,
    id = 'date-picker',
    ...props
}: IProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const locale = TranslationManager.getActiveLanguage() === 'IS' ? ISLocale : enGB;
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(props.name);

    // Memoize the isLabelOnTop calculation
    const isLabelOnTop = props.value !== '' && props.value !== null || isOpen;

    // Memoize the onChange handler
    const handleDateChange = useCallback((val: Date | null) => {
        if (val) {
            // Format the date to match the expected format
            const formattedDate = format(val, dateFormat);
            setFieldValue(field.name, formattedDate, false);
        } else {
            setFieldValue(field.name, null, false);
        }
    }, [field.name, setFieldValue, dateFormat]);

    // Memoize calendar open/close handlers
    const handleCalendarOpen = useCallback(() => setIsOpen(true), []);
    const handleCalendarClose = useCallback(() => setIsOpen(false), []);

    // Memoize the custom header buttons handlers
    const renderCustomHeader = useCallback(({
        monthDate,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    }) => (
        <div className="react-datepicker__header-inner">
            <button
                aria-label={lang === 'is' ? 'Fyrri mánuður' : 'Previous Month'}
                className="react-datepicker__navigation react-datepicker__navigation--previous"
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    decreaseMonth();
                }}
                disabled={prevMonthButtonDisabled}
            >
                <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.281 9.719a1.01 1.01 0 0 0 1.094.219C5.75 9.78 6 9.405 6 9V1c0-.375-.25-.75-.625-.906a1.01 1.01 0 0 0-1.094.218l-4 4A.97.97 0 0 0 0 5a.99.99 0 0 0 .281.719l4 4Z" fill="currentColor" />
                </svg>
            </button>

            <div className="react-datepicker__current-month">
                {format(monthDate, 'MMMM y', { locale: locale })}
            </div>

            <button
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    increaseMonth();
                }}
                disabled={nextMonthButtonDisabled}
                aria-label={lang === 'is' ? 'Næsti mánuður' : 'Next Month'}
                className="react-datepicker__navigation react-datepicker__navigation--next"
            >
                <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.688.313A1.01 1.01 0 0 0 .593.092.984.984 0 0 0 0 1v8c0 .406.219.781.594.938a1.01 1.01 0 0 0 1.094-.22l4-4C5.875 5.532 6 5.282 6 5c0-.25-.125-.5-.313-.688l-4-4Z" fill="currentColor" />
                </svg>
            </button>
        </div>
    ), [lang, locale]);

    return (
        <div>
            <div
                className={cx('KCL_datepicker', size, {
                    'is-open': isOpen,
                    'has-error': meta.error && meta.touched,
                    'disabled': disabled,
                })}
            >
                <label
                    htmlFor={id}
                    className={cx("datepicker__label", {
                        'is-on-top': isLabelOnTop,
                        'disabled': disabled,
                        'has-error': meta.error && meta.touched
                    })}
                >
                    {label}
                </label>
                {disabled ? (
                    <input
                        disabled
                        className={cx('datepicker__input', 'disabled', { 'max_width': max_width })}
                        id={id}
                    />
                ) : (
                    <DatePicker
                        {...field}
                        id={id}
                        popperPlacement="bottom-end"
                        className={cx('datepicker__input', {
                            'has-error': meta.error && meta.touched,
                            'max_width': max_width
                        })}
                        // selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat={dateFormat}
                        locale={locale}
                        onCalendarOpen={handleCalendarOpen}
                        onCalendarClose={handleCalendarClose}
                        minDate={minDate}
                        maxDate={maxDate}
                        renderCustomHeader={renderCustomHeader}
                        tabIndex={-1}
                    />
                )}
                <FontAwesomeIcon
                    icon={meta.error && meta.touched ? faTimesCircle : faCalendarDay}
                    className={cx("datepicker__icon", {
                        "icon-fa error-icon": meta.error && meta.touched
                    })}
                />
            </div>
            {meta.error && meta.touched && (
                <div className="datePicker-error-message">{meta.error}</div>
            )}
        </div>
    );
};