// TradingComponents.tsx
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import * as React from "react";
import { GLWindowManager } from "../Windows/GLWindowManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faArrowUpArrowDown,
  faCirclePlus,
  faLayerGroup,
  faLayerPlus,
} from "@fortawesome/pro-solid-svg-icons";
import cx from "classnames";
import Button from "@mui/material/Button";
import { Popover } from "@mui/material";
import { TranslationManager } from "../../Translation/Translation";
import { ComponentName, TradingComponentNameList } from "../../Types/Windows";
import NewOrderModal from "../Modals/NewOrderModal";
import { hideDefaultModal, openDefaultModal } from "../Main/Main";


interface EdgeCaseFixComponentItemProps {
  componentName: ComponentName;
  icon: typeof faLayerGroup;
}

function EdgeCaseFixComponentItem({ componentName, icon }: EdgeCaseFixComponentItemProps) {
  const ref = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (ref.current) {
      ref.current.appendChild(
        GLWindowManager.draggableAddElements[componentName]
      );
    }
  }, [componentName]);

  return (
    <div className="menu-item" ref={ref}>
      <FontAwesomeIcon
        style={{
          transform: componentName === "ViewTrades" ? "rotate(90deg)" : "rotate(0)",
        }}
        className="fa-icon"
        icon={icon}
      />
    </div>
  );
}

interface TradingDropDownProps {
  tradingType?: 'DMA' | 'Retail';
}

export function TradingDropDown({ tradingType = 'DMA' }: TradingDropDownProps) {
  const [localModalOpen, setLocalModalOpen] = React.useState(false);

  function getIcon(componentName: ComponentName) {
    switch (componentName) {
      case "ViewOrders":
        return faLayerGroup;
      case "ViewTrades":
        return faArrowUpArrowDown;
      default:
        return faCirclePlus;
    }
  }

  const handleNewOrder = (popupState: any) => {
    if (tradingType === 'DMA') {
      openDefaultModal(<NewOrderModal open={true} onClose={hideDefaultModal} />);
    } else {
      setLocalModalOpen(true);
    }
    popupState.close();
  };

  return (
    <>
      <PopupState variant="popover">
        {(popupState) => (
          <React.Fragment>
            <Button
              className="noHoverButton"
              disableRipple
              size="small"
              variant="text"
              {...bindTrigger(popupState)}
            >
              <div className={cx("headerButton", { active: popupState.isOpen })}>
                <FontAwesomeIcon
                  className="fa-icon"
                  style={{
                    fontSize: "14px",
                    marginRight: "6px",
                    transform: "rotate(90deg)",
                  }}
                  icon={faArrowUpArrowDown}
                />
                {TranslationManager.getTranslation().Buttons.Trading}
                <FontAwesomeIcon
                  className={cx("fa-icon rotate", {
                    "rotate-180": popupState.isOpen,
                  })}
                  style={{ marginLeft: "8px" }}
                  icon={faAngleDown}
                />
              </div>
            </Button>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div className="dwMenu">
                <div className="dashboard">
                  <div
                    className="wrapper"
                    onClick={() => handleNewOrder(popupState)}
                  >
                    <div className="menu-item">
                      <FontAwesomeIcon className="fa-icon" icon={faLayerPlus} />
                      <div>
                        {TranslationManager.getTranslation().Trading.NewOrder}
                      </div>
                    </div>
                  </div>
                  {TradingComponentNameList.map((componentName) => (
                    <div
                      className="wrapper"
                      onClick={() => {
                        GLWindowManager.addComponentToRoot(componentName);
                        popupState.close();
                      }}
                      key={componentName}
                    >
                      <EdgeCaseFixComponentItem
                        componentName={componentName}
                        icon={getIcon(componentName)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Popover>
          </React.Fragment>
        )}
      </PopupState>
      {tradingType === 'DMA' && (
        <NewOrderModal
          open={localModalOpen}
          onClose={() => setLocalModalOpen(false)}
        />
      )}
    </>
  );
}