// React
import { ReactNode } from "react";

// Custom
import { MBOOrder, MarketDepthType, PriceInfo } from "../../../Types/Websocket";
import { SymbolLinkableWindowComponent } from "../AbstractWindow";
import { LMDInterface } from "../../../KodiInterface/LMD";
import { formatNumber, formatPercent } from "../../../Utils/Formatting";
import { MUITable } from "../../Tables/MUIProTable";
import { MarketByOrderColumnsInfo } from "./Columns";
import { LevelInfo, MarketByOrderInfo } from "../../../Types/Windows";

import {
  MarketDepthHeaderInfo,
  MarketDepthSelector,
  SymbolSelector,
} from "./Components";
import { isIcelandic } from "../../../Utils/Definitions";
import { MUITableState } from "../../../Types/MUITable";
import { AppStateManager } from "../../../StateManager";
import { MarketStatus } from "../../UI-Elements/StatusLights";
import { TranslationManager } from "../../../Translation/Translation";

type SaveState = {
  toggleAlignment: MarketDepthType;
  tableState: MUITableState | undefined;
};

type RunState = {
  priceInfo: PriceInfo | undefined;
  currency: string;
  toggleEnabled: boolean;
  rows: any[];
};

export class MarketDepth extends SymbolLinkableWindowComponent<RunState, SaveState> {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      priceInfo: undefined,
      currency: "",
      toggleEnabled: false,
      rows: [],
    };
  }

  componentDidMount(): void {
    if (this.state.symbol !== undefined) this.setNewSymbol(this.state.symbol);
  }
  async setNewSymbol(symbol: string) {
    // Update symbol
    this.setState({ symbol });

    // Set toggle variables
    const toggleEnabled = await isIcelandic(symbol);
    let toggleAlignment = this.state.toggleAlignment;
    if (!toggleEnabled) toggleAlignment = "market_by_level";
    this.setState({ toggleEnabled, toggleAlignment });

    // Get currency
    LMDInterface.getTradeableInfo(symbol).then((tradeableInfo) => {
      if (tradeableInfo) {
        const { TradingCurrency } = tradeableInfo;
        this.setState({ currency: TradingCurrency });
      }
    });

    // Update subscriptions
    this.changeMarketDepthType(symbol, toggleAlignment);
  }

  changeMarketDepthType(symbol: string, marketDepthType: MarketDepthType) {
    this.setState({ toggleAlignment: marketDepthType });
    this.clearSubscriptions();

    // Add symbol price info subscription
    this.subscriptions.push(
      AppStateManager.MF.getHandler("price_info").subscribe(
        symbol,
        (priceInfo: PriceInfo | undefined) => this.setState({ priceInfo })
      )
    );

    // Add symbol marketDepthType subscription
    this.subscriptions.push(
      marketDepthType === "market_by_level"
        ? AppStateManager.MF.getHandler("market_by_level").subscribe(
          symbol,
          (marketDepthInfo) => {
            const rows = rowsFromMarketDepth(marketDepthInfo);
            this.setState({ rows });
          }
        )
        : AppStateManager.MF.getHandler("market_by_order").subscribe(
          symbol,
          (marketByOrderInfo) => {
            const rows = rowsFromMarketByOrder(marketByOrderInfo);
            this.setState({ rows });
          }
        )
    );
  }

  private clearSubscriptions() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  componentDidUpdate(prevProps, prevState, snapshot?: any): void {
    // Save state on each change
    this.saveState({
      tableState: this.state.tableState,
      toggleAlignment: this.state.toggleAlignment,
      linked: this.state.linked,
      symbol: this.state.symbol,
    });
  }

  render(): ReactNode {
    const state = this.state.priceInfo?.orderbook_state ?? "undefined";
    // Calculate maxVolume
    const maxB = Math.max(
      ...this.state.rows.map((row) => row.b_volume ?? -Infinity)
    );
    const maxA = Math.max(
      ...this.state.rows.map((row) => row.a_volume ?? -Infinity)
    );
    const maxVolume = Math.max(maxB, maxA);
    return (
      <div className="market-depth window">
        {this.linkedButton()}
        <div className="autocomplete_wrapper">
          <SymbolSelector
            width={120}
            selectedSymbol={this.state.symbol}
            onSelect={(symbol) => this.setNewSymbol(symbol)}
          />
          {this.state.symbol !== undefined && (
            <>
              {this.state.toggleEnabled && (
                <MarketDepthSelector
                  currentToggle={this.state.toggleAlignment}
                  onChange={(mdt) =>
                    this.changeMarketDepthType(this.state.symbol as string, mdt)
                  }
                />
              )}
              {this.state.priceInfo !== undefined && (
                <>
                  <MarketStatus status={state} />
                </>
              )}
            </>
          )}
        </div>
        <div className="header-container">
          {this.state.priceInfo !== undefined && (
            <MarketDepthHeaderInfo
              priceInfo={this.state.priceInfo}
              currency={this.state.currency}
            />
          )}
        </div>
        <div className="fill" style={{ overflow: "hidden" }}>
          <MUITable
            columns={MarketByOrderColumnsInfo}
            rows={this.state.rows.map((row) => {
              return { ...row, id: JSON.stringify(row) };
            })}
            cell={(column, row) => formatCell(column, row, maxVolume)}
            tableState={this.state.tableState}
            saveState={(state) => this.setState({ tableState: state })}
          />
        </div>
        <div>
          <MarketDepthBar orderbook={this.state.rows} />
        </div>
      </div>
    );
  }
}

const MarketDepthBar: React.FC<{ orderbook: LevelInfo[] }> = ({
  orderbook,
}) => {
  interface Level {
    a_volume?: number;
    b_volume?: number;
    [key: string]: any;
  }

  type VolumeType = "a_volume" | "b_volume";

  const calculateVolumeSum = (
    orderbook: Level[],
    volumeType: VolumeType
  ): number =>
    orderbook.reduce((sum, level) => sum + (level[volumeType] ?? 0), 0);

  const calculateVolumeCount = (
    orderbook: Level[],
    volumeType: VolumeType
  ): number =>
    orderbook.filter((level) => typeof level[volumeType] === "number").length;
  const bVolumeSum = calculateVolumeSum(orderbook, "b_volume");
  const aVolumeSum = calculateVolumeSum(orderbook, "a_volume");
  const bVolumePercentage = (bVolumeSum / (aVolumeSum + bVolumeSum)) * 100;
  const aVolumeCount = calculateVolumeCount(orderbook, "a_volume");
  const bVolumeCount = calculateVolumeCount(orderbook, "b_volume");
  if (isNaN(bVolumePercentage)) return null;
  return (
    <>
      <div style={{ marginTop: "10px" }} className="market-depth-bar">
        <div className="market-depth-bar__volume market-depth-bar__volume--b">
          {formatNumber(bVolumeSum)}
        </div>
        <div className="market-depth-bar__volume market-depth-bar__volume--a">
          {formatNumber(aVolumeSum)}
        </div>
        <div
          className="market-depth-bar__progress"
          style={{ width: `${bVolumePercentage}%` }}
        ></div>
      </div>
      <div className="market-depth-bar__counts">
        <div>{bVolumeCount}</div>
        <div>{TranslationManager.getTranslation().MarketDepth.total}</div>
        <div>{aVolumeCount}</div>
      </div>
    </>
  );
};
const centerAlign = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

function formatCell(column, row: any, maxVolume: number): React.JSX.Element {
  const value = row[column];
  if (column.includes("yield")) {
    return (
      <div style={centerAlign} className="fill">
        {value && formatPercent(value)}
      </div>
    );
  }
  if (["b_volume", "a_volume"].includes(column)) {
    const barStyle =
      column === "a_volume"
        ? { left: 0, background: "var(--red-200)" }
        : { right: 0, background: "var(--green-200)" };
    const fontStyle = { zIndex: 1 };
    fontStyle["color"] =
      column === "b_volume" ? "var(--green-600)" : "var(--red-600)";

    return (
      <div
        className="fill"
        style={{
          position: "relative",
          ...centerAlign,
        }}
      >
        <div
          style={{
            position: "absolute",
            ...barStyle,
            top: 0,
            width: `${Math.floor((100 * value) / maxVolume)}%`,
            height: "100%",
          }}
        ></div>
        <div style={fontStyle}>{formatNumber(value)}</div>
      </div>
    );
  } else {
    return (
      <div style={centerAlign} className="fill">
        {formatNumber(value)}
      </div>
    );
  }
}

function rowsFromMarketByOrder(marketByOrder: {
  ask: MBOOrder[];
  bid: MBOOrder[];
}): MarketByOrderInfo[] {
  const asks = sortOrders([...marketByOrder.ask], "asc");
  const bids = sortOrders([...marketByOrder.bid], "desc");

  const height = Math.max(asks.length, bids.length);
  const rows: MarketByOrderInfo[] = [];
  for (let i = 0; i < height; i++) {
    const ask = asks[i] || {},
      bid = bids[i] || {};
    const row: MarketByOrderInfo = createRow(ask, bid);
    rows.push(row);
  }
  return rows;
}

function sortOrders(orders: MBOOrder[], order: "asc" | "desc"): MBOOrder[] {
  return orders.sort((a: MBOOrder, b: MBOOrder) => {
    if (a.price > b.price) return order === "asc" ? 1 : -1;
    if (a.price < b.price) return order === "asc" ? -1 : 1;

    const aTime = a.time ?? "00:00:00:000";
    const bTime = b.time ?? "00:00:00:000";
    if (aTime !== bTime) return aTime.localeCompare(bTime);

    if (a.order_key > b.order_key) return 1;
    if (a.order_key < b.order_key) return -1;

    return 0;
  });
}

function createRow(ask: MBOOrder, bid: MBOOrder): MarketByOrderInfo {
  return {
    b_participant: bid.participant,
    b_trader: bid.participant,
    b_orders: bid.price ? 1 : undefined,
    b_volume: bid.volume,
    b_yield: bid.yield,
    b_dirty: bid.dirty,
    b_price: bid.price,
    b_order_key: bid.order_key,
    sortingTime: bid.time ?? ask.time,
    a_price: ask.price,
    a_dirty: ask.dirty,
    a_yield: ask.yield,
    a_volume: ask.volume,
    a_orders: ask.price ? 1 : undefined,
    a_trader: ask.participant,
    a_order_key: ask.order_key,
    a_participant: ask.participant,
  } as MarketByOrderInfo;
}

function rowsFromMarketDepth(marketDepth): LevelInfo[] {
  if (!marketDepth) return [];

  const { bids, asks } = marketDepth;
  const levels = new Set([...Object.keys(bids), ...Object.keys(asks)]);
  const rows: LevelInfo[] = [];

  levels.forEach((level) => {
    const bid = bids[level] || {};
    const ask = asks[level] || {};

    rows.push({
      b_trader: undefined,
      b_orders: bid.orders,
      b_volume: bid.volume,
      b_yield: undefined,
      b_dirty: undefined,
      b_price: bid.price,
      a_price: ask.price,
      a_dirty: undefined,
      a_yield: undefined,
      a_volume: ask.volume,
      a_orders: ask.orders,
      a_trader: undefined,
    });
  });

  return rows;
}
