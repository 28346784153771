import React from 'react';
import cx from 'classnames';
import { SymbolLinkableWindowComponent } from '../AbstractWindow';
import { Autocomplete, Box, TextField } from '@mui/material';
import { LMDInterface } from '../../../KodiInterface/LMD';


import CircularProgress from '@mui/material/CircularProgress';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppStateManager } from '../../../StateManager';
import { PriceInfo } from '../../../Types/Websocket';
import { HighChart } from './Highcharts';

type DataPoint = { date: Date; price: number; volume: number };

type SaveState = {
    symbol?: string;
    linked?: boolean;
};

type RunState = {
    symbols: string[];
    points: DataPoint[];
    isOpen: boolean;
    symbol?: string;
    linked?: boolean;
};

export class Chart extends SymbolLinkableWindowComponent<RunState, SaveState> {
    private initial: boolean = true;

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            symbols: [],
            points: [],
            isOpen: false,
        };
    }

    private clearSubscriptions() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }

    private async fetchHistoricalData(symbol: string): Promise<DataPoint[]> {
        const { points } = await LMDInterface.getHistoricalGraphData(symbol);
        return points.map(point => ({
            ...point,
            date: new Date(point.date)
        }));
    }

    private getTodayPoint(): Date {
        const today = new Date();
        today.setDate(today.getDate());
        today.setUTCHours(18, 0, 0, 0);
        return today;
    }

    private updateExistingPoint(points: DataPoint[], priceInfo: PriceInfo): DataPoint[] {
        const lastIndex = points.length - 1;
        const updatedPoints = [...points];
        updatedPoints[lastIndex] = {
            ...points[lastIndex],
            price: priceInfo?.last_price || points[lastIndex - 1].price,
            volume: priceInfo?.intraday_accumulated_volume || 0
        };
        return updatedPoints;
    }

    private createNewPoint(points: DataPoint[], priceInfo: PriceInfo, date: Date): DataPoint[] {
        return [
            ...points,
            {
                date,
                price: priceInfo?.last_price || points[points.length - 1].price,
                volume: priceInfo?.intraday_accumulated_volume || 0
            }
        ];
    }

    private handlePriceUpdate(symbol: string, priceInfo: PriceInfo | undefined, initialPoints: DataPoint[]) {
        if (!priceInfo || priceInfo.symbol !== symbol) return;

        if (this.initial) {
            const today = this.getTodayPoint();
            const lastPoint = initialPoints[initialPoints.length - 1];
            const updatedPoints = today.getTime() === lastPoint.date.getTime()
                ? this.updateExistingPoint(initialPoints, priceInfo)
                : this.createNewPoint(initialPoints, priceInfo, today);

            this.setState({ points: updatedPoints });
            this.initial = false;
            return;
        }

        const currentPoints = [...this.state.points];
        const lastPoint = currentPoints[currentPoints.length - 1];
        if (currentPoints.length > 0 &&
            (lastPoint.price !== priceInfo.last_price ||
                lastPoint.volume !== priceInfo.intraday_accumulated_volume)) {
            this.setState({
                points: this.updateExistingPoint(currentPoints, priceInfo)
            });
        }
    }

    public async setNewSymbol(symbol: string) {
        this.setState({ symbol, points: [] });
        this.clearSubscriptions();
        this.initial = true;

        const points = await this.fetchHistoricalData(symbol);

        const subscription = AppStateManager.MF.getHandler('price_info')
            .subscribe(symbol, (priceInfo: PriceInfo | undefined) =>
                this.handlePriceUpdate(symbol, priceInfo, points)
            );

        this.subscriptions.push(subscription);
    }

    componentDidMount(): void {
        LMDInterface.getAllSymbols().then(symbols => this.setState({ symbols }));
        if (this.state.symbol !== undefined) {
            this.setNewSymbol(this.state.symbol);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot?: any): void {
        this.saveState({ symbol: this.state.symbol, linked: this.state.linked });
    }

    render(): React.ReactNode {
        return (
            <div className='window' role="region" aria-label="Chart window">
                <div className='fill' style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Autocomplete
                        autoHighlight
                        popupIcon={<FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '10px' }} />}
                        options={this.state.symbols}
                        sx={{
                            padding: 0,
                            '& input': {
                                maxWidth: '200px',
                                height: 10
                            },
                        }}
                        onOpen={() => this.setState({ isOpen: true })}
                        onClose={() => this.setState({ isOpen: false })}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{ width: 120 }}
                                className={cx('autocomplete-custom', { 'isOpen': this.state.isOpen })}
                            />
                        )}
                        renderOption={(props, symbol) => (
                            <Box
                                component="li"
                                {...props}
                                sx={{
                                    color: 'var(--dark-900, #232530)',
                                    fontSize: '11px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                {symbol}
                            </Box>
                        )}
                        disableClearable
                        value={this.state.symbol || null as any}
                        size='small'
                        onChange={(event: any, newValue: string) => this.setNewSymbol(newValue)}
                    />
                    <div>{this.linkedButton()}</div>
                </div>
                <div className='fill' style={{ overflow: 'hidden' }} role="figure" aria-label="Stock price chart">
                    {this.state.symbol && this.state.points.length === 0 ?
                        <div className='center-container fill' role="status" aria-label="Loading chart">
                            <CircularProgress />
                        </div>
                        : <HighChart points={this.state.points} />
                    }
                </div>
            </div>
        );
    }
}