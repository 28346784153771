// hooks/useOrderValidation.ts
import * as Yup from 'yup';
import { Side } from '../../../Types/private-data/side';
import { Order } from '../../../Types/private-data/orderType';
import { TimeInForce } from '../../../Types/private-data/timeInForce';
import { AppStateManager } from '../../../StateManager';

// Utility functions for number validation
const parseNumberValue = (value: string): number => {
    return parseFloat(value.replace(/,/g, '.').replace(/\./g, ''));
};

const isValidNumber = (value: string): boolean => {
    const number = parseNumberValue(value);
    return !isNaN(number);
};

const isGreaterThanZero = (value: string): boolean => {
    const number = parseNumberValue(value);
    return number > 0;
};

// Custom validation tests
const numberValidation = {
    isNumber: Yup.string().test(
        'is-number',
        'Must be a valid number',
        (value) => !value || isValidNumber(value)
    ),
    greaterThanZero: Yup.string().test(
        'is-greater-than-zero',
        'Must be greater than 0',
        (value) => !value || (isValidNumber(value) && isGreaterThanZero(value))
    )
};

export const useOrderValidation = () => {
    const createPriceValidation = (isRequired: boolean = true) => {
        let schema = numberValidation.isNumber.concat(numberValidation.greaterThanZero);
        return isRequired ? schema.required('Required') : schema;
    };

    const createQuantityValidation = () => {
        return numberValidation.isNumber
            .concat(numberValidation.greaterThanZero)
            .required('Required');
    };

    const validationSchema = Yup.object().shape({
        // Side validation
        side: Yup.string()
            .oneOf(Object.values(Side), 'Invalid side')
            .required('Required'),

        // Quantity validation
        quantity: createQuantityValidation(),

        // Symbol validation
        symbol: Yup.string()
            .required('Required'),

        // Price validation - conditional based on order type
        price: Yup.string()
            .when('order_type', {
                is: (order_type: string) => order_type !== 'market',
                then: (schema) => createPriceValidation(true),
                otherwise: (schema) => createPriceValidation(false)
            }),

        // Order type validation
        order_type: Yup.string()
            .oneOf(Object.values(Order), 'Invalid order type')
            .required('Required'),

        // Visible quantity validation - conditional based on hidden flag
        visible_quantity: Yup.string()
            .when('hidden', {
                is: true,
                then: (schema) => schema
                    .test('is-greater-than-zero',
                        'Visible quantity must be greater than 0',
                        (value) => !value || isGreaterThanZero(value))
                    .test('is-less-than-quantity',
                        'Visible quantity cannot be higher than quantity',
                        function (value) {
                            const { quantity } = this.parent;
                            if (!value || !quantity) return true;
                            return parseNumberValue(value) <= parseNumberValue(quantity);
                        })
            }),

        // Hidden flag validation
        hidden: Yup.boolean(),

        // Time in force validation
        time_in_force: Yup.string()
            .oneOf(Object.values(TimeInForce), 'Invalid time in force')
            .required('Required'),

        // Min quantity validation
        min_quantity: Yup.string(),

        // Broker validation
        broker: Yup.string()
            .required('Required'),

        // Portfolio ID validation - conditional based on retail access
        portfolio_id: Yup.string()
            .when('broker', {
                is: () => AppStateManager.hasAccessToRetail.getValue(),
                then: (schema) => schema
                    .required('Required')
                    .test('not-empty',
                        'Portfolio ID cannot be an empty string',
                        value => value !== '')
            })
    });

    return {
        validationSchema,
        validateNumber: isValidNumber,
        validateGreaterThanZero: isGreaterThanZero,
        parseNumberValue
    };
};