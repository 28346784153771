import { useEffect, useRef } from "react";
import {
    INewsLmdNewsItem,
    NewsRefreshConfig,
    NewsState,
    NewsStateSetters,
    NotificationSetters,
    SearchInputToSourceFilterToResultsDoubleMap,
    SourceFilterToResultsMap
} from "../../../Types/NewsType";
import { combineOldAndNewNews } from "../../../Utils/NewsUtils";
import { updateNotifications } from "./Services/NotificationService";
import { NewsRefreshService } from "./Services/NewsRefreshService";

interface NewsRefreshParams {
    newsState: NewsState;
    defaultNewsToAddTo: INewsLmdNewsItem[] | null;
    newNewsItems: INewsLmdNewsItem[];
    currentPageIndex: number;
    usingDefaultNews: boolean;
    stateSetters: NewsStateSetters;
}

const handleDefaultNewsUpdate = ({
    defaultNewsToAddTo,
    newNewsItems,
    currentPageIndex,
    usingDefaultNews,
    stateSetters
}: NewsRefreshParams): INewsLmdNewsItem[] => {
    const newsToAddTo = defaultNewsToAddTo || [];
    const { combinedNews, addedNews } = combineOldAndNewNews(newNewsItems, newsToAddTo, null);

    if (usingDefaultNews) {
        if (currentPageIndex === 0) {
            stateSetters.setDefaultNewsItems(combinedNews);
        } else {
            stateSetters.setDefaultNewsItemsWaitingQueue(combinedNews);
        }
    } else {
        stateSetters.setDefaultNewsItems(combinedNews);
    }

    return addedNews;
};

interface SourceFilterUpdateParams {
    newNewsItems: INewsLmdNewsItem[];
    sourceFilterToResultsMap: SourceFilterToResultsMap;
    category: string;
    sourceFilters: any;
    currentPageIndex: number;
    usingSearchWord: boolean;
    searchResultsDoubleMap: SearchInputToSourceFilterToResultsDoubleMap;
    stateSetters: NewsStateSetters;
}

const handleSourceFilterUpdate = ({
    newNewsItems,
    sourceFilterToResultsMap,
    category,
    sourceFilters,
    currentPageIndex,
    usingSearchWord,
    searchResultsDoubleMap,
    stateSetters
}: SourceFilterUpdateParams): INewsLmdNewsItem[] => {
    const { newSourceFilterToResultsMap, newsThatGetNotification } =
        NewsRefreshService.processSourceFilters(
            newNewsItems,
            sourceFilterToResultsMap,
            category,
            sourceFilters,
            currentPageIndex,
            usingSearchWord
        );

    const updatedSearchResultsDoubleMap: SearchInputToSourceFilterToResultsDoubleMap = {
        ...searchResultsDoubleMap,
        '': newSourceFilterToResultsMap
    };

    stateSetters.setSearchResultsDoubleMap(updatedSearchResultsDoubleMap);
    return newsThatGetNotification;
};

const isValidInitialNewsItem = (newsItems: INewsLmdNewsItem[] | null): newsItems is INewsLmdNewsItem[] => {
    if (!newsItems?.length) return false;
    const firstItem = newsItems[0];
    return Boolean(firstItem?.id);
};

interface FetchNewsParams {
    fetchNews: (count: number) => Promise<INewsLmdNewsItem[]>;
    initialItem: INewsLmdNewsItem;
    config: NewsRefreshConfig;
}

interface ProcessNewsParams {
    newsState: NewsState;
    newNewsItems: INewsLmdNewsItem[];
    stateSetters: NewsStateSetters;
    notificationSetters: NotificationSetters;
    config: NewsRefreshConfig;
}

export const useNewsRefresh = ({
    refreshRateMs,
    newsState,
    stateSetters,
    notificationSetters,
    config,
    fetchNews
}: {
    refreshRateMs: number;
    newsState: NewsState;
    stateSetters: NewsStateSetters;
    notificationSetters: NotificationSetters;
    config: NewsRefreshConfig;
    fetchNews: (count: number) => Promise<INewsLmdNewsItem[]>;
}) => {
    const timeoutRef = useRef<NodeJS.Timeout>();

    // Function to fetch new news items
    const fetchLatestNews = async ({ fetchNews, initialItem, config }: FetchNewsParams): Promise<INewsLmdNewsItem[]> => {
        return NewsRefreshService.fetchNewNewsRecursively(
            fetchNews,
            initialItem,
            config.initialFetchCount,
            config.maxRecursionAttempts
        );
    };

    // Function to determine which news list to update
    const getNewsToUpdate = (
        currentPageIndex: number,
        usingDefaultNews: boolean,
        defaultNewsItems: INewsLmdNewsItem[],
        waitingQueue: INewsLmdNewsItem[] | null
    ): INewsLmdNewsItem[] => {
        if (usingDefaultNews && currentPageIndex !== 0) {
            return waitingQueue ?? [];
        }
        return defaultNewsItems;
    };

    // Function to process source filter updates and notifications
    const processNewsUpdates = async ({
        newsState,
        newNewsItems,
        stateSetters,
        notificationSetters,
        config
    }: ProcessNewsParams): Promise<void> => {
        const {
            defaultNewsItems,
            defaultNewsItemsWaitingQueue,
            currentPageIndex,
            usingDefaultNews,
            category,
            sourceFilters,
            usingSearchWord,
            searchResultsDoubleMap
        } = newsState;

        // Early return if defaultNewsItems is null
        if (!defaultNewsItems) {
            return;
        }

        // Update default news
        const defaultNewsToUpdate = getNewsToUpdate(
            currentPageIndex,
            usingDefaultNews,
            defaultNewsItems,
            defaultNewsItemsWaitingQueue
        );

        let notifications = handleDefaultNewsUpdate({
            newsState,
            defaultNewsToAddTo: defaultNewsToUpdate,
            newNewsItems,
            currentPageIndex,
            usingDefaultNews,
            stateSetters
        });

        // Update source filters if necessary
        const sourceFilterToResultsMap = searchResultsDoubleMap[category];
        if (sourceFilterToResultsMap) {
            const filterNotifications = handleSourceFilterUpdate({
                newNewsItems,
                sourceFilterToResultsMap,
                category,
                sourceFilters,
                currentPageIndex,
                usingSearchWord,
                searchResultsDoubleMap,
                stateSetters
            });
            notifications = [...notifications, ...filterNotifications];
        }

        // Update notifications if needed
        if (notifications.length > 0) {
            updateNotifications(notifications, notificationSetters, config);
        }
    };

    useEffect(() => {
        if (!refreshRateMs) return;

        const refresh = async () => {
            const { defaultNewsItems } = newsState;
            if (!isValidInitialNewsItem(defaultNewsItems)) return;

            try {
                const newNewsItems = await fetchLatestNews({
                    fetchNews,
                    initialItem: defaultNewsItems[0],
                    config
                });

                await processNewsUpdates({
                    newsState,
                    newNewsItems,
                    stateSetters,
                    notificationSetters,
                    config
                });
            } catch (error) {
                stateSetters.setError(
                    error instanceof Error ? error : new Error('Unknown error during refresh')
                );
            }
        };

        const intervalId = setInterval(refresh, refreshRateMs);

        return () => {
            clearInterval(intervalId);
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [refreshRateMs, newsState, stateSetters, notificationSetters, config, fetchNews]);
};