import { ColumnInfo } from "../../../Types/MUITable";

export const BondPaymentInfoColumns: ColumnInfo[] = [
    { name: "date", defaultHidden: false, dataType: 'string', alignment: 'left' },
    { name: "principal", defaultHidden: false, dataType: 'number', alignment: 'right' },
    { name: "year_fract", defaultHidden: false, dataType: 'number', alignment: 'right' },
    { name: "payment", defaultHidden: false, dataType: 'number', alignment: 'right' },
    { name: "interests", defaultHidden: false, dataType: 'number', alignment: 'right' },
    { name: "total", defaultHidden: false, dataType: 'number', alignment: 'right' },
    { name: "present_value", defaultHidden: false, dataType: 'number', alignment: 'right' },
    { name: "index_adjusted", defaultHidden: false, dataType: 'number', alignment: 'right' },
    { name: "interest_date", defaultHidden: true, dataType: 'string', alignment: 'left' },
];