import { createTheme } from "@mui/material/styles";

export const AppTheme = createTheme({
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
  components: {
    MuiTouchRipple: {
      styleOverrides: {
        child: {
          backgroundColor: "var(--blue-600)",
        },
        root: {
          "&:hover": {
            background: "var(--table-header-svgIcon-hover)",
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: { backgroundColor: "var(--blue-100)" },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          //default styles
          color: "var(--Blue-900, #1D3097)",
          fontFamily: "Roboto",
          fontSize: "11px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
          backgroundColor: "var(--blue-100)",
          border: "none",
          r: 6,
          borderRadius: "6px",
          height: "20px",
          //disabled
          "&.Mui-disabled": {
            color: "var(--blue-200)",
            border: "none",
          },
          "&.Mui-selected": {
            backgroundColor: "var(--blue-200)",
            color: "var(--blue-900)",
            r: 6,
            borderRadius: "6px !important",
            fontWeight: "500",
            "&:hover": {
              backgroundColor: "var(--blue-200)",
              borderRadius: "6px !important",
            },
          },
          "&:hover": {
            backgroundColor: "var(--blue-200)",
            borderRadius: "6px !important",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "24px",
          background: "var(--White-900, #FFF)",
          /* active search shadaw */
          boxShadow: "0px 2px 30px 0px rgba(34, 53, 223, 0.50)",
          maxWidth: "unset",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'var(--dark-600, #65666E)',
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '13px',
          background: 'var(--white)',
          WebkitTransform: 'translate(14px, 16px) scale(1)',
          MozTransform: 'translate(14px, 16px) scale(1)',
          msTransform: 'translate(14px, 16px) scale(1)',
          transform: 'translate(14px, 9px) scale(1)',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: "var(--blue-600)",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // height: "20px",
          borderRadius: "6px",
          background: "var(--White, #FFF)",
          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.04)",
          // paddingRight: "8px",
          // lineHeight: "1em",
          // minHeight: "1em",
          input: {
            padding: "4px 8px",
          },
          "&.Mui-focused": {
            ".MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px",
              borderColor: "var(--blue-600)",
            },
          },
          //hover
          "&.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--blue-600)",
            boxShadow: "var(--box-shadow-active)",
          },

          "&.MuiInputBase-root:hover [data-testid='ArrowDropDownIcon']": {
            color: "var(--blue-600)",
          },
          "&.MuiInputBase-sizeSmall": {
            height: '20px',
          },
          '&.Mui-disabled': {
            backgroundColor: 'var(--dark-050)',
            boxShadow: 'none',
            "&:hover": {
              fieldset: {
                "&.MuiOutlinedInput-notchedOutline": {
                  borderColor: "var(--dark-300)",
                  boxShadow: "none",

                }
              }
            }
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          "&.MuiIconButton-root:hover": {
            backgroundColor: "var(--blue-200)",
          },
          "&.MuiMenuItem-root:hover": {
            backgroundColor: "var(--blue-100)",
          },
          "&.MuiMenuItem-root:hover .MuiListItemText-root span": {
            color: "var(--blue-600)",
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: "var(--dark-300) !important",
          },
          color: "var(--blue-600)",
          // Triple dots in header
          '&[data-testid="TripleDotsVerticalIcon"]': {
            fill: "var(--table-header-svgIcon)",
            "&:hover": {
              backgroundfill: "var(--table-header-svgIcon-hover)",
            },
          },
          "&[data-testid='ArrowDropDownIcon']": {
            color: "var(--dark-300)",
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          //header menu rightClick menu. Disable min width.
          minWidth: "0 !important",
          overflowY: "auto",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          "&.MuiListItemText-primary": {
            color: "var(--dark-900, #232530)",
            fontFamily: "Roboto",
            fontSize: "11px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
          },
        },
        h1: {
          fontSize: "2rem",
          color: "blue",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        endAdornment: {
          top: 'unset',
          right: 'unset',
          transform: 'unset',
        },
        root: {
          "& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall": {
            padding: "4px 0px 4px 15px",
            fontFamily: "Roboto",
            fontSize: "11px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
          },
          "& .MuiInputBase-input.MuiOutlinedInput-input": {
            color: "var(--dark-900)",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
          },
          "& .MuiAutocomplete-clearIndicator": {
            padding: "1px",
            marginRight: "1px",
            ".MuiSvgIcon-root": {
              color: "var(--dark-900)",
              height: "12px",
              width: "12px",
              "&:hover": {
                color: "var(--blue-600)",
              },
            },
          },
          "& .MuiAutocomplete-popupIndicator": {
            color: "var(--dark-900)",
            marginRight: "2px",
            background: 'var(--white)',
            "&:hover": {
              color: "var(--blue-600)",
            },
            "&.Mui-disabled": {
              background: 'transparent'
            },
          },
          "& .MuiAutocomplete-input": {
            height: "15px",
          },

          "& .MuiAutocomplete-tag": {
            borderRadius: "6px",
          },
          //focused
          "&.Mui-focused": {
            "& .MuiAutocomplete-popupIndicator": {
              color: "var(--blue-600)",
            },
          },
          ".MuiOutlinedInput-root": {
            cursor: "pointer",
            padding: "0px 12px",
          },
          ".MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
            paddingTop: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            paddingRight: "0px",
            borderRadius: "6px",
            height: "20px",

            //font-style
            color: "var(--dark-900)",
            fontSize: "11px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            "&.MuiInputBase-adornedEnd": {
              paddingRight: "40px",
            }
          },
          //medium size
          "&.md": {
            "& .MuiAutocomplete-input": {
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '23px',

              ".MuiOutlinedInput-root": {
                padding: 0,
              }
            },
            ".MuiAutocomplete-endAdornment": {
              top: "7.5px",
            },
            "&.md": {
              ".MuiAutocomplete-endAdornment": {
                top: "4px",
              },
            },
          },
        },
        inputRoot: {
          padding: "0px 12px", // Change the padding as needed

          //focused input
          "&.Mui-focused .MuiInputBase-input": {
            color: "var(--dark-600)",
          },
        },
        //dropdown
        option: {
          '&.MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            backgroundColor: "var(--blue-100)",
          },
          //focused
          "&.MuiAutocomplete-option.Mui-focused": {
            color: "var(--blue-600)",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
            background: "var(--blue-100)",
          },
          //selected
          '&.MuiAutocomplete-option[aria-selected="true"]': {
            color: "var(--blue-600)",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
            background: "var(--blue-100)",
          },
          //hover
          "&.MuiAutocomplete-option:hover:not([aria-selected='true'])": {
            color: "var(--blue-600)",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            background: "var(--blue-100)",
          },
        },
        listbox: {
          "&::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar-track": {
            background: "var(--scrollbar-track)",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "var(--scrollbar-thumb)",
            border: "2px solid var(--scrollbar-thumb)",
          },
        },
        paper: {
          boxShadow: "var(--box-shadow-active)",
          border: "1px solid var(--blue-600)",
          borderTop: "none",
          borderRadius: "0px 0px 6px 6px",

          "&::webkit-scrollbar": {
            width: "10px",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        //menu in watchList
        elevation2: {
          filter: "drop-shadow(0px 2px 30px rgba(34, 53, 223, 0.50))",
        },
        elevation5: {
          borderRadius: "0 0 6px 6px",
          boxShadow: "var(--box-shadow-active)",
          border: "1px solid var(--blue-600)",
          borderTop: "none",
        },

        //default is elevation8
        elevation8: {
          borderRadius: "6px 6px",
        },
        root: {
          //header menu rightClick menu
          backgroundColor: "var(--white)",
          border: "none",
          boxShadow: "var(--box-shadow-menu-dropdown)",
          borderRadius: "6px",
          "&.MuiDialog-paper": {
            overflowY: 'visible',
          }
        },
      },
    },
    MuiDivider: { styleOverrides: { root: { display: "none" } } },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "3px 20px 3px 10px",
          color: "var(--dark-900, #232530)",
          fontFamily: "Roboto",
          fontSize: "11px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
          "&:hover": {
            color: "var(--blue-600)",
            // fontWeight: "700",
            backgroundColor: "var(--blue-100)",
          },
          "&:active": {
            color: "var(--blue-600)",
            fontWeight: "700",
            backgroundColor: "var(--blue-100)",
          },
          "&.Mui-selected": {
            color: "var(--blue-600)",
            fontWeight: "700",
            backgroundColor: "var(--blue-100)",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          padding: "0px",
        },
        select: {
          "&.MuiSelect-select": {
            padding: "5px 24px 5px 12px"
          },
        },
        icon: {
          color: "var(--dark-900)",
          "&:hover, &.MuiSelect-iconOpen": {
            color: "var(--blue-600)",
          },
          "&:hover": {
            backgroundColor: "var(--blue-200)",
          }
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          ".MuiInputLabel-root": {
            color: "var(--dark-600)",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "13px",
            background: "var(--white)",
            padding: "0 3px",
            WebkitTransform: "translate(14px, 8px) scale(1)",
            MozTransform: "translate(14px, 8px) scale(1)",
            msTransform: "translate(14px, 8px) scale(1)",
            transform: "translate(14px, 8px) scale(1)",
            "&[data-shrink=true]": {
              WebkitTransform: "translate(14px, -5px) scale(0.75)",
              MozTransform: "translate(14px, -5px) scale(0.75)",
              msTransform: "translate(14px, -5px) scale(0.75)",
              transform: "translate(14px, -5px) scale(0.75)",
            },
            "&.Mui-focused": {
              color: "var(--blue-600)",
            }

          },
        }
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          padding: "0px",

          //hiding asterisk for required fields
          "& .MuiFormLabel-asterisk": {
            color: "inherit",
            display: "none",
          },
        },
      },
    },
  },
});
