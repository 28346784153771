import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TranslationManager } from "../../../Translation/Translation";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";

export const OrderFormHeader: React.FC<{ modifyOrder: any | null; onClose: () => void }> = ({
    modifyOrder,
    onClose
}) => (
    <div className="jc-sb">
        <h1>
            {modifyOrder !== null
                ? TranslationManager.getTranslation().Titles.ModifyOrderTitle
                : TranslationManager.getTranslation().Titles.NewOrderTitle}
        </h1>
        <FontAwesomeIcon
            className="modalCloseButton"
            onClick={onClose}
            icon={faXmark}
        />
    </div>
);