import { Component } from "react";
import { Subject, Subscription } from "rxjs";
import { ListInfo } from "../../Types/LMDTypes";

import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";

type Input<SaveState> = {
  state: SaveState;
  GLSaveState: (state: SaveState) => void;
  containerWidth?: number;
  containerHeight?: number;
};
export abstract class WindowComponent<
  RunState,
  SaveState extends { linked: boolean }
> extends Component<Input<SaveState>, RunState & SaveState> {
  subscriptions: Subscription[];
  linkedButton: () => React.JSX.Element;
  constructor(props: Input<SaveState>) {
    super(props);
    this.state = props.state as RunState & SaveState;
    this.subscriptions = [];
    this.linkedButton = () => (
      <div
        style={{ position: "absolute", right: 5, top: 0, cursor: "pointer" }}
      >
        {!this.state.linked && (
          <LinkOffIcon
            style={{ fill: "var(--window-not-linked)" }}
            onClick={this.link.bind(this)}
          />
        )}
        {this.state.linked && (
          <LinkIcon
            style={{ fill: "var(--window-linked)" }}
            onClick={this.unlink.bind(this)}
          />
        )}
      </div>
    );
  }

  abstract link(): void;
  abstract unlink(): void;

  saveState(state: SaveState) {
    this.props.GLSaveState(state);
  }

  componentWillUnmount() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
export abstract class SymbolLinkableWindowComponent<RunState, SaveState> extends WindowComponent<
  RunState,
  SaveState & { symbol: string | undefined; linked: boolean }
> {
  protected linkSubscriptions: Subscription[] = [];

  constructor(props: Input<SaveState & { symbol: string | undefined; linked: boolean }>) {
    super(props);
    if (this.props.state.linked) {
      this.subscribeGLWM();
    }
  }

  abstract setNewSymbol(symbol: Symbol): void;

  protected subscribeGLWM() {
    const GLWM = getGlobalLinkedWindowManager();
    const subscription = GLWM.listenerSubscribe((symbol) =>
      this.setNewSymbol(symbol)
    );
    this.linkSubscriptions.push(subscription);
  }

  // Implementation of abstract methods from WindowComponent
  link(): void {
    this.subscribeGLWM();
    this.setState({ linked: true as any });
  }

  unlink(): void {
    this.linkSubscriptions.forEach(subscription => subscription.unsubscribe());
    this.linkSubscriptions = [];
    this.setState({ linked: false as any });
  }

  // Override componentWillUnmount to handle both subscription types
  componentWillUnmount(): void {
    super.componentWillUnmount(); // Handle base subscriptions
    this.linkSubscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
export abstract class SymbolListWindowComponent<
  RunState,
  SaveState
> extends WindowComponent<
  RunState,
  SaveState & { list: ListInfo | undefined; linked: boolean }
> {

  selectedSymbol(symbol: Symbol): void {
    if (this.state.linked) {
      const GLWM = getGlobalLinkedWindowManager();
      GLWM.select(symbol);
    }
  }

  link() {
    this.setState({ linked: true as any });
  }
  unlink() {
    this.setState({ linked: false as any });
  }
}

// Might but into its own file later
type Symbol = string;
class LinkedWindowManager {
  private subject: Subject<Symbol>;
  constructor() {
    this.subject = new Subject<Symbol>();
  }

  listenerSubscribe(callback: (symbol: Symbol) => void): Subscription {
    return this.subject.subscribe(callback);
  }

  select(symbol: Symbol) {
    this.subject.next(symbol);
  }
}
const GlobalLinkedWindowManager = new LinkedWindowManager();
export function getGlobalLinkedWindowManager(): LinkedWindowManager {
  return GlobalLinkedWindowManager;
}
