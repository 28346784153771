import { FormControl, InputLabel } from "@mui/material";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { AppStateManager } from "../../StateManager";
import { TranslationManager } from "../../Translation/Translation";
import Dropdown from "../UI-Elements/DropDownWrapper";
import ColorSelect from "../UI-Elements/FormikInputs/SideSelector";
import NumberInput from "../UI-Elements/FormikInputs/NumberInput";
import SymbolSelectorWrapper from "../UI-Elements/FormikInputs/SymbolSelectorWrapper";

interface OrderFormProps {
  modifyOrder: any | null;
  setFieldValue: (field: string, value: any) => void;
  values: OrderFormValues;
}
export interface OrderFormValues {
  side: string;
  symbol: string | null;
  quantity: string | null;
  price?: string;
  order_type: string;
  visible_quantity?: string;
  hidden: boolean;
  time_in_force: string;
  min_quantity?: number;
  broker: string;
  portfolio_id?: string;
  account_id?: string;
}

const SymbolField: React.FC<OrderFormProps> = ({ modifyOrder, values, setFieldValue }) => (
  <div className="modal_item">
    <Field
      disabled={modifyOrder !== null}
      as={SymbolSelectorWrapper}
      size="md"
      placeholder={TranslationManager.getTranslation().New_Order_Modal.Labels.Autocomplete_symbol}
      name="symbol"
      disableClearable={false}
      selectedSymbol={values.symbol}
      onSelect={(symbol) => setFieldValue("symbol", symbol)}
      autoFocus
    />
  </div>
);

const QuantityPriceFields: React.FC<{
  values: OrderFormValues;
}> = ({ values }) => (
  <>
    <div className="modal_item">
      <Field
        inputSize="sm"
        as={NumberInput}
        name="quantity"
        type="number"
        decimalScale={0}
        label={TranslationManager.getTranslation().New_Order_Modal.Labels.Quantity}
      />
    </div>
    <div className="modal_item">
      <Field
        inputSize="sm"
        as={NumberInput}
        name="price"
        disabled={values.order_type === "market"}
        type="number"
        withtickerformatting={true}
        label={TranslationManager.getTranslation().New_Order_Modal.Labels.Price}
      />
    </div>
  </>
);

export const FirstRow: React.FC<OrderFormProps> = ({ modifyOrder, values, setFieldValue }) => (
  <>
    <div className="modal_item">
      <FormControl fullWidth>
        <InputLabel id="side-label">
          {TranslationManager.getTranslation().New_Order_Modal.Labels.Buy_sell}
        </InputLabel>
        <Field
          disabled={modifyOrder !== null}
          as={ColorSelect}
          name="side"
          labelId="side-label"
        />
      </FormControl>
      <ErrorMessage name="side" component="div" />
    </div>
    <SymbolField
      modifyOrder={modifyOrder}
      values={values}
      setFieldValue={setFieldValue}
    />
    <QuantityPriceFields
      values={values}
    />
  </>
);

export const SecondRow: React.FC<OrderFormProps> = ({ modifyOrder, setFieldValue, values }) => {
  return (
    <>
      <div className="modal_item">
        <FormControl fullWidth>
          <InputLabel shrink id="order_type-label">
            {TranslationManager.getTranslation().New_Order_Modal.Labels.Order_type}
          </InputLabel>
          <Field
            disabled={modifyOrder !== null}
            fullWidth
            size="large"
            initialValue={values.order_type}
            menuItems={[
              { value: "limit", title: "Limit" },
              { value: "market", title: "Market" },
            ]}
            as={Dropdown}
            name="order_type"
            labelId="order_type-label"
            onChange={(value) => {
              value === "market" && setFieldValue("price", undefined);
              setFieldValue('order_type', value)
            }}
          />
        </FormControl>
        <ErrorMessage className="formik-error-message" name="order_type" component="div" />
      </div>
      <div className="modal_item">
        {/* Visbile QTY */}
        <Field
          inputSize="sm"
          as={NumberInput}
          name="visible_quantity"
          disabled={!values.hidden || modifyOrder !== null}
          type="number"
          decimalScale={0}
          label={TranslationManager.getTranslation().New_Order_Modal.Labels.Visible_quantity}
        />
      </div>
      <div className="hidden">
        {/* HIDDEN */}
        <label><Field disabled={modifyOrder !== null} type="checkbox" name="hidden" />{TranslationManager.getTranslation().New_Order_Modal.Labels.Hidden}</label>
      </div>
    </>
  )
};

export const LastRow: React.FC<{
  modifyOrder: any | null;
  brokers: Array<{ value: string; title: string }>;
  portfolios?: Array<any>;
  accounts?: Array<any>;
  setSelectedPortfolio: (value: any) => void;
}> = ({ modifyOrder, brokers, portfolios, accounts, setSelectedPortfolio }) => {
  const { setFieldValue } = useFormikContext<OrderFormValues>();
  return (
    <>
      <div className="modal_item">
        <FormControl fullWidth>
          <InputLabel shrink id="time_in_force-label">{TranslationManager.getTranslation().New_Order_Modal.Labels.Time_in_force}</InputLabel>
          <Field
            fullWidth
            size="large"
            menuItems={[
              { value: "day", title: "Day" },
              // { value: "fill_or_kill", title: "FOK" },
              { value: "immediate_or_cancel", title: "IOC" },
              // { value: "good_till_cancel", title: "GTC" },
              // { value: "good_till_date", title: "GTT" },
            ]}
            as={Dropdown}
            name="time_in_force"
            disabled={true}
            labelId="time_in_force-label"
            onChange={(value) => { setFieldValue("time_in_force", value) }}
          />
        </FormControl>
        <ErrorMessage className="formik-error-message" name="time_in_force" component="div" />
      </div>
      <div className="modal_item">
        {/* Min QTY */}
        <Field
          inputSize="sm"
          as={NumberInput}
          name="min_quantity"
          type="number"
          disabled
          decimalScale={0}
          label={TranslationManager.getTranslation().New_Order_Modal.Labels.Min_quantity}
        />
      </div>
      <div className="modal_item">
        {/* // connect to available brokers  */}
        <FormControl fullWidth>
          <InputLabel shrink id="broker-label">{TranslationManager.getTranslation().New_Order_Modal.Labels.Broker}</InputLabel>
          <Field
            size="large"
            fullWidth
            disabled={modifyOrder !== null || AppStateManager.hasAccessToRetail.getValue()}
            menuItems={brokers}
            as={Dropdown}
            name="broker"
            labelId="broker-label"
          />
        </FormControl>
        <ErrorMessage className="formik-error-message" name="broker" component="div" />
      </div>
      {AppStateManager.hasAccessToRetail.getValue()
        && <RetailFields
          modifyOrder={modifyOrder}
          portfolios={portfolios}
          accounts={accounts}
          setSelectedPortfolio={setSelectedPortfolio} />
      }
    </>
  )
};

export const RetailFields: React.FC<{
  modifyOrder: any | null;
  portfolios: Array<any> | undefined;
  accounts: Array<any> | undefined;
  setSelectedPortfolio: (value: any) => void;
}> = ({ modifyOrder, portfolios, accounts, setSelectedPortfolio }) => (
  <>
    <div className="modal_item">
      <FormControl fullWidth>
        <InputLabel id="portfolio-label">
          {TranslationManager.getTranslation().New_Order_Modal.Labels.Portfolio}
        </InputLabel>
        <Field
          fullWidth
          menuItems={portfolios}
          as={Dropdown}
          onChange={setSelectedPortfolio}
          disabled={modifyOrder !== null}
          name="portfolio_id"
          labelId="portfolio-label"
        />
      </FormControl>
      <ErrorMessage className="formik-error-message" name="portfolio_id" component="div" />
    </div>
    <div className="modal_item">
      <FormControl fullWidth>
        <InputLabel id="account-label">
          {TranslationManager.getTranslation().New_Order_Modal.Labels.Account}
        </InputLabel>
        <Field
          fullWidth
          menuItems={accounts}
          as={Dropdown}
          disabled={modifyOrder !== null}
          name="account_id"
          labelId="account-label"
        />
      </FormControl>
      <ErrorMessage className="formik-error-message" name="account_id" component="div" />
    </div>
  </>
);