import { useRef, useEffect } from "react";
import {
    INewsLmdNewsItem,
    SearchCallbacks,
    SearchConfig,
    SearchInputToSourceFilterToResultsDoubleMap,
    SearchResultsInfo,
    SearchState
} from "../../../../Types/NewsType";
import { updateDoubleMapValue } from "../../../../Utils/Common";
import { getSourceStringFromSourceList } from "../../../../Utils/NewsUtils";

export class NewsSearchService {
    static calculateItemsToFetch(
        searchResults: SearchResultsInfo | undefined,
        defaultFetchCount: number
    ): number {
        if (!searchResults || searchResults.results instanceof Error) {
            return defaultFetchCount;
        }

        const calculatedCount = searchResults.results.length * 2;
        return searchResults.totalCount
            ? Math.min(searchResults.totalCount, calculatedCount)
            : calculatedCount;
    }

    static handleSearchResponse(
        searchResultsDoubleMap: SearchInputToSourceFilterToResultsDoubleMap,
        searchKey: string,
        filterKey: string,
        results: INewsLmdNewsItem[],
        totalCount: number
    ): SearchInputToSourceFilterToResultsDoubleMap {
        return updateDoubleMapValue<SearchResultsInfo>(
            searchResultsDoubleMap,
            searchKey,
            filterKey,
            {
                results,
                totalCount,
                waitingQueue: []
            }
        );
    }

    static handleSearchError(
        searchResultsDoubleMap: SearchInputToSourceFilterToResultsDoubleMap,
        searchKey: string,
        filterKey: string,
        error: Error
    ): SearchInputToSourceFilterToResultsDoubleMap {
        return updateDoubleMapValue<SearchResultsInfo>(
            searchResultsDoubleMap,
            searchKey,
            filterKey,
            {
                results: error,
                totalCount: 0,
                waitingQueue: []
            }
        );
    }
}

export const useNewsSearch = (
    state: SearchState,
    callbacks: SearchCallbacks,
    config: SearchConfig
) => {
    const timeoutRef = useRef<NodeJS.Timeout>();

    const executeSearch = (
        itemsToFetch: number,
        searchKey: string,
        filterKey: string,
        searchQuery?: string,
        sourceFilter?: string
    ) => {
        callbacks.fetchNews(
            itemsToFetch,
            (results, totalCount) => {
                const newMap = NewsSearchService.handleSearchResponse(
                    state.searchResultsDoubleMap,
                    searchKey,
                    filterKey,
                    results,
                    totalCount
                );
                callbacks.setSearchResultsDoubleMap(newMap);
            },
            (err) => {
                const newMap = NewsSearchService.handleSearchError(
                    state.searchResultsDoubleMap,
                    searchKey,
                    filterKey,
                    err
                );
                callbacks.setSearchResultsDoubleMap(newMap);
            },
            undefined,
            searchQuery,
            sourceFilter
        );
    };

    const withDelay = (func: () => void) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = window.setTimeout(func, config.debounceMs) as unknown as NodeJS.Timeout;
    };

    useEffect(() => {
        if (state.usingDefaultNews || state.isCompleteSlice || state.usingSourceFilter === 'none') {
            return;
        }

        if (!state.usingSearchWord) {
            // Source filter only
            const sourceFilterString = getSourceStringFromSourceList(state.sourceFilters, 'on');
            if (!sourceFilterString) return;

            const searchResults = state.searchResultsDoubleMap?.['']?.[sourceFilterString];
            const itemsToFetch = NewsSearchService.calculateItemsToFetch(
                searchResults,
                config.defaultFetchCount
            );

            executeSearch(itemsToFetch, '', sourceFilterString, undefined, sourceFilterString);
        } else {
            // With search word
            if (state.usingSourceFilter === 'all') {
                // No source filter
                const searchResults = state.searchResultsDoubleMap?.[state.searchInput]?.[state.category];
                const itemsToFetch = NewsSearchService.calculateItemsToFetch(
                    searchResults,
                    config.defaultFetchCount
                );

                withDelay(() => executeSearch(
                    itemsToFetch,
                    state.searchInput,
                    state.category,
                    state.searchInput
                ));
            } else {
                // With source filter
                const sourceFilterString = getSourceStringFromSourceList(state.sourceFilters, 'on');
                if (!sourceFilterString) return;

                const searchResults = state.searchResultsDoubleMap?.[state.searchInput]?.[sourceFilterString];
                const itemsToFetch = NewsSearchService.calculateItemsToFetch(
                    searchResults,
                    config.defaultFetchCount
                );

                withDelay(() => executeSearch(
                    itemsToFetch,
                    state.searchInput,
                    sourceFilterString,
                    state.searchInput,
                    sourceFilterString
                ));
            }
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [
        state.usingDefaultNews,
        state.searchInput,
        state.sourceFilters,
        state.searchResultsDoubleMap,
        state.isCompleteSlice,
        state.usingSourceFilter
    ]);
};