import { MenuItem, ListItemIcon, ListItemText, CircularProgress } from "@mui/material";
import { GridColumnMenu, GridColumnMenuItemProps, GridColumnMenuProps } from "@mui/x-data-grid-premium";
import IsoIcon from "@mui/icons-material/Iso";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TranslationManager } from "../../Translation/Translation";

export function CustomUserItem(
    props: GridColumnMenuItemProps
) {
    const { myCustomHandler, myCustomValue } = props;
    return (
        <MenuItem onClick={myCustomHandler}>
            <ListItemIcon>
                <IsoIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{myCustomValue}</ListItemText>
        </MenuItem>
    );
}

export function MyColumnSortedDescendingIcon() {
    return <FontAwesomeIcon icon={faCaretDown} style={{ fontSize: '10px' }} />
}

export function MyColumnSortedAscendingIcon() {
    return <FontAwesomeIcon icon={faCaretUp} style={{ fontSize: '10px' }} />
}

interface CustomColumnMenuProps {
    ref: React.RefObject<HTMLElement>;
    state: any;
    saveState: (state: any) => void;
}

export const ColumnMenu = ({ ref, state, saveState }: CustomColumnMenuProps) => {
    const ColumnMenuComponent = (props: GridColumnMenuProps) => (
        <GridColumnMenu
            {...props}
            slots={{
                columnMenuUserItem: CustomUserItem,
            }}
            slotProps={{
                columnMenuUserItem: {
                    displayOrder: 15,
                    myCustomValue: "Resize",
                    myCustomHandler: () => {
                        if (ref.current) {
                            const tableParent = ref.current;
                            const newTableState = calculateResizedState(tableParent, state);
                            saveState(newTableState);
                        }
                    },
                },
            }}
        />
    );

    return ColumnMenuComponent;
};

export function MyNoRowsOverlay() {
    return (
        <div className="fill center-container">
        </div>
    );
}

export function MyLoadingOverlay() {
    return <div className="fill center-container">
        <CircularProgress />;
    </div>
}

// ChatGPT solution
export function estimateWidth(text: string, fontSize: number): number {
    // Create a temporary span element
    const span = document.createElement("span");

    // Set its content and styles
    span.textContent = text;
    span.style.fontSize = fontSize + "px";
    span.style.position = "absolute"; // so it doesn't affect layout
    span.style.left = "-10000px"; // move it off-screen
    span.style.whiteSpace = "nowrap"; // ensure text doesn't wrap

    // Append to body, measure width, then remove
    document.body.appendChild(span);
    const width = span.offsetWidth;
    document.body.removeChild(span);

    return width;
}

export function calculateResizedState(
    tableContainer: HTMLElement,
    currentState: any
): any {
    const MinWidth = 65;

    const tableState = currentState === undefined ? {} : { ...currentState };
    if (tableState.columns === undefined) tableState.columns = {};
    if (tableState.columns.dimensions === undefined)
        tableState.columns.dimensions = {};

    const allCells: HTMLElement[] = Array.from(
        tableContainer.querySelectorAll(`.table-cell`)
    );
    const columnNames = new Set(
        allCells.map((element) => element.getAttribute("data-column-name") ?? "")
    );
    const cellsByColumn: Record<string, HTMLElement[]> = {};
    columnNames.forEach((column) => (cellsByColumn[column] = []));
    allCells.forEach((cell) => {
        const columnName = cell.getAttribute("data-column-name") || "";
        cellsByColumn[columnName].push(cell);
    });

    const getElementsWidth = (element: HTMLElement) => {
        return estimateWidth(element.innerText, fontSizeInPixels) + 20;
    };

    const fontSizeInPixels = parseFloat(
        window.getComputedStyle(allCells[0], null).getPropertyValue("font-size")
    );
    columnNames.forEach((column) => {
        const children = cellsByColumn[column];
        const maxWidth = Math.max(
            MinWidth,
            estimateWidth(
                TranslationManager.getTranslation().Columns[column],
                fontSizeInPixels
            ) + 20,
            ...Array.from(children).map((child) => getElementsWidth(child))
        );

        tableState.columns.dimensions[column] = {
            maxWidth: -1,
            MinWidth,
            width: maxWidth,
            flex: 0,
        };
    });

    return tableState;
}
