import { BehaviorSubject, Subscription } from "rxjs";
import { SubscriptionCommands, SymbolMessageType } from "../../../Types/Websocket";
import { AppStateManager } from "../../../StateManager";
import { ManageSubscriptionMessage } from "../WebsocketHelpers";

export abstract class MessageHandler<T> {
    abstract handleMessage(message: T): void;
    abstract regenerateSubscriptionMessage(): ManageSubscriptionMessage;
}


type Symbol = string;
export abstract class SymbolMessageHandler<IncomingMessage, SymbolInfo> extends MessageHandler<IncomingMessage> {

    symbolHandlerDetails: Record<string, {
        symbolInfo: SymbolInfo;
        behaviorSubject: BehaviorSubject<SymbolInfo>
        subscriptionCount: number;
        waitingOnAction: (() => void) | null;
    }>;

    private updatePushDelayMS: number = 1000;

    constructor() {
        super();
        this.symbolHandlerDetails = {};
    }

    abstract messageType: SymbolMessageType;

    abstract initialValue(symbol: string): SymbolInfo;
    abstract messageSymbol(message: IncomingMessage): Symbol;
    abstract handleSymbolsMessage(symbol: Symbol, message: IncomingMessage): void;

    handleMessage(message: IncomingMessage): void {
        const symbol = this.messageSymbol(message);
        if (!(symbol in this.symbolHandlerDetails)) this.initSymbol(symbol);
        this.handleSymbolsMessage(symbol, message);
    }

    regenerateSubscriptionMessage(): ManageSubscriptionMessage {
        const symbols = Object.keys(this.symbolHandlerDetails).filter(symbol => {
            const { subscriptionCount } = this.symbolHandlerDetails[symbol];
            return (subscriptionCount > 0);
        });
        return {
            type: this.messageType,
            command: SubscriptionCommands.Subscribe,
            message: { symbols }
        };
    }



    // Might delete logic later and just replace with
    pushUpdate(symbol: string) {
        if (this.symbolHandlerDetails[symbol].waitingOnAction === null) {
            const { symbolInfo, behaviorSubject } = this.symbolHandlerDetails[symbol];
            behaviorSubject.next(symbolInfo);
            this.symbolHandlerDetails[symbol].waitingOnAction = () => {
                this.symbolHandlerDetails[symbol].waitingOnAction = null;
            }
            setTimeout(() => {
                const { waitingOnAction } = this.symbolHandlerDetails[symbol];
                if (waitingOnAction !== null) waitingOnAction();
            }, this.updatePushDelayMS);
        } else {
            this.symbolHandlerDetails[symbol].waitingOnAction = () => {
                const { symbolInfo, behaviorSubject } = this.symbolHandlerDetails[symbol];
                behaviorSubject.next(symbolInfo);
                this.symbolHandlerDetails[symbol].waitingOnAction = null;
            }
        }
    }

    initSymbol(symbol: string) {
        this.symbolHandlerDetails[symbol] = {
            subscriptionCount: 0,
            behaviorSubject: new BehaviorSubject(this.initialValue(symbol)),
            symbolInfo: this.initialValue(symbol),
            waitingOnAction: null
        }
    }


    subscribe(symbol: string, callback: (info: SymbolInfo) => void): Subscription {
        if (!(symbol in this.symbolHandlerDetails)) this.initSymbol(symbol);

        this.symbolHandlerDetails[symbol].subscriptionCount += 1;
        // if subscriptionCount for symbol went from 0 -> 1
        if (this.symbolHandlerDetails[symbol].subscriptionCount === 1)
            AppStateManager.MF.manageSymbol(symbol, SubscriptionCommands.Subscribe, this.messageType);


        const subscription = this.symbolHandlerDetails[symbol].behaviorSubject.subscribe(callback);
        const tearDownLogic = () => {
            this.symbolHandlerDetails[symbol].subscriptionCount -= 1;
            // if subscriptionCount for symbol went from 1 -> 0
            if (this.symbolHandlerDetails[symbol].subscriptionCount === 0)
                AppStateManager.MF.manageSymbol(symbol, SubscriptionCommands.Unsubscribe, this.messageType);
        };
        subscription.add(tearDownLogic);
        return subscription;
    }
}