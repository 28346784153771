import { format, parse } from "date-fns";
import { TranslationManager } from "../Translation/Translation";
import { enGB, is } from "date-fns/locale";

// ChatGPT
export function convertKODITimeStringToDate(timeString: string): Date {
    const currentDate = new Date();
    const [hours, minutes, seconds, milliseconds] = timeString.split(":").map(Number);

    // Set the time components to the current date
    currentDate.setHours(hours || 0);
    currentDate.setMinutes(minutes || 0);
    currentDate.setSeconds(seconds || 0);
    currentDate.setMilliseconds(milliseconds || 0);

    return currentDate;
}

export function formatNewsDate(dateString: string): string {
    const inputDate = new Date(dateString);
    const currentDate = new Date();

    // Check if the input date is the same day as the current date
    const sameDay = inputDate.getDate() === currentDate.getDate() &&
        inputDate.getMonth() === currentDate.getMonth() &&
        inputDate.getFullYear() === currentDate.getFullYear();

    if (sameDay) {
        // If it's the same day, return the time in HH:MM:SS format
        return inputDate.toISOString().substring(11, 19);
    } else {
        // If it's not the same day, return the date in DD:MM:YYYY format
        const day = inputDate.getDate();
        const month = inputDate.getMonth() + 1; // Months are 0-based in JS
        const year = inputDate.getFullYear();

        return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    }
}

export function formatDate(date: Date | number | undefined | string): string {
    if (!date) return '';
    if (typeof date === 'string') date = new Date(date);
    const locale = TranslationManager.getActiveLanguage() === 'EN' ? enGB : is;
    return format(date, 'dd.MM.yyyy', { locale: locale })
}

/**
 * Pads single-digit months and days with a leading zero.
 * @param dateStr - The date string in the format "yyyy-mm-d" or "yyyy-m-dd".
 * @returns The formatted date string with months and days padded to two digits.
 */
export function padDate(dateStr: string): string {
    // Split the date string into components
    const [year, month, day] = dateStr.split('-');

    // Pad the month and day with leading zeros if necessary
    const paddedMonth = month.padStart(2, '0');
    const paddedDay = day.padStart(2, '0');

    // Return the formatted date string
    return `${year}-${paddedMonth}-${paddedDay}`;
}

export function getAPIDate(date: Date | string | undefined | null): string {
    if (!date) return '';
    const locale = TranslationManager.getActiveLanguage() === 'EN' ? enGB : is;
    let parsedDate: Date;

    try {
        if (typeof date === 'string') {
            const formatString = detectDateFormat(date);
            parsedDate = parse(date, formatString, new Date());

            // If parsing fails, try as ISO string
            if (parsedDate.toString() === 'Invalid Date') {
                parsedDate = new Date(date);
            }
        } else {
            parsedDate = new Date(date);
        }

        // Check if we got a valid date
        if (parsedDate.toString() === 'Invalid Date') {
            return '';
        }

        return format(parsedDate, 'yyyy-MM-dd', { locale });
    } catch {
        return '';
    }
}
export function detectDateFormat(dateStr: string): string {
    // Define patterns with their corresponding date-fns format strings
    const patterns: [RegExp, string][] = [
        [/^\d{2}\.\d{2}\.\d{4}$/, 'dd.MM.yyyy'],
        [/^\d{4}\.\d{2}\.\d{2}$/, 'yyyy.MM.dd'],
        [/^\d{2}-\d{2}-\d{4}$/, 'dd-MM-yyyy'],
        [/^\d{4}-\d{2}-\d{2}$/, 'yyyy-MM-dd']
    ];
    const trimmedDateStr = dateStr.trim();

    for (const [regex, formatString] of patterns) {
        if (regex.test(trimmedDateStr)) {
            try {
                // Validate the date by attempting to parse it
                const parsed = parse(trimmedDateStr, formatString, new Date());
                if (parsed.toString() !== 'Invalid Date') {
                    return formatString;
                }
            } catch {
                continue;
            }
        }
    }

    // Default to ISO format if no match is found
    return 'yyyy-MM-dd';
}