import { formatNumber } from "../../../Utils/Formatting";
import { motion } from "framer-motion";

const barStyles = {
  flex: 1,
  display: "flex",
  alignItems: "center",
  gap: "5px",
};

const Bar: React.FC<{
  color: "red" | "green";
  justify: "flex-start" | "flex-end";
  volume: number | undefined;
  percentage: number;
}> = ({ color, justify, volume, percentage }) => (
  <div style={{ ...barStyles, justifyContent: justify }}>
    {color === "green" ? formatNumber(volume) : null}
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: percentage + "%" }}
      transition={{ duration: 0.5 }}
      style={{
        background: `var(--${color}-200)`,
        height: "8px",
        borderRadius: "10px",
      }}
    />
    {color === "red" ? formatNumber(volume) : null}
  </div>
);

const LadderStep: React.FC<{
  color: "red" | "green";
  price: number | undefined;
  volume: number | undefined;
  maxVol: number;
}> = ({ color, price, volume, maxVol }) => {
  //calculate the percentage of the volume
  const percentage = volume ? (volume / maxVol) * 100 : 0;
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        gap: "20px",
        fontSize: "12px",
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "5px",
        }}
      >
        {color === "green" && (
          <Bar
            color={"green"}
            justify={"flex-end"}
            volume={volume}
            percentage={percentage}
          />
        )}
      </div>
      <div
        style={{
          flexBasis: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {formatNumber(price, 3)}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "5px",
        }}
      >
        {color === "red" && (
          <Bar
            color={"red"}
            justify={"flex-start"}
            volume={volume}
            percentage={percentage}
          />
        )}
      </div>
    </div>
  );
};

export default LadderStep;
