import { useCallback, useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import cx from "classnames";
import { DatePickerField } from "../../UI-Elements/FormikInputs/DatePicker";
import { MoreTradeableInfo } from "../../../Types/LMDTypes";
import NumberInput, { cleanValueWithTickerFormatting } from "../../UI-Elements/FormikInputs/NumberInput";
import { LMDInterface } from "../../../KodiInterface/LMD";
import { TranslationManager } from "../../../Translation/Translation";
import { generateUUID } from "../../../Utils/Common";
import { openPropertiesOfSymbol } from "../../Main/Main";
import ClearAllButton from "../../UI-Elements/ClearAllButton";
import { SymbolSelector } from "../MarketDepth/Components";
import Button from "../../UI-Elements/Button";
import { formatDate } from "../../../Utils/Date";
import Radio from "../../UI-Elements/Radio";


interface ICalculateFormProps {
    symbol: string | undefined,
    lastPrice: number | undefined,
    dirtyPrice: number | undefined,
    yieldPrice: number | undefined,
    symbolInfo: MoreTradeableInfo | undefined,
    settlementdate: string,
    width: number | undefined,
    onSubmit: (values) => Promise<void>,
    symbolChange: (symbol: string) => void,
    clearWindow: () => void
}
type radioValues = 'price' | 'dirty' | 'yield';

export const CalculatorForm: React.FC<ICalculateFormProps> = ({
    symbol,
    lastPrice,
    dirtyPrice,
    yieldPrice,
    symbolInfo,
    settlementdate,
    width,
    symbolChange,
    onSubmit,
    clearWindow
}) => {
    const formikRef = useRef<any>(null);
    const radioOptions: { id: string, value: radioValues, name: string }[] = [
        { id: 'clean', value: 'price', name: 'clean_price' },
        { id: 'dirty', value: 'dirty', name: 'dirty_price' },
        { id: 'yield', value: 'yield', name: 'yield' },
    ];

    const initialValues = {
        symbol: symbol,  // Initialize with prop value
        date: settlementdate,
        clean_price: lastPrice ? cleanValueWithTickerFormatting(lastPrice) : undefined,
        dirty_price: dirtyPrice ? cleanValueWithTickerFormatting(dirtyPrice) : undefined,
        yield: yieldPrice ? cleanValueWithTickerFormatting(lastPrice) : undefined,
        nominal_value: "1000000",
        activeRadio: 'price'
    };

    const validationSchema = Yup.object().shape({
        symbol: Yup.string().required('Symbol is required'),
        clean_price: Yup.string().when('activeRadio', (activeRadio, schema) => {
            return activeRadio[0] === 'price' ? schema.required('Clean price is required when selected') : schema.nullable();
        }),
        dirty_price: Yup.string().when('activeRadio', (activeRadio, schema) => {
            return activeRadio[0] === 'dirty' ? schema.required('Dirty price is required when selected') : schema.nullable();
        }),
        yield: Yup.string().when('activeRadio', (activeRadio, schema) => {
            return activeRadio[0] === 'yield' ? schema.required('Yield is required when selected') : schema.nullable();
        }),
    });

    // Handle settlement date fetch only once on mount
    useEffect(() => {
        const fetchSettlementDate = async () => {
            const formik = formikRef.current;
            if (!formik || formik.values.date !== "") return;

            const response = await LMDInterface.getNextSettlementDay();
            formik.setFieldValue('date', formatDate(response.value), false);
        };

        fetchSettlementDate();
    }, []); // Empty dependency array

    // Handle price info and symbol updates
    useEffect(() => {
        const formik = formikRef.current;
        if (!formik) return;

        const updateFormValues = async () => {
            if (symbol !== formik.values.symbol) {
                await formik.setFieldValue('symbol', symbol, false);
            }

            await formik.setFieldValue('clean_price', cleanValueWithTickerFormatting(lastPrice), false);

            // Only submit if the values match the current selection
            const currentValues = formik.values;
            if (currentValues.symbol === symbol) {
                if (lastPrice) {
                    await onSubmit({ ...currentValues, clean_price: lastPrice });
                }
            }
        };

        updateFormValues();
    }, [symbol, lastPrice]);

    useEffect(() => {
        if (formikRef.current) {
            formikRef.current.setFieldValue('dirty_price', cleanValueWithTickerFormatting(dirtyPrice), false);
            formikRef.current.setFieldValue('yield', cleanValueWithTickerFormatting(yieldPrice), false);
        }
    }, [dirtyPrice, yieldPrice]);

    const rendersymbolInfo = useCallback((label: string, value: string | undefined | React.JSX.Element) => {
        return (
            <div key={label} className="bondInfo">
                <span className="bondInfoLabel">{label}:</span>
                {value ? (
                    <span className="bondInfoValue">{value}</span>
                ) : (
                    <span className="bondInfoValue">&nbsp;</span>
                )}
            </div>
        );
    }, []);

    const differentOrentiation = width && width < 382;

    const renderLeftContainer = useCallback((items: React.ReactNode[]) => {
        if (differentOrentiation) {
            return (
                <div className="left-container">
                    <div className="flex-item">{items[0]}</div>
                    <div style={{ display: 'flex', marginBottom: '5px' }}>
                        <div className="flex-item">{items[1]}</div>
                        <div className="flex-item">{items[2]}</div>
                    </div>
                    <div className="flex-item">{items[3]}</div>
                </div>
            );
        }

        return (
            <div className="left-container">
                {items.slice(0, 4).map((item, index) => (
                    <div key={'flex-item' + index} className={cx("flex-item")}>
                        {item}
                    </div>
                ))}
            </div>
        );
    }, [differentOrentiation]);

    return (
        <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            enableReinitialize={true}
            onSubmit={async (values) => onSubmit(values)}
        >
            {({ setFieldValue, values, errors }) => {
                const radio = radioOptions.map(({ id, value, name }) => (
                    <div key={id} className="radio_input">
                        <Radio
                            radioSize="xsm"
                            id={id + generateUUID()}
                            name="active"
                            value={value}
                            defaultValue={values.activeRadio}
                            onChange={() => setFieldValue('activeRadio', value, false)}
                        >
                            <span style={{ width: '100px', whiteSpace: 'nowrap', fontSize: '12px' }}>
                                {TranslationManager.getTranslation().BondsCalculator[name]}
                            </span>
                        </Radio>
                        <Field
                            onClick={() => setFieldValue('activeRadio', value, false)}
                            fakeDisabled={values.activeRadio !== value}
                            inputSize="xsm"
                            as={NumberInput}
                            name={name}
                            type="number"
                            dontShowErrorText={true}
                        />
                    </div>
                ));

                const items = [
                    <SymbolSelector
                        key="symbol"
                        size="sm"
                        disableClearable={true}
                        selectedSymbol={values.symbol}
                        onSelect={(symbol) => {
                            setFieldValue("symbol", symbol, false);
                            symbolChange(symbol);
                        }}
                        autoFocus
                        selectWithTab={true}
                        type="bonds"
                        hasError={!!errors.symbol}
                    />,
                    rendersymbolInfo(
                        TranslationManager.getTranslation().BondsCalculator.Name,
                        symbolInfo?.Name
                    ),
                    <div
                        key="settlementdate"
                        style={{
                            display: 'flex',
                            justifyContent: differentOrentiation ? 'flex-end' : 'flex-start',
                        }}
                    >
                        {rendersymbolInfo(
                            TranslationManager.getTranslation().BondsCalculator.SettlementDate,
                            <DatePickerField
                                size="xsm"
                                name="date"
                                value={values.date}
                                tabIndex={-1}
                            />
                        )}
                    </div>,
                    <div key="hidden" className="hide-me" />,
                    ...radio,
                    <div key="nominalValue" className="radio_input">
                        <span>{TranslationManager.getTranslation().BondsCalculator.NominalValue}</span>
                        <Field
                            inputSize="xsm"
                            as={NumberInput}
                            name="nominal_value"
                            type="number"
                            decimalScale={0}
                            dontShowErrorText
                        />
                    </div>
                ];

                const firstError = Object.values(errors)[0];

                return (
                    <Form>
                        <div className="KMW_Calculator-form">
                            <div className="flex-container">
                                {renderLeftContainer(items)}
                                <div className="right-container">
                                    {items.slice(4).map((item, index) => (
                                        <div key={item.key} className="flex-item">
                                            {item}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div
                            className="error-button_container"
                            style={{ justifyContent: firstError ? 'space-between' : 'flex-end' }}
                        >
                            {firstError && <div className="error_message">{firstError}</div>}
                            <div className="button_container" style={{ whiteSpace: 'pre' }}>
                                <Button
                                    onClick={() => openPropertiesOfSymbol(symbol as string)}
                                    noMargin
                                    type="button"
                                    size="xsm"
                                >
                                    {TranslationManager.getTranslation().BondsCalculator.Buttons.Properties}
                                </Button>
                                <ClearAllButton onHeld={clearWindow} size="xsm">
                                    {TranslationManager.getTranslation().BondsCalculator.Buttons.Clear}
                                </ClearAllButton>
                                <Button noMargin type="submit" size="xsm">
                                    {TranslationManager.getTranslation().BondsCalculator.Buttons.Calculate}
                                </Button>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};