import { useState, useCallback } from "react";
import { LMDInterface } from "../../../KodiInterface/LMD";
import { OrderSubmitRequest } from "../../../Types/private-data/NewOrderSubmitRequest";
import { cleanValue } from "../../UI-Elements/FormikInputs/NumberInput";
import Button from "../../UI-Elements/Button";
import { buildOrderRequest } from "./utils";
import { IPriceInfoCallBack } from "./SymbolInfo";

// components/OrderForm/SubmitButton.tsx
const OrderSubmitButton: React.FC<{
    values: any;
    validateForm: () => Promise<Record<string, string>>;
    setErrors: (errors: Record<string, string>) => void;
    setTouched: (touched: Record<string, boolean>) => void;
    setPhaseTwo: (request: OrderSubmitRequest | undefined) => void;
    setCheck: (check: any) => void;
    priceInfo: IPriceInfoCallBack;
}> = ({
    values,
    validateForm,
    setErrors,
    setTouched,
    setPhaseTwo,
    setCheck,
    priceInfo
}) => {
        const [isSubmitting, setIsSubmitting] = useState(false);

        const handleSubmit = useCallback(async () => {
            if (isSubmitting) return;
            setIsSubmitting(true);

            try {
                const errors = await validateForm();

                if (Object.keys(errors).length > 0) {
                    setErrors(errors);
                    setTouched(
                        Object.keys(errors).reduce((acc, key) => ({
                            ...acc,
                            [key]: true
                        }), {})
                    );
                    return;
                }

                values.price = values.price
                    ? Number(cleanValue(values.price).replace(",", "."))
                    : 0;

                // Fat finger check
                let check;
                try {
                    check = values.order_type !== "market" && values.symbol !== null
                        ? await LMDInterface.fatFingerCheck(values.symbol, values.price, values.side)
                        : undefined;
                } catch (e) {
                    check = 'no_data';
                }
                setCheck(check);

                // Calculate visible quantity
                const visibleQty = calculateVisibleQuantity(values);

                // Create order request
                const orderRequest = buildOrderRequest({
                    values,
                    priceInfo,
                    visibleQty,
                });
                setPhaseTwo(orderRequest);

            } catch (error) {
                console.error('Order submission error:', error);
                // Handle error appropriately
            } finally {
                setIsSubmitting(false);
            }
        }, [
            isSubmitting,
            values,
            validateForm,
            setErrors,
            setTouched,
            setCheck,
            setPhaseTwo,
            priceInfo
        ]);

        return (
            <Button
                size="sm"
                type="button"
                buttonType="primary"
                disabled={isSubmitting}
                onClick={handleSubmit}
            >
                OK
            </Button>
        );
    };

// Utility function for visible quantity calculation
const calculateVisibleQuantity = (values: any): number | undefined => {
    if (values.order_type === 'market') return 0;
    if (!values.hidden) return undefined;

    if (values.visible_quantity &&
        values.visible_quantity !== "0" &&
        values.visible_quantity.trim() !== "") {
        return Number(cleanValue(values.visible_quantity));
    }

    return undefined;
};
export default OrderSubmitButton;
