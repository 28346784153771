import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { filter } from "rxjs/internal/operators/filter";
import { first } from "rxjs/internal/operators/first";
import {
  MessageType,
  SubscriptionCommands,
  SymbolMessageType,
  WSStatus,
} from "../../Types/Websocket";

import { PriceInfoHandler } from "./Handlers/PriceInfoHandler";
import { TradeTickHandler } from "./Handlers/TradeTickerHandler";
import { TimeHandler } from "./Handlers/TimeHandler";
import { MBOHandler } from "./Handlers/MBOHandler";
import { MBLHandler } from "./Handlers/MBLHandler";

import {
  WebSocketSubscriptionLogging,
} from "../../Config/Logging";
import { AppStateManager } from "../../StateManager";
import { WebsocketConnectionData } from "../../Types/LMDTypes";
import { hideDefaultModal, openDefaultModal } from "../../Components/Main/Main";
import { MultipleSessionsModal } from "../../Components/Modals/MultipleSessionsModal";
import { ManageSubscriptionMessage } from "./WebsocketHelpers";
import { toast } from "react-toastify";
import { ICONS } from "../../Utils/Definitions";
import { TranslationManager } from "../../Translation/Translation";
import { eventBus } from "../../Components/Windows/WatchList/EventBus";
import { BaseWebSocket } from "./BaseWebsocket";



export type WSManagerHandlers = {
  market_by_level: MBLHandler;
  market_by_order: MBOHandler;
  price_info: PriceInfoHandler;
  trade: TradeTickHandler;
  time: TimeHandler;
};

/**
 * PDSManagerRecord class is responsible for managing multiple PDSSockets and their handlers.
 */
export class MFManagerRecord {
  //Current socket
  public currentSocket: MFSocket | null;
  //available sockets
  public availableSockets: WebsocketConnectionData[];
  private failedSockets: string[];

  // Handlers for different types of messages.
  private handlers: WSManagerHandlers;

  public lastHeartBeat: BehaviorSubject<Date> = new BehaviorSubject(new Date());

  // Buffer for symbol subscriptions, allowing for batched subscribe/unsubscribe operations.
  private symbolSubscriptionBuffer: Record<
    SubscriptionCommands.Subscribe | SubscriptionCommands.Unsubscribe,
    Record<SymbolMessageType, { symbols: Set<string>; timeOutSet: boolean }>
  >;

  /**
   * Returns the handler for a given type.
   * @param type - The type of the handler.
   * @returns The handler for the given type.
   */
  public getHandler<T extends keyof WSManagerHandlers>(
    type: T
  ): WSManagerHandlers[T] {
    return this.handlers[type];
  }

  /**
   * Constructs a new PDSManagerRecord.
   */
  constructor() {

    this.availableSockets = [];
    this.failedSockets = [];
    this.currentSocket = null;

    this.handlers = {
      market_by_level: new MBLHandler(),
      market_by_order: new MBOHandler(),
      price_info: new PriceInfoHandler(),
      trade: new TradeTickHandler(),
      time: new TimeHandler(),
    };

    // Information for symbol message buffering, I'd like to remove this one day
    this.symbolSubscriptionBuffer = {
      subscribe: {
        price_info: { symbols: new Set(), timeOutSet: false },
        trade: { symbols: new Set(), timeOutSet: false },
        market_by_order: { symbols: new Set(), timeOutSet: false },
        market_by_level: { symbols: new Set(), timeOutSet: false },
        // 'news': {symbols: new Set(), timeOutSet: false},
      },
      unsubscribe: {
        price_info: { symbols: new Set(), timeOutSet: false },
        trade: { symbols: new Set(), timeOutSet: false },
        market_by_order: { symbols: new Set(), timeOutSet: false },
        market_by_level: { symbols: new Set(), timeOutSet: false },
        // 'news': {symbols: new Set(), timeOutSet: false},
      },
    }
  }

  private connectToSocket(socketUrl: string, name: string, token: string) {
    // If there's an existing socket, clean it up before creating a new one
    if (this.currentSocket) {
      this.currentSocket.status.complete(); // Unsubscribe from the status
      this.currentSocket.closeSocket(); // Close the socket
      this.currentSocket = null; // Set the current socket to null
    }

    // Create a new socket
    const newSocket = new MFSocket(socketUrl, name, token, this.handlers, this.getHandler, this.lastHeartBeat);
    this.currentSocket = newSocket;

    // Subscribe to the socket's status
    newSocket.status.subscribe((status) => {
      // If the status is 'closed', try to connect to another socket
      if (status === 'closed') {
        // Add the current socket to the failed sockets array
        this.failedSockets.push(newSocket.name)

        // If all sockets have been tried, log a message and return
        if (this.availableSockets.length === this.failedSockets.length) {
          this.failedSockets = [];
        }

        // Pick another socket
        const randomIndex = Math.floor(Math.random() * this.availableSockets.filter(x => !this.failedSockets.includes(x.name)).length);
        const randomSocket = this.availableSockets.filter(x => !this.failedSockets.includes(x.name))[randomIndex];

        //send reconnecting toast
        toast.warning(`${TranslationManager.getTranslation().ToastMessage.Connecting_to} ${randomSocket.name}`, {
          autoClose: false,
          closeButton: false,
          closeOnClick: false,
          icon: ICONS.reconnecting,
          type: "warning",
          toastId: 'market_feed',
        })
        // Connect to the new socket
        this.connectToSocket(randomSocket.url, randomSocket.name, token);
      }
      if (status === 'connected') {
        //update with success message
        toast.update('market_feed', {
          render: `${TranslationManager.getTranslation().ToastMessage.Connected_to} ${newSocket.name}`,
          autoClose: 2000,
          hideProgressBar: true,
          icon: ICONS.success,
          type: "success",
        })
      }
    });
    eventBus.next("triggerReSubscription")
  }

  public async changeSocket(socketName: string) {
    const socket = this.availableSockets.find((x) => x.name === socketName);
    if (socket) {
      this.connectToSocket(socket.url, socket.name, await AppStateManager.getToken());
      if (toast.isActive('market_feed')) {
        toast.update('market_feed', {
          render: `${TranslationManager.getTranslation().ToastMessage.Connected_to} ${socket.name}`,
          autoClose: 2000,
          hideProgressBar: true,
          icon: ICONS.success,
          type: "success",
        });
      } else {
        toast(`${TranslationManager.getTranslation().ToastMessage.Connected_to} ${socket.name}`, {
          autoClose: 2000,
          hideProgressBar: true,
          icon: ICONS.success,
          toastId: 'market_feed',
          type: "success",
        });
      }
    }
  }

  public initial(token: string) {
    // Filter all available sockets for market data
    AppStateManager.userProfile.access.forEach((connection) => {
      if (connection.type === "market_data") {
        this.availableSockets.push(connection)
      }
    })

    // If there are no available sockets, return
    if (this.availableSockets.length === 0) {
      //This will never happen. Since we check if the user has access to market data before connecting
      return;
    }

    // Pick a random socket and connect to it
    const randomIndex = Math.floor(Math.random() * this.availableSockets.length);
    const randomSocket = this.availableSockets[randomIndex];
    this.connectToSocket(randomSocket.url, randomSocket.name, token);
  }

  private async send(message: ManageSubscriptionMessage) {
    // Wait to send the connection untill the WSManager is connected.
    if (this.currentSocket) {
      this.currentSocket.status
        .pipe(
          filter((status) => status === "connected"),
          first()
        )
        .subscribe(async (connectedStatus) => {
          if (WebSocketSubscriptionLogging)
            console.log("Sending over websocket:");
          if (WebSocketSubscriptionLogging) console.log(message);
          //@ts-ignore
          const socket = await this.currentSocket.socket.waitForValue();
          socket.send(JSON.stringify(message));
        });
    }
  }
  // Program gets flooded with subscription message from every cell
  // This groups up symbols for 100ms before sending message
  async manageSymbol(
    symbol: string,
    command: SubscriptionCommands,
    type: SymbolMessageType
  ) {
    const bufferInfo = this.symbolSubscriptionBuffer[command][type];
    bufferInfo.symbols.add(symbol);
    if (!bufferInfo.timeOutSet) {
      bufferInfo.timeOutSet = true;
      setTimeout(() => {
        this.send({
          type,
          command,
          message: { symbols: [...bufferInfo.symbols] },
        });
        bufferInfo.symbols.clear();
        bufferInfo.timeOutSet = false;
      }, 100);
    }
  }

  async manageTimeSubscription(command: SubscriptionCommands) {
    return this.send({ type: MessageType.time, command });
  }
}

export class MFSocket extends BaseWebSocket<WSManagerHandlers> {
  private hasShownInitialConnection = false;

  protected handleMessage(messageEvent: {
    message: any;
    type: MessageType | "status" | "authenticate" | "authentication";
  }): void {
    this.lastHeartBeat.next(new Date());
    const { message, type } = messageEvent;

    if (type in this.handlers) {
      this.getHandler(type as keyof WSManagerHandlers).handleMessage(message);
    } else if (
      [
        MessageType.first_level,
        MessageType.second_level,
        MessageType.third_level as string,
      ].includes(type)
    ) {
      this.handlers[MessageType.market_by_level].handleMessage(message);
    } else if (type === "status") {
      this.handleStatus(message.status);
    } else if (type === "authenticate") {
      this.handleAuthentication(message);
    } else {
      console.error("Unhandled message from server:", { type }, message);
    }
  }

  protected handleStatusChange(status: WSStatus): void {
    super.handleStatusChange(status);

    const active = toast.isActive('market_feed');

    if (status === 'closed') {
      toast.error(`${'TranslationManager.getTranslation().ToastMessage.Closed'} ${this.name}`, {
        autoClose: false,
        closeButton: false,
        closeOnClick: false,
        icon: ICONS.error,
        type: "error",
        toastId: 'market_feed',
      });
    } else if (status === 'connecting' && this.hasShownInitialConnection) {
      toast.warning(`${TranslationManager.getTranslation().ToastMessage.Connecting_to} ${this.name}`, {
        autoClose: false,
        closeButton: false,
        closeOnClick: false,
        icon: ICONS.reconnecting,
        type: "warning",
        toastId: 'market_feed',
      });
    } else if (status === 'connected') {
      if (!this.hasShownInitialConnection) {
        this.hasShownInitialConnection = true;
      } else if (active) {
        toast.update('market_feed', {
          render: `${TranslationManager.getTranslation().ToastMessage.Connected_to} ${this.name}`,
          autoClose: 2000,
          hideProgressBar: true,
          icon: ICONS.success,
          type: "success",
        });
      }
    }
  }

  protected handleMultipleSessions(): void {
    this.multiple_session_detected = true;
    const socket = this.socket.value();
    if (socket) {
      socket.onclose = () => { };
      socket.close();
      this.retryTimer.active = false;
      this.retryTimer.intervalSeconds = 0;
      this.retryTimer.timer = null;
      this.status.next('multiple_session');
      openDefaultModal(
        <MultipleSessionsModal reconnect={() => {
          this.createSocket();
          hideDefaultModal();
        }} />
      );
    }
  }
}
