import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, TextField, Box } from "@mui/material";
import cx from "classnames";
import React from "react";
import styled from "styled-components";
import { ListInfo } from "../../../../Types/LMDTypes";

function ListAutocomplete({ lists, value, onChange }: { lists: ListInfo[], value: ListInfo | undefined, onChange: (list: ListInfo) => void }) {
    function listInfoToItem(listInfo: ListInfo): ListInfo & { label: string } {
        return { ...listInfo, label: listInfo.name || '' }
    }
    const [isOpen, setIsOpen] = React.useState(false);
    return (
        <Autocomplete
            autoHighlight
            popupIcon={<FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '10px' }} />}
            options={lists.map(listInfoToItem)}
            groupBy={(option) => option.country}
            sx={{
                borderRadius: '6px',
                width: '100%', padding: 0,
                '& input': {
                    width: 200,
                    height: 20
                }
            }}
            renderInput={(params) => <TextField {...params}
                size='small'
                className={cx('autocomplete-custom', { 'isOpen': isOpen })}
                sx={{
                    padding: '0px',
                }}
                inputProps={{
                    ...params.inputProps,
                }}
            />
            }
            renderGroup={(params) => {
                const GroupItems = styled('ul')({ padding: 0 });
                return <li key={params.key} style={{ paddingTop: 6, paddingBottom: 4 }}>
                    <div style={{
                        paddingLeft: 8, paddingRight: 8, paddingTop: 0, paddingBottom: 4,
                        background: 'var(--white)',
                        textTransform: 'uppercase',
                        fontSize: '10px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                        color: 'var(--dark-400)',
                    }}>{params.group}</div>
                    <GroupItems>{params.children}</GroupItems>
                </li>
            }}
            renderOption={(props, option) => (
                <Box component="li" {...props} sx={{
                    color: 'var(--dark-900, #232530)',
                    fontSize: '11px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }} >
                    <div style={{
                        maxWidth: '85%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'
                    }}>{option.name}
                    </div>
                    <div style={{
                        paddingLeft: 0,

                    }}>&nbsp;({option.count})</div>
                </Box >
            )}
            onOpen={() => {
                setIsOpen(true);
            }}
            onClose={() => {
                setIsOpen(false);
            }}
            disableClearable
            value={value}
            size='small'
            getOptionLabel={(option) => typeof option !== 'string' && option ? option.name : option}
            isOptionEqualToValue={(a, b) => a && b && a.symbol === b.symbol}
            onChange={(event: any, newValue: any) => onChange(newValue as ListInfo)}
        />);
}

export default ListAutocomplete;