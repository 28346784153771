import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { filter } from "rxjs/internal/operators/filter";
import { first } from "rxjs/internal/operators/first";
import {
  AuthenticationMessage,
  MessageType,
  OmsMessage,
  StreamerError,
  SubscriptionCommands,
  WSStatus,
} from "../../Types/Websocket";

import {
  PDSWebSocketConsoleLogging,
  WebSocketSubscriptionLogging,
} from "../../Config/Logging";
import { AppStateManager } from "../../StateManager";
import { hideDefaultModal, openDefaultModal } from "../../Components/Main/Main";
import { MultipleSessionsModal } from "../../Components/Modals/MultipleSessionsModal";
import { LMDInterface } from "../LMD";
import { ViewOrdersHandler } from "./Handlers/ViewOrdersHandler";
import { ViewTradesHandler } from "./Handlers/ViewTradesHandler";
import { ManageSubscriptionMessage } from "./WebsocketHelpers";
import { BaseWebSocket } from "./BaseWebsocket";

export type RetailManagerHandlers = {
  view_orders: ViewOrdersHandler;
  view_trades: ViewTradesHandler;
};

/**
 * PDSManagerRecord class is responsible for managing multiple PDSSockets and their handlers.
 */
export class RetailWebsocketManager {
  //Current socket
  public currentSocket: RetailWebSocket | null;
  //available sockets
  // public availableSockets: WebsocketConnectionData[];

  // Handlers for different types of messages.
  private handlers: RetailManagerHandlers;

  public lastHeartBeat: BehaviorSubject<Date> = new BehaviorSubject(new Date());

  /**
   * Returns the handler for a given type.
   * @param type - The type of the handler.
   * @returns The handler for the given type.
   */
  public getHandler<T extends keyof RetailManagerHandlers>(
    type: T
  ): RetailManagerHandlers[T] {
    return this.handlers[type];
  }

  /**
   * Constructs a new PDSManagerRecord.
   */
  constructor() {
    this.currentSocket = null;

    this.handlers = {
      view_orders: new ViewOrdersHandler(),
      view_trades: new ViewTradesHandler(),
    };
  }
  public connectToSocket(socketUrl: string, token: string, name: string, callback: (e) => void) {
    //if there exists  a socket, close it
    // If there's an existing socket, clean it up before creating a new one
    if (this.currentSocket) {
      this.currentSocket.status.complete(); // Unsubscribe from the status
      this.currentSocket.closeSocket(); // Close the socket
      this.currentSocket = null; // Set the current socket to null

    }
    // Create a new socket
    const newSocket = new RetailWebSocket(socketUrl, name ?? '', token, this.handlers, this.getHandler.bind(this), this.lastHeartBeat);
    this.currentSocket = newSocket;

    // Subscribe to the status of the socket
    newSocket.status.subscribe(async (status) => {

      //success hide the modal, remove the component from the root and add it to the root also fetch the customer info
      if (status === 'connected') {
        AppStateManager.hasAccessToRetail.next(true);
        callback("connected");
        //fetch customer info
        const customerinfo = await LMDInterface.getRetailCustomer(new URL(socketUrl).host);
        if ('customer_id' in customerinfo) {
          AppStateManager.customerInfo = customerinfo;
        } else {
          console.error('Failed to fetch customer info:', customerinfo);
        }
      }
      //if closed, show the modal and show a toast
      else if (status === 'closed') {
        callback('closed');
      }
    });
  }

  private async send(message: ManageSubscriptionMessage) {
    // Wait to send the connection untill the WSManager is connected.
    if (this.currentSocket) {
      this.currentSocket.status
        .pipe(
          filter((status) => status === "connected"),
          first()
        )
        .subscribe(async (connectedStatus) => {
          if (WebSocketSubscriptionLogging)
            console.log("Sending over websocket:");
          if (WebSocketSubscriptionLogging) console.log(message);
          //@ts-ignore
          const socket = await this.currentSocket.socket.waitForValue();
          socket.send(JSON.stringify(message));
        });
    }
  }
  async manageTimeSubscription(command: SubscriptionCommands) {
    return this.send({ type: MessageType.time, command });
  }
}
export class RetailWebSocket extends BaseWebSocket<RetailManagerHandlers> {
  protected createAuthenticationMessage(): any {
    return {
      type: 'authentication',
      command: "logon",
      message: {
        token: this.token,
        token_type: "Bearer",
        expires_in: 86400,
      },
    };
  }

  protected handleMessage(messageEvent: {
    message: AuthenticationMessage | OmsMessage[] | OmsMessage | any;
    type: MessageType | "status" | "authentication" | "oms" | "error";
    command: "order" | "trade";
  }): void {
    const broker = this.name;
    const { message, type } = messageEvent;

    if (PDSWebSocketConsoleLogging) {
      console.log("Message from server:", type, message);
    }

    if (type === "error") {
      this.handleErrorMessages(message);
    } else if (type === "status") {
      this.handleStatus(message.status);
    } else if (type === "authentication") {
      this.handleAuthentication(message);
    } else if (Array.isArray(messageEvent)) {
      this.handleArrayMessage(messageEvent, broker);
    } else if (messageEvent.type === "oms") {
      this.handleOmsMessage(messageEvent, broker);
    } else {
      console.error("Unhandled message from server:", { type }, message);
    }
  }

  private handleArrayMessage(messages: any[], broker: string): void {
    if (messages[0].type === "oms") {
      messages.forEach((message: OmsMessage) => {
        if (message.command === "order") {
          this.getHandler("view_orders").handleMessage(
            message.message.map(item => ({ ...item, broker }))
          );
        } else if (message.command === "trade") {
          this.getHandler("view_trades").handleMessage(
            message.message.map(item => ({ ...item, broker }))
          );
        }
      });
    }
  }

  private handleOmsMessage(message: any, broker: string): void {
    if (message.command === "order") {
      this.getHandler("view_orders").handleMessage(
        [{ ...message.message, broker }]
      );
    } else if (message.command === "trade") {
      this.getHandler("view_trades").handleMessage(
        [{ ...message.message, broker }]
      );
    }
  }

  private handleErrorMessages(error: { reason: string; status: string }): void {
    if (error.reason === StreamerError.MarketFeedNotOnline || error.status === "offline") {
      this.closeSocket();
    }
  }

  protected handleMultipleSessions(): void {
    this.multiple_session_detected = true;
    const socket = this.socket.value();
    if (socket) {
      socket.onclose = () => { };
      socket.close();
      this.retryTimer.active = false;
      this.retryTimer.intervalSeconds = 0;
      this.retryTimer.timer = null;
      this.status.next('multiple_session');
      openDefaultModal(
        <MultipleSessionsModal
          reconnect={() => {
            this.createSocket();
            hideDefaultModal();
          }}
        />
      );
    }
  }

  protected handleStatusChange(status: WSStatus): void {
    super.handleStatusChange(status);
    this.showToast(status);
  }
}