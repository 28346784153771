import { useMemo } from "react";
import { DisplayResult, DisplayState } from "../../../Types/NewsType";
import { NewsDisplayService } from "./NewsDisplayService";

export const useNewsDisplay = (state: DisplayState): DisplayResult => {
    return useMemo(() => {
        const loadingState: DisplayResult = {
            dataToDisplay: null,
            isCompleteSlice: false
        };

        // Early return if no source filter is applied
        if (state.usingSourceFilter === 'none') {
            return loadingState;
        }

        // Get search results
        const searchResults = NewsDisplayService.getSearchResults(state);
        if (!searchResults) {
            return loadingState;
        }

        // Get slice of data for current page
        const { dataSlice, isSliceFullLength } = NewsDisplayService.getSliceOfData(
            searchResults.results,
            state.currentPageIndex,
            state.itemsPerPage
        );

        // Calculate pagination details
        const { numberOfPages, isOnLastPage } = NewsDisplayService.calculatePaginationDetails(
            searchResults.totalCount,
            state.itemsPerPage,
            state.currentPageIndex
        );

        return {
            dataToDisplay: dataSlice,
            isCompleteSlice: isSliceFullLength || isOnLastPage || (numberOfPages === 0)
        };
    }, [
        state.sourceFilters,
        state.searchInput,
        state.searchResultsDoubleMap,
        state.defaultNewsItems,
        state.currentPageIndex,
        state.totalPagesForDefault,
        state.usingSearchWord,
        state.usingSourceFilter,
        state.category,
        state.itemsPerPage,
    ]);
};