import { BehaviorSubject, Subscription } from "rxjs";
import { MessageHandler } from "./AbstractHandler";
import {
  MessageType,
  OrderMessage,
  SubscriptionCommands,
} from "../../../Types/Websocket";
import { ManageSubscriptionMessage } from "../WebsocketHelpers";

export class ViewOrdersHandler extends MessageHandler<OrderMessage[]> {
  private orders: BehaviorSubject<OrderMessage[]>;

  constructor() {
    super();
    this.orders = new BehaviorSubject<OrderMessage[]>([]);
  }

  handleMessage(message: OrderMessage[]): void {
    // Get the current orders
    const currentOrders = this.orders.value;
    let hasUpdates = false;

    // Iterate over the new orders
    message.forEach((order) => {
      // Check if the order already exists
      const exists = currentOrders.find(
        (currentOrder) => currentOrder.id === order.id // replace 'id' with the actual property to compare
      );

      // Check if the status has changed
      if (exists) {
        if (exists.status !== order.status) {
          currentOrders[currentOrders.indexOf(exists)] = order;
          hasUpdates = true;
        }
      }
      // If the order doesn't exist, add it to the orders
      else {
        currentOrders.push(order);
        hasUpdates = true;
      }
    });

    // Update the orders only if there are updates
    if (hasUpdates) {
      this.orders.next(currentOrders);
    }
  }
  subscribe(callback: (order: OrderMessage[]) => void): Subscription {
    const subscription = this.orders.subscribe(callback);
    return subscription;
  }

  regenerateSubscriptionMessage(): ManageSubscriptionMessage {
    return { type: MessageType.time, command: SubscriptionCommands.Subscribe };
  }
}
