// Package imports:
import React from "react";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { CircularProgress } from "@mui/material";

type ButtonTypes = "primary" | "buy" | "secondary" | "danger";
export type ButtonSizes = "micro" | "xsm" | "sm" | "lg";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;
type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;
interface IOwnProps {
  buttonType?: ButtonTypes;
  size?: ButtonSizes;
  disabled?: boolean;
  anchorProps?: AnchorProps;
  icon?: IconProp;
  showLoader?: boolean;
  hideTextInMobile?: boolean;
  smallInMobile?: boolean;
  noMargin?: boolean;
  fullWidth?: boolean;
}

type Props = IOwnProps & ButtonProps;
const Button: React.FC<Props> = ({
  buttonType = "primary",
  size = "lg",
  disabled = false,
  className,
  children,
  anchorProps,
  icon,
  showLoader = false,
  hideTextInMobile = false,
  smallInMobile = false,
  noMargin = false,
  fullWidth = false,
  ...props
}) => {

  if (anchorProps) {
    return (
      <a
        aria-disabled={disabled || showLoader}
        className={cx(
          "KCL_button",
          buttonType,
          size,
          {
            disabled: disabled || showLoader,
            "with-icon": icon,
            "hide-text-in-mobile": hideTextInMobile,
            "smaller-button-in-mobile": smallInMobile,
            noMargin: noMargin,
            fullWidth: fullWidth,
          },
          className
        )}
        {...anchorProps}
      >
        {icon && <FontAwesomeIcon icon={icon} className="icon-fa" />}
        {children && <span className="text-wrapper">{children}</span>}
      </a>
    );
  }
  return (
    <button
      disabled={disabled || showLoader}
      className={cx(
        "KCL_button",
        buttonType,
        size,
        {
          disabled: disabled || showLoader,
          "with-icon": icon,
          "hide-text-in-mobile": hideTextInMobile,
          "smaller-button-in-mobile": smallInMobile,
          noMargin: noMargin,
          fullWidth: fullWidth,
        },
        className
      )}
      {...props}
    >
      {showLoader ? (
        <>
          <div style={{ height: "0px" }}>
            <CircularProgress size={20} />
          </div>
          <span style={{ visibility: "hidden" }}>{children}</span>
        </>
      ) : (
        <>
          {icon && <FontAwesomeIcon icon={icon} className="icon-fa" />}
          {children && (
            <div className="text-wrapper" style={{ display: "inline-block" }}>
              {children}
            </div>
          )}
        </>
      )}
    </button>
  );
};

export default Button;
