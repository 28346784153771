import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WatchListModal from "../../../Modals/WatchListModal";
import { faGear } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import { eventBus } from "../EventBus";

function OpenWatchListModal() {
    const [watchListModalOpen, setWatchListModalOpen] = React.useState(0);// 0: never opened, 1: closed, 2: open

    React.useEffect(() => {
        if (watchListModalOpen === 1) {
            eventBus.next('triggerRefresh');
        }
    }, [watchListModalOpen]);

    return (
        <div>
            <div style={{ marginLeft: '7px', cursor: 'pointer' }} onClick={() => setWatchListModalOpen(2)}>
                <FontAwesomeIcon icon={faGear} style={{ fontSize: '14px' }} />
            </div>
            <WatchListModal closeModal={() => setWatchListModalOpen(1)} open={watchListModalOpen === 2} />
        </div>
    );
}

export default OpenWatchListModal;