import {
  OmsMessage,
  StreamerError,
  WSStatus,
} from "../../Types/Websocket";
import {
  PDSWebSocketConsoleLogging,
} from "../../Config/Logging";
import { AppStateManager } from "../../StateManager";
import { ViewOrdersHandler } from "./Handlers/ViewOrdersHandler";
import { ViewTradesHandler } from "./Handlers/ViewTradesHandler";
import { LMDInterface } from "../LMD";
import Button from "../../Components/UI-Elements/Button";
import { toast } from "react-toastify";
import { ICONS } from "../../Utils/Definitions";
import { TranslationManager } from "../../Translation/Translation";
import { BaseWebSocket } from "./BaseWebsocket";

export type PDSManagerHandlers = {
  view_orders: ViewOrdersHandler;
  view_trades: ViewTradesHandler;
};

/**
 * PDSManagerRecord class is responsible for managing multiple PDSSockets and their handlers.
 */
export class PDSManagerRecord {
  // A record of PDSSockets, keyed by their URL.
  public PDSsockets: Record<string, PDSSocket>

  // Handlers for different types of messages.
  private handlers: PDSManagerHandlers;

  /**
   * Returns the handler for a given type.
   * @param type - The type of the handler.
   * @returns The handler for the given type.
   */
  public getHandler<T extends keyof PDSManagerHandlers>(
    type: T
  ): PDSManagerHandlers[T] {
    return this.handlers[type];
  }

  /**
   * Constructs a new PDSManagerRecord.
   */
  constructor() {
    this.PDSsockets = {};
    this.handlers = {
      view_orders: new ViewOrdersHandler(),
      view_trades: new ViewTradesHandler(),
    };
  }

  /**
   * Initializes the PDSManagerRecord with a given token.
   * @param token - The token to use for authentication.
   */
  public initial(token: string) {
    AppStateManager.userProfile.access.forEach((connection) => {
      if (connection.type === "private_data") {
        // Test the PDS version.
        const testPDSVersion = LMDInterface.testPDSVersion(connection)

        // If the test returns an error, skip this connection.
        if (testPDSVersion instanceof Error) {
          return;
        }

        // Create a new PDSSocket for this connection and store it in the record.
        const cleanedURl = 'wss://' + new URL(connection.url).host + '/dma/streamer/api';
        this.PDSsockets[cleanedURl] = new PDSSocket(cleanedURl, connection.name, token, this.handlers, this.getHandler.bind(this));
      }
    })
  }
}
export class PDSSocket extends BaseWebSocket<PDSManagerHandlers> {
  protected handleMessage(messageEvent: any): void {
    // Handle array of messages
    if (Array.isArray(messageEvent)) {
      messageEvent.forEach((msg) => this.handleMessage(msg));
      return;
    }

    this.lastHeartBeat.next(new Date());
    const broker = this.name;
    const { message, type, command } = messageEvent;

    if (PDSWebSocketConsoleLogging) {
      console.log("Message from server:", type, message);
    }

    const messageHandlers = {
      error: () => this.handleErrorMessages(message),
      status: () => this.handleStatus(message.status),
      authentication: () => this.handleAuthentication(message),
      oms: () => this.processOmsMessage(message, command, broker)
    };

    const handler = messageHandlers[type];
    if (handler) {
      handler();
    } else {
      console.error("Unhandled message type:", type, message);
    }
  }

  protected handleStatusChange(status: WSStatus): void {
    super.handleStatusChange(status);
    this.toastErrorHandler(status);
  }

  private toastErrorHandler(status: string): void {
    const active = toast.isActive(this.name ?? 1);

    if (status === 'closed') {
      const toastContent = (
        <span>
          {`Streamer ${this.name} has been closed`}
          <Button
            buttonType="secondary"
            size="sm"
            onClick={() => this.disconnect()}
          >
            Reconnect
          </Button>
        </span>
      );

      if (!active) {
        toast.error(toastContent, {
          toastId: this.name,
          closeButton: true,
          autoClose: false,
          closeOnClick: false,
          icon: ICONS.error
        });
      } else {
        toast.update(this.name, {
          render: toastContent,
          type: "error",
          closeButton: true,
          autoClose: false,
          closeOnClick: false,
          icon: ICONS.error
        });
      }
    } else if (status === 'connecting') {
      toast.update(this.name, {
        icon: ICONS.reconnecting,
        render: `${TranslationManager.getTranslation().ToastMessage.Reconnecting_to} ${this.name}`,
        autoClose: false,
        type: "warning",
      });
    } else if (status === 'connected') {
      if (active) {
        toast.update(this.name, {
          render: `${TranslationManager.getTranslation().ToastMessage.Connected_to} ${this.name}`,
          autoClose: 2000,
          hideProgressBar: true,
          icon: ICONS.success,
          type: "success",
        });
      }
    }
  }

  protected handleErrorMessages(error: { reason: string; status: string }): void {
    if (error.reason === StreamerError.MarketFeedNotOnline || error.status === "offline") {
      this.closeSocket();
    } else {
      this.disconnect();
    }
  }

  private processOmsMessage(message: OmsMessage | OmsMessage[], messageCommand?: 'order' | 'trade', broker?: string): void {
    if (!messageCommand) return;

    const messages = Array.isArray(message) ? message : [message];
    const handlers = {
      order: (msg: any) => this.getHandler("view_orders").handleMessage([{ ...msg, broker }]),
      trade: (msg: any) => this.getHandler("view_trades").handleMessage([{ ...msg, broker }])
    };

    messages.forEach(msg => {
      const handler = handlers[messageCommand];
      if (handler) handler(msg);
    });
  }

  public disconnect(): void {
    const socket = this.socket.value();
    if (socket) {
      socket.onclose = () => { };
      socket.close();
      this.retryTimer.active = true;
      this.status.next('disconnected');
    }
  }

  protected handleStatus(status: "offline" | "online" | "catchup"): void {
    if (status === "online") {
      this.error = null;
      this.status.next("connected");
    } else if (status === "offline") {
      this.error = "Server is offline";
      this.closeSocket();
    } else if (status === "catchup") {
      this.error = "Server is in catchup";
      this.status.next("connecting");
    }
  }

  protected createAuthenticationMessage(): any {
    return {
      type: 'authentication',
      command: "logon",
      message: { token: this.token }
    };
  }

  protected handleMultipleSessions(): void {
    console.log('Multiple sessions detected');
    // In PDS, we just log the multiple sessions rather than showing a modal
  }
}