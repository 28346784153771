import { Fetched } from "../Types/Common";
import { INewsFeedSourceFilter, INewsLmdNewsItem, NewsItemFeedIdMapToBoolean } from "../Types/NewsType";

const DELIMITER = ';'

export const getSourceStringFromSourceList = (sourceFilters: Fetched<INewsFeedSourceFilter[]>, which: 'on' | 'off') => {
    if (sourceFilters === null || sourceFilters instanceof Error) return null;
    const filteredSources = sourceFilters.filter(filter => (which === 'on' ? filter.isOn : !filter.isOn));
    if (filteredSources.length === 0) return null;
    const seenSymbols = new Map();
    const filteredSourceTitles = filteredSources
        .map(sourceFilter => sourceFilter.newsFeedSource.feed.feedSymbol)
        .filter(symbol => {
            if (seenSymbols.has(symbol)) {
                return false;
            }
            seenSymbols.set(symbol, true);
            return true;
        });
    //for some reason SVN has to be first
    const svnIndex = filteredSourceTitles.findIndex(title => title === 'SVN');
    if (svnIndex > -1) {
        const [svnTitle] = filteredSourceTitles.splice(svnIndex, 1);
        filteredSourceTitles.unshift(svnTitle);
    }
    const sourceFilterString = filteredSourceTitles.join(DELIMITER);
    return sourceFilterString;
}

export const getSourceListFromSourceString = (sourceFilterString: string) => {
    return sourceFilterString.split(DELIMITER);
}

export function getNewsDateOrTime(date: Date): string {
    const now = new Date();
    const isCurrentYear = (date.getFullYear() === now.getFullYear());
    // If today, use time
    if (
        date.getDate() === now.getDate()
        && date.getMonth() === now.getMonth()
        && isCurrentYear
    ) {
        return (((date.getHours()).toString()).padStart(2, '0')
            + ':'
            + ((date.getMinutes()).toString()).padStart(2, '0')
        );
    }
    // If in past, use date
    else if (isCurrentYear) {
        return (
            ((date.getDate()).toString()).padStart(2, '0')
            + '.'
            + ((date.getMonth() + 1).toString()).padStart(2, '0')
            + '.'
        );
    } else {
        return (
            ((date.getDate()).toString()).padStart(2, '0')
            + '.'
            + ((date.getMonth() + 1).toString()).padStart(2, '0')
            + '.'
            + ((date.getFullYear()).toString()).slice(2)
        );
    }
}

export const combineOldAndNewNews = (newNewsItems: INewsLmdNewsItem[], oldNews: INewsLmdNewsItem[], sources: string[] | null) => {
    const newNewsItemsForSources = (sources === null)
        ? newNewsItems
        : newNewsItems.filter(newsItem => sources.includes(newsItem.source ?? ''));

    const firstOldNews = oldNews[0];
    let indexWhereOldNewsBegins = newNewsItemsForSources.findIndex(newsItem => (
        (
            newsItem.id !== null
            && newsItem.id === firstOldNews.id
        )
        || (
            newsItem.publish_timestamp !== null
            && firstOldNews.publish_timestamp !== null
            && newsItem.publish_timestamp < firstOldNews.publish_timestamp
        )
    ));

    const addedNews = newNewsItems.slice(0, indexWhereOldNewsBegins);
    const combinedNews = [...addedNews, ...oldNews];

    // Remove duplicates
    const combinedNewsWithoutDuplicates: INewsLmdNewsItem[] = [];
    const hasNewsItemBeenAdded: { [feedId in string]?: boolean } = {};
    for (const newsItem of combinedNews) {
        if (newsItem.id === null) continue;
        if (hasNewsItemBeenAdded[newsItem.id]) continue;
        combinedNewsWithoutDuplicates.push(newsItem);
        hasNewsItemBeenAdded[newsItem.id] = true;
    }

    return {
        combinedNews: combinedNewsWithoutDuplicates,
        addedNews
    };
};

export const updateNewsMaps = (
    newsThatGetNotification: INewsLmdNewsItem[],
    newsItemFeedIdToIsNewMap: NewsItemFeedIdMapToBoolean,
    newsItemFeedIdToIsHighlightedMap: NewsItemFeedIdMapToBoolean,
    setNewsItemFeedIdToIsNewMap: (map: NewsItemFeedIdMapToBoolean) => void,
    setNewsItemFeedIdToIsHighlightedMap: (map: NewsItemFeedIdMapToBoolean) => void
) => {
    const newNewsItemsToTrueMap: NewsItemFeedIdMapToBoolean = {};
    const newNewsItemsToFalseMap: NewsItemFeedIdMapToBoolean = {};
    newsThatGetNotification.forEach(newsItem => {
        const { id } = newsItem;
        if (id) {
            newNewsItemsToTrueMap[id] = true;
            newNewsItemsToFalseMap[id] = false;
        }
    });

    const newNewsItemFeedIdToIsNewMap = {
        ...newsItemFeedIdToIsNewMap,
        ...newNewsItemsToTrueMap
    };
    const newNewsItemFeedIdToIsHighlightedMap = {
        ...newsItemFeedIdToIsHighlightedMap,
        ...newNewsItemsToTrueMap
    };

    setNewsItemFeedIdToIsNewMap(newNewsItemFeedIdToIsNewMap);
    setNewsItemFeedIdToIsHighlightedMap(newNewsItemFeedIdToIsHighlightedMap);

    window.setTimeout(() => {
        setNewsItemFeedIdToIsHighlightedMap({
            ...newsItemFeedIdToIsHighlightedMap,
            ...newNewsItemsToFalseMap
        });
    }, 15 * 1000 /* 15 seconds */);

    window.setTimeout(() => {
        setNewsItemFeedIdToIsNewMap({
            ...newsItemFeedIdToIsNewMap,
            ...newNewsItemsToFalseMap
        });
    }, 15 * 60 * 1000 /* 15 minutes */);
};

export const newsTypeMap: {
    [T in string]: string
} = {
    'news': 'Frétt',
    'press_release': 'Fréttatilkynning',
    'exchange_notice': 'Kauphallartilkynning',
    'podcast': 'Hlaðvarpsþáttur',
    'article': 'Grein'
}