import { INewsFeedSource, INewsFeedSourceFilter, INewsFeedSourcesResponse } from "../../../../Types/NewsType";
import { sortIcelandic } from "../../../../Utils/Common";

const NEWS_API_BASE_URL = 'https://news-search.livemarketdata.com';

export class NewsSourcesService {
    static async fetchSources(category: string): Promise<INewsFeedSource[]> {
        const response = await fetch(`${NEWS_API_BASE_URL}/api/feeds?topics=${category}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('RequestFailed');
        }
        const body: INewsFeedSourcesResponse = await response.json();

        return body.feeds;
    }

    static filterSourcesByCategory(
        sources: INewsFeedSource[],
        category: string
    ): INewsFeedSource[] {
        let sourcesForCategory = sources.filter(source =>
            category === source.topics[0].topic || category === source.topics[1].topic
        );

        if (sourcesForCategory.length === 0) {
            sourcesForCategory = sources.map(item => ({ ...item, isOn: true }));
        }

        return sources.sort((source1, source2) =>
            sortIcelandic(source1.feed.feedDisplayName, source2.feed.feedDisplayName)
        );
    }

    static mapToSourceFilters(
        sourcesForCategory: INewsFeedSource[],
        initialSources: Record<string, INewsFeedSourceFilter[]> | undefined,
        category: string
    ): INewsFeedSourceFilter[] {
        const categorySources = initialSources?.[category] || [];
        return sourcesForCategory.map(newsFeedSource => {
            const existingSource = categorySources.find(
                existingSource =>
                    existingSource.newsFeedSource.feed.feedKey === newsFeedSource.feed.feedKey
            );

            return {
                newsFeedSource,
                isOn: existingSource ? existingSource.isOn : true
            };
        });
    }
}

export const useNewsSources = (
    category: string,
    initialSources: Record<string, INewsFeedSourceFilter[]> | undefined,
    saveSourceState: (sources: INewsFeedSourceFilter[]) => void,
    setSourceFilters: (sources: INewsFeedSourceFilter[] | Error) => void
) => {
    const fetchAndProcessSources = async () => {
        try {
            const sources = await NewsSourcesService.fetchSources(category);
            const filteredSources = NewsSourcesService.filterSourcesByCategory(sources, category);
            const sourceFilters = NewsSourcesService.mapToSourceFilters(
                filteredSources,
                initialSources,
                category
            );

            saveSourceState(sourceFilters);
            setSourceFilters(sourceFilters);
        } catch (e) {
            const error = e instanceof Error ? e : new Error('NetworkError');
            setSourceFilters(error);
        }
    };

    return { fetchAndProcessSources };
};