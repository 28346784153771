// Windows
import { TradeTicker } from "./TradeTicker/TradeTicker";
import { MarketDepth } from "./MarketDepth/MarketDepth";
import { Chart } from "./Chart/Chart";
import { WatchList } from "./WatchList/WatchList";
import { News } from "./News/News";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { AppTheme } from "../../Config/MUITheme";
import { createRoot } from "react-dom/client";

import GoldenLayout from "golden-layout";
import {
  ComponentName,
  ComponentNameList,
  TradingComponentNameList,
} from "../../Types/Windows";

import { Component } from "react";
import { getGlobalWSM } from "./WorkspaceManager";
import {
  ContentItem,
  GLCoreEmptyLayout,
  GLLayoutConfig,
} from "../../Types/GoldenLayout";
import { TranslationManager } from "../../Translation/Translation";
import { ViewOrders } from "./ViewOrders/ViewOrders";
import { PriceLadder } from "./PriceLadder/PriceLadder";
import { ViewTrades } from "./ViewTrades/ViewTrades";
import { RetailTrading } from "./RetailTrading/RetailTrading";
import { BondsCalculator } from "./BondsCalculator/BondsCalculator";

function newComponent(componentName: ComponentName, componentState?: any) {
  if (componentState === undefined)
    componentState = (
      {
        MarketDepth: { symbol: undefined, toggleAlignment: "market_by_level" },
        //'WatchList': { selectedList: undefined },
        TradeTicker: { selectedList: undefined },
        Chart: { symbol: undefined },
        // Stupid autocomplete bug behaves weirdly if we start with undefined
        WatchList: { list: { count: 0, name: '', symbol: '', country: '' } },
        News: { symbol: undefined },
        PriceLadder: { symbol: undefined },
        BondsCalculator: { symbol: undefined },
        //trading components
        ViewOrders: { symbol: undefined },
        ViewTrades: { symbol: undefined },
        BulkOrderShift: { symbol: undefined },

        //retail trading
        RetailTrading: { symbol: undefined },
      } satisfies Record<ComponentName, any>
    )[componentName];

  return {
    type: "component",
    componentName,
    componentState,
    title: TranslationManager.getTranslation().ComponentName[componentName],
  };
}

function putCompInStack(comp) {
  return {
    activeItemIndex: 0,
    content: [comp],
    isClosable: true,
    reorderEnabled: GLCoreEmptyLayout.settings.reorderEnabled,
    title: "",
    type: "stack",
  };
}

function sanitizeContent(content: ContentItem[]): ContentItem[] {
  return content
    .map((contentItem) => {
      const common = {
        isClosable: contentItem.isClosable,
        reorderEnabled: contentItem.reorderEnabled,
      };
      if (contentItem.content !== undefined)
        common["content"] = sanitizeContent(contentItem.content);
      if (contentItem.type === "component") {
        return {
          type: "component",
          componentName: contentItem.componentName,
          componentState: contentItem.componentState,
          title:
            TranslationManager.getTranslation().ComponentName[
            contentItem.componentName
            ],
          ...common,
        };
      } else if (contentItem.type === "stack") {
        const { height, width } = contentItem;
        return {
          type: "stack",
          activeItemIndex: Math.min(
            contentItem.activeItemIndex,
            (contentItem.content?.length ?? 0) - 1
          ), // This is some save bug in GL
          height,
          width,
          ...common,
        };
      } else if (["row", "column"].includes(contentItem.type)) {
        const { height, width } = contentItem;
        return {
          type: contentItem.type,
          height,
          width,
          ...common,
        } as ContentItem;
      }
    })
    .filter(
      (contentItem): contentItem is ContentItem => contentItem !== undefined
    );
}

function layoutConfigFromContent(content: ContentItem[]): GLLayoutConfig {
  return { ...GLCoreEmptyLayout, content };
}

export class GLWindowManager {
  private static layout: GoldenLayout;
  private static GLWMContainer: HTMLElement;

  // Allow disable save when tearing down windows preventing
  // Save of empty GLWindowManager
  private static saveState: boolean = false;

  static readonly draggableAddElements: Record<ComponentName, HTMLElement> = {
    MarketDepth: document.createElement("div"),
    TradeTicker: document.createElement("div"),
    WatchList: document.createElement("div"),
    Chart: document.createElement("div"),
    News: document.createElement("div"),
    PriceLadder: document.createElement("div"),
    BondsCalculator: document.createElement("div"),

    //trading components
    ViewOrders: document.createElement("div"),
    ViewTrades: document.createElement("div"),
    BulkOrderShift: document.createElement("div"),

    //retail trading
    RetailTrading: document.createElement("div"),
  };

  // Should only be called once
  static init(parentElement: HTMLElement) {
    GLWindowManager.GLWMContainer = parentElement; //document.getElementById('GLWMContainer') as HTMLElement;

    // If there exists stored layout use that, else the default
    const content = getGlobalWSM().getSession().content;
    const config = layoutConfigFromContent(sanitizeContent(content));

    GLWindowManager.setLayoutInstance(config);

    // Resize GL when window is resized
    window.addEventListener("resize", () => GLWindowManager.resize());
    // if retail trading window is still open we need to remove it
    this.removeComponentFromRoot("RetailTrading");
  }

  static resize() {
    const { width } = GLWindowManager.GLWMContainer.getBoundingClientRect();
    GLWindowManager.layout.updateSize(width, window.innerHeight - 90);
  }

  static destroy() {
    GLWindowManager.saveState = false;
    GLWindowManager.layout.destroy();
  }

  static reset() {
    GLWindowManager.destroy();
    getGlobalWSM().reset();
    const content = getGlobalWSM().getSession().content;
    GLWindowManager.setLayoutInstance(
      layoutConfigFromContent(sanitizeContent(content))
    );
  }

  static getContent(): ContentItem[] {
    const content = sanitizeContent(GLWindowManager.layout.toConfig().content);
    return content;
  }

  // Save file management
  static saveAs(name: string) {
    const content = GLWindowManager.getContent();
    getGlobalWSM().saveWorkspace(name, content);
  }
  // Save file management
  static saveImportedWS(name: string, content: ContentItem[]) {
    getGlobalWSM().saveWorkspace(name, content);
  }

  static getWorkspacenames(): string[] {
    return getGlobalWSM()
      .getWorkspaces()
      .map((workspace) => workspace.name);
  }

  static loadWorkspace(name: string) {
    const workspace = getGlobalWSM().getWorkspace(name);
    if (workspace !== undefined) {
      const content = sanitizeContent(workspace.content);
      const config = layoutConfigFromContent(content);
      GLWindowManager.destroy();
      GLWindowManager.setLayoutInstance(config);
      getGlobalWSM().saveSession(content);
    } else {
      throw new Error(`Workspace name ${name} does not exist`);
    }
  }

  private static setLayoutInstance(config) {
    GLWindowManager.layout = new GoldenLayout(
      config,
      GLWindowManager.GLWMContainer
    );
    (
      [
        { componentName: "TradeTicker", WindowComponent: TradeTicker },
        { componentName: "WatchList", WindowComponent: WatchList },
        { componentName: "MarketDepth", WindowComponent: MarketDepth },
        { componentName: "Chart", WindowComponent: Chart },
        { componentName: "News", WindowComponent: News },
        { componentName: "PriceLadder", WindowComponent: PriceLadder },
        { componentName: "BondsCalculator", WindowComponent: BondsCalculator },

        //trading components
        { componentName: "ViewOrders", WindowComponent: ViewOrders },
        { componentName: "ViewTrades", WindowComponent: ViewTrades },
        { componentName: "BulkOrderShift", WindowComponent: TradeTicker },

        // retail trading components
        { componentName: "RetailTrading", WindowComponent: RetailTrading },
        // Add more components here
      ] satisfies {
        componentName: ComponentName;
        WindowComponent: typeof Component;
      }[]
    ).forEach(({ componentName, WindowComponent }) => {
      GLWindowManager.layout.registerComponent(
        componentName,
        function (container, state) {
          const element = container.getElement().get(0);
          const root = createRoot(element);

          // Component-specific resize observer
          const componentResizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
              const { width, height } = entry.contentRect;
              // Render with updated dimensions
              root.render(
                <ThemeProvider theme={AppTheme}>
                  <CssBaseline />
                  <WindowComponent
                    state={state}
                    GLSaveState={(newState) => container.setState(newState)}
                    containerWidth={width}
                    containerHeight={height}
                  />
                </ThemeProvider>
              );
            }
          });

          // Initial render
          root.render(
            <ThemeProvider theme={AppTheme}>
              <CssBaseline />
              <WindowComponent
                state={state}
                GLSaveState={(newState) => container.setState(newState)}
              />
            </ThemeProvider>
          );

          // Observe the container element
          if (componentName === "BondsCalculator") componentResizeObserver.observe(element);

          // Cleanup on container destroy
          container.on("destroy", () => {
            root.unmount();
            if (componentName === "BondsCalculator") componentResizeObserver.disconnect();
          });
        }
      );
    });

    // This is a special solution because of GL library and version I'm using
    ComponentNameList.forEach((componentName) => {
      const div = GLWindowManager.draggableAddElements[componentName];
      div.textContent =
        TranslationManager.getTranslation().ComponentName[componentName];

      const component = newComponent(componentName);
      GLWindowManager.layout.createDragSource(div, component);
    });

    TradingComponentNameList.forEach((componentName) => {
      const div = GLWindowManager.draggableAddElements[componentName];
      div.textContent =
        TranslationManager.getTranslation().ComponentName[componentName];

      const component = newComponent(componentName);
      GLWindowManager.layout.createDragSource(div, component);
    });

    // Save state on change
    GLWindowManager.saveState = true;
    GLWindowManager.layout.on("stateChanged", function () {
      if (GLWindowManager.saveState) {
        const content = GLWindowManager.getContent();
        getGlobalWSM().saveSession(content);
      }
    });

    GLWindowManager.layout.init();
  }

  static removeComponentFromRoot(componentName: ComponentName) {
    const allComponents = GLWindowManager.layout.root.getItemsByType('component');

    allComponents.forEach((component) => {
      if ((component.config as any).componentName === componentName) {
        (component as any).parent.removeChild(component); // Remove the component from its parent
      }
    });

    // If the root content is empty, clear the entire layout
    if (GLWindowManager.layout.root.contentItems.length === 0) {
      GLWindowManager.layout.root.contentItems = [];
    }

    GLWindowManager.layout.updateSize();
  }

  static addComponentToRoot(componentName: ComponentName, side: "left" | "right" | "top" | "bottom" = 'left', componentState?: any) {
    const config = GLWindowManager.layout.toConfig();
    const content = config.content;
    const newComp = putCompInStack(newComponent(componentName, componentState));

    if (content.length === 0) {
      content.push(newComp);
    } else {
      const oldRoot = content[0];
      let newType = "row" as "column" | "row"; // Sometimes I hate typescript
      let newContent: any[] = [newComp, oldRoot];

      if (side === 'right') { newContent = [oldRoot, newComp]; }
      else if (side === 'top') { newType = "column"; }
      else if (side === 'bottom') { newContent = [oldRoot, newComp]; newType = "column"; }
      content[0] = {
        content: newContent,
        isClosable: true,
        reorderEnabled: true,
        title: "",
        type: newType,
      };

      const split = side === 'right' || side === 'bottom' ? [70, 30] : [30, 70];
      if (newType === "column") {
        content[0].content[0].height = split[0];
        content[0].content[1].height = split[1];
      } else {
        content[0].content[0].width = split[0];
        content[0].content[1].width = split[1];
      }
    }

    GLWindowManager.destroy();
    GLWindowManager.setLayoutInstance(config);
  }
}
