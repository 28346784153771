import { IBondCalculation } from "../../../KodiInterface/LMD";
import { formatNumber } from "../../../Utils/Formatting";

// Utility functions
const formatAmortizationType = (type: string | undefined): string => {
    if (!type) return '';
    else if (type === 'equal_inst_zero_coupon_hybrid') return 'Equal Installments Balloon';
    else if (type === 'annuity_zero_coupon_hybrid') return 'Annuity Balloon';
    return type.replaceAll('_', ' ').replace(/^\w/, c => c.toUpperCase());
};

const formatSimpleString = (str: string | undefined): string => {
    if (!str) return '';
    return str.replaceAll('_', ' ');
};

// Calculation utilities
const calculatePriceAdjustment = (principal: number | undefined, remainingPrincipal: number | undefined): number => {
    return ((principal ?? 1) / 10000) * (remainingPrincipal ?? 1);
};

const calculateAccruedInterest = (
    calculation: IBondCalculation | undefined,
    priceAdjustment: number
): string => {
    if (!calculation?.accrued_interest) return '';
    return formatNumber(Math.max(calculation.accrued_interest * priceAdjustment, 0), 0);
};

const calculateAccruedIndex = (
    calculation: IBondCalculation | undefined,
    priceAdjustment: number
): string => {
    if (!calculation?.index_adjustment || !calculation.remaining_principal) return '';
    const value = ((calculation.index_adjustment * calculation.remaining_principal) / 100) * priceAdjustment;
    return formatNumber(Math.max(value, 0), 0);
};

const calculateTotal = (
    calculation: IBondCalculation | undefined,
    priceAdjustment: number
): string => {
    if (!calculation?.principal_with_interest_and_index) return '';
    return formatNumber(Math.max(calculation.principal_with_interest_and_index * priceAdjustment, 0), 0);
};

const calculateDiscount = (
    calculation: IBondCalculation | undefined,
    priceAdjustment: number
): string => {
    if (!calculation?.principal_with_interest_and_index || !calculation.dirty_price) return '';
    return formatNumber(
        (calculation.principal_with_interest_and_index - calculation.dirty_price) * priceAdjustment,
        0
    );
};

export {
    formatAmortizationType,
    formatSimpleString,
    calculatePriceAdjustment,
    calculateAccruedInterest,
    calculateAccruedIndex,
    calculateTotal,
    calculateDiscount
};