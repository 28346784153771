import React from 'react';
import { Subscription } from 'rxjs/internal/Subscription';
import { ListInfo } from "../../../Types/LMDTypes";
import { SymbolListWindowComponent } from "../AbstractWindow";
import { WatchListTable } from "./WatchListTable";
import { LMDInterface } from '../../../KodiInterface/LMD';
import { GridEventListener } from '@mui/x-data-grid-pro';

import { eventBus } from './EventBus';
import ListAutocomplete from './Helpers/ListAutocomplete';
import OpenWatchListModal from './Helpers/OpenWatchListModal';

type TableState = any;
type SaveState = {
    tableState: TableState;
};

type RunState = {
    tableParentRef: React.RefObject<HTMLDivElement>;
    lists: ListInfo[];
}
export class WatchList extends SymbolListWindowComponent<RunState, SaveState> {
    subscription: Subscription;
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            tableParentRef: React.createRef<HTMLDivElement>(),
            lists: []
        };
        this.subscription = new Subscription();
    }

    componentDidMount(): void {
        LMDInterface.getAllLists().then(lists => {
            this.setState({ lists });
            if (this.state.list) {
                const list = lists.find(list => list.symbol === this.state.list?.symbol);
                if (!list) {
                    // If we set list to undefined it creates weird behaviour in autocomplete component
                    // So we set it to an empty instance of ListInfo
                    this.setState({ list: { count: 0, name: '', symbol: '', country: '' } });
                }
            }
        });
        this.subscription = eventBus.subscribe((event) => {
            if (event === 'triggerRefresh') {
                this.refreshList();
            }
        });
    }

    componentWillUnmount(): void {
        this.subscription.unsubscribe();
    }

    componentDidUpdate(prevProps, prevState, snapshot?: any): void {
        // Save state on each change
        this.saveState({ linked: this.state.linked, tableState: this.state.tableState, list: this.state.list });
    }

    refreshList = () => {
        setTimeout(() => {
            LMDInterface.getAllLists().then(lists => {
                this.setState({ lists });
                if (this.state.list) {
                    const list = lists.find(list => list.symbol === this.state.list?.symbol);
                    if (!list) {
                        // If we set list to undefined it creates weird behaviour in autocomplete component
                        // So we set it to an empty instance of ListInfo
                        this.setState({ list: { count: 0, name: '', symbol: '', country: '' } });
                    } else if (list.country === 'My Lists') {
                        // Causes a state update so that watchlist table refreshes
                        this.setState({ list });
                    }
                }
            });
        }, 1000);
    };

    render() {
        const onRowClick: GridEventListener<'rowClick'> = (params) => {
            const row = params.row;
            const symbol = row.symbol;
            this.selectedSymbol(symbol);
        };


        return (
            <div className='KM_watch_list window' ref={this.state.tableParentRef}>
                <div className='fill' style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', maxWidth: '260px', width: '100%' }}>
                        <ListAutocomplete
                            lists={this.state.lists}
                            value={this.state.list}
                            onChange={(listInfo) => this.setState({ list: listInfo })}
                        />
                        <OpenWatchListModal />
                    </div>
                    <div>{this.linkedButton()}</div>
                </div>

                <WatchListTable
                    list={this.state.list}
                    rowsSelectable={this.state.linked}
                    onRowClick={onRowClick}
                    tableState={this.state.tableState}
                    saveState={(state) => this.setState({ tableState: state })}
                />
            </div>
        );
    }
}

