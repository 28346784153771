// Package imports:
import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
// Component imports:
import NewsItem from './NewsItem';
// Service imports:
import { usePageCounter, getNumberOfPages } from '../../../Utils/Hooks';
import { updateDoubleMapValue } from '../../../Utils/Common';
import { getSourceStringFromSourceList } from '../../../Utils/NewsUtils';
// Type imports:
import {
    INewsLmdNewsItem,
    INewsLmdResponse,
    INewsFeedCategoryDetails,
    INewsFeedSourceFilter,
    NewsItemFeedIdMapToBoolean,
    SearchInputToSourceFilterToResultsDoubleMap,
    SearchResultsInfo
} from '../../../Types/NewsType';
import { CircularProgress, MenuItem, Select } from '@mui/material';
import Filter from './Filter';
import { Fetched } from '../../../Types/Common';
import { CategoryKeys } from './News';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { useNewsSources } from './Services/NewsSourceService';
import { useNewsRefresh } from './UseNewsRefresh';
import { useNewsSearch } from './Services/NewsSearchService';
import { useNewsDisplay } from './UseNewsDisplay';

const INITIAL_REFRESH_RATE_NEWS_ITEMS_TO_FETCH = 20;
const NEWS_ITEMS_FETCH_ON_INDEX_CHANGE = 100;

const MINIMUM_LETTER_SEARCH = 3;

type UsingSourceFilter = 'all' | 'some' | 'none'

interface IOwnProps {
    // setHasNew(hasNewNewsItems: boolean): void,
    newsFeedCategoryDetails: INewsFeedCategoryDetails,

}
export type SaveSourceState = {
    [K in CategoryKeys]: INewsFeedSourceFilter[];
};
type Props = IOwnProps & {
    refreshRateMs: number,
    searchInput: string,
    saveSourceState: (state: INewsFeedSourceFilter[]) => void,
    initialSources: SaveSourceState | undefined,
};

/**
 * HINT: Use ctrl+shift+p: > "Fold Level 2", if you're starting out here.
 *
 * At first the initial news batch is fetched.
 * The newsitems that are displayed for the user are stored in the defaultNewsItems state variable.
 * Then, every [refreshRateMs] milliseconds, More news are fetched.
 * - Since we don't know how many new newsItems have been added, we start by fetching [INTIAL_REFRESH_RATE_NEWS_ITEMS_TO_FETCH].
 * - Then we double this number until the new news batch includes the first newsitem of the already-fetched news.
 * The new news should only be added to the defaultNewsItems if the user is on the first page.
 * - If the user is scrolling through old news, we don't want the list to shift around due to the new arrivals.
 * - If the user is on an old page, the news news items are added to the state variable newNewsWaitingQueue.
 * When the user is going through old news, we requested by searching from the timestamp of the oldest fetched news (last in the defaultNewsItems array)
 * - We fetch [NEWS_ITEMS_FETCH_ON_INDEX_CHANGE] of news and append it to the end of defaultNewsItems.
 *
 *
 * Additionally we have maps mapping the newsId to boolean, for if any given news item is "new" or "read".
 * - Default is: not new, and not read.
 *
 * Since the state can be confusing, all state variables have been commented with their purpose.
 */
const NewsContainer: React.FC<Props> = ({
    // setHasNew,
    newsFeedCategoryDetails,
    refreshRateMs,
    searchInput = "",
    saveSourceState,
    initialSources
}) => {
    /* News Storage States: */
    // The list of "default" news items that the user can see.
    // "Default" meaning non-filtered, non-searched news. Simply the most recent news.
    const [defaultNewsItems, setDefaultNewsItems] = useState<INewsLmdNewsItem[] | null>(null);
    // When the user is:
    // - not filtering by source AND
    // - not searching by word AND
    // - on page 1.
    // Then new news should automatically be added to defaultNewsItems.
    // HOWEVER,
    // When the user does NOT meet those conditions, we must store the new news items in the newNewsWaitingQueue.
    // This list gets added to the front of defaultNewsItems when the user meets ALL coniditions again.
    const [defaultNewsItemsWaitingQueue, setDefaultNewsItemsWaitingQueue] = useState<INewsLmdNewsItem[]>([]);
    // Search word results map:
    // When user filters by source only, and not by word:
    // - then the first key (the word) is "" (the empty string).
    // When the user filters by word only, and not by source:
    // -> then the second key (sourceFilterString) is "" (the empty string).
    // When the user filters by both, then use both keys: searchResultsDoubleMap?.[searchInput]?.[sourceFilterString];
    const [searchResultsDoubleMap, setSearchResultsDoubleMap] = useState<SearchInputToSourceFilterToResultsDoubleMap>({});

    /* News Filtering States: */
    // Sources used to filter by.
    const [sourceFilters, setSourceFilters] = useState<Fetched<INewsFeedSourceFilter[]>>(null);
    // Search word
    // const [searchInput, setSearchInput] = useState('');

    /* News to Boolean maps: */
    // IsSeen news = news that has been clicked on/opened
    const [newsItemFeedIdToIsSeenMap, setNewsItemFeedIdToIsSeenMap] = useState<NewsItemFeedIdMapToBoolean>({});
    // IsNew refers to the yellow "bolla" next to the news item.
    const [newsItemFeedIdToIsNewMap, setNewsItemFeedIdToIsNewMap] = useState<NewsItemFeedIdMapToBoolean>({});
    // IsHighlighted refers to the yellow highlighted hue that very new news has.
    const [newsItemFeedIdToIsHighlightedMap, setNewsItemFeedIdToIsHighlightedMap] = useState<NewsItemFeedIdMapToBoolean>({});

    /* Display helper states: */
    const [lastDisplayableData, setLastDisplayableData] = useState<INewsLmdNewsItem[] | null>(null);

    const [ITEMS_TO_DISPLAY_PER_PAGE, setQuantityPerPage] = React.useState<number>(50)

    /* Overhead News States: */
    // Page index variables.
    const {
        currentPageIndex,
        totalPages: totalPagesForDefault, // Renamed. When searching, the total pages are fetched from the Double Map.
        goToNextPage,
        goToPreviousPage,
        setTotalPages: setTotalPagesForDefault, // Renamed. When searching, the total pages are fetched from the Double Map.
        resetPageCounter
    } = usePageCounter();
    // Any error if it would occur
    const [newsError, setNewsError] = useState<Error | null>(null);

    /* MEMO */
    const usingSourceFilter: UsingSourceFilter = useMemo(() => {
        if (sourceFilters === null || sourceFilters instanceof Error) return 'all';
        if (sourceFilters.every(sourceFilter => !sourceFilter.isOn)) return 'none';
        if (sourceFilters.every(sourceFilter => sourceFilter.isOn)) return 'all';
        return 'some';
    }, [sourceFilters]);

    const usingSearchWord = useMemo(() => {
        return searchInput.length > MINIMUM_LETTER_SEARCH
    }, [searchInput]);

    const usingDefaultNews = useMemo(() => {
        return (!usingSearchWord && usingSourceFilter === 'all');
    }, [usingSearchWord, usingSourceFilter]);

    const { dataToDisplay, isCompleteSlice } = useNewsDisplay({
        sourceFilters,
        searchInput,
        searchResultsDoubleMap,
        defaultNewsItems,
        currentPageIndex,
        totalPagesForDefault,
        usingSearchWord,
        usingSourceFilter,
        category: newsFeedCategoryDetails.category,
        itemsPerPage: ITEMS_TO_DISPLAY_PER_PAGE
    });

    const totalPages = useMemo(() => {
        const loadingState = '...';
        if (usingSourceFilter === 'none') return loadingState;

        if (usingDefaultNews) return totalPagesForDefault ?? loadingState;

        const sourceFilterString = (usingSourceFilter === 'all')
            ? `${newsFeedCategoryDetails.category}`
            : getSourceStringFromSourceList(sourceFilters, 'on');
        if (sourceFilterString === null) return loadingState;
        const searchResults = searchResultsDoubleMap?.[searchInput]?.[sourceFilterString];
        if (searchResults === undefined) return loadingState;
        return getNumberOfPages(searchResults.totalCount, ITEMS_TO_DISPLAY_PER_PAGE);
    }, [usingDefaultNews, searchResultsDoubleMap, searchInput, sourceFilters, totalPagesForDefault, usingSourceFilter, ITEMS_TO_DISPLAY_PER_PAGE]);

    /* EFFECTS AND FUNCTIONS */
    // Update lastDisplayableData to last viable data.
    useEffect(() => { if (dataToDisplay !== null) setLastDisplayableData(dataToDisplay) }, [dataToDisplay]);

    // Reset page counter on search input change
    useEffect(() => {
        if (currentPageIndex !== 0) resetPageCounter();
    }, [searchInput, sourceFilters]);

    // Update defaultNewsItems and newNewsWaitingQueue when the user returns to the default state.
    useEffect(() => {
        if (defaultNewsItemsWaitingQueue.length > 0 && usingDefaultNews && currentPageIndex === 0 && Array.isArray(defaultNewsItems)) {
            setDefaultNewsItems([...defaultNewsItemsWaitingQueue, ...defaultNewsItems]);
            setDefaultNewsItemsWaitingQueue([]);
        }
    }, [usingDefaultNews, currentPageIndex])

    // Update boolean maps when opening news item.
    const newsItemOnOpen = (newsItem: INewsLmdNewsItem) => {
        const { id } = newsItem;
        if (id === null) return;
        // Set to "not new" if new
        setNewsItemFeedIdToIsNewMap({
            ...newsItemFeedIdToIsNewMap,
            [id]: false
        })
        setNewsItemFeedIdToIsHighlightedMap({
            ...newsItemFeedIdToIsHighlightedMap,
            [id]: false
        })
        // Set to seen if not seen
        setNewsItemFeedIdToIsSeenMap({
            ...newsItemFeedIdToIsSeenMap,
            [id]: true
        })
    }

    // Fetch helper function
    const buildNewsSearchUrl = (
        numberOfNewsItemsToFetch: number,
        searchQuery?: string,
        sourceFilterString?: string,
        startPublishDate?: number
    ): { baseUrl: string; params: URLSearchParams } => {
        const urlSearchParams = new URLSearchParams({
            start: '0',
            limit: numberOfNewsItemsToFetch.toString()
        });

        // Determine base URL and apply search or default parameters
        if (searchQuery || sourceFilterString) {
            const baseUrl = 'https://news-search.livemarketdata.com/search/query';
            urlSearchParams.set('f', 'title;body');
            urlSearchParams.set('q', searchQuery?.toLowerCase() ?? '*');

            if (sourceFilterString) {
                urlSearchParams.set('filter', `feedMeta.feedSymbol=${sourceFilterString}`);
            } else {
                urlSearchParams.set('filter', `topics=${newsFeedCategoryDetails.category}`);
            }

            return { baseUrl, params: urlSearchParams };
        }

        // Default news fetch
        const baseUrl = 'https://news-search.livemarketdata.com/search/keldan';
        urlSearchParams.set('category', newsFeedCategoryDetails.category);

        if (startPublishDate) {
            urlSearchParams.set('startPublishDate', startPublishDate.toString());
        }

        return { baseUrl, params: urlSearchParams };
    };

    const fetchNews = async (
        numberOfNewsItemsToFetch: number,
        onSuccess: (newsItems: INewsLmdNewsItem[], totalCount: number) => void,
        onFail: (err: Error) => void,
        startPublishDate?: number,
        searchQuery?: string,
        sourceFilterString?: string
    ) => {
        try {
            const { baseUrl, params } = buildNewsSearchUrl(
                numberOfNewsItemsToFetch,
                searchQuery,
                sourceFilterString,
                startPublishDate
            );

            const url = `${baseUrl}?${params.toString()}`;

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) throw new Error('RequestFailed');

            const resBody: INewsLmdResponse = await response.json();

            if (resBody?.feedItems === null) {
                throw new Error('Server did not return any news.');
            }

            const filteredNews = resBody.feedItems.filter(
                x => x.publish_timestamp === null || x.publish_timestamp <= Date.now()
            );
            onSuccess(filteredNews, resBody.totalCount);
        } catch (err) {
            onFail(err instanceof Error ? err : new Error('NetworkError'));
        }
    };

    // Sources fetch.
    const { fetchAndProcessSources } = useNewsSources(
        newsFeedCategoryDetails.category,
        initialSources,
        saveSourceState,
        setSourceFilters
    );
    useEffect(() => {
        fetchAndProcessSources();
    }, [newsFeedCategoryDetails]);

    // Initial data fetch:
    useEffect(() => {
        fetchNews(
            NEWS_ITEMS_FETCH_ON_INDEX_CHANGE,
            (newsItems, totalCount) => {
                if (totalPagesForDefault === null) setTotalPagesForDefault(totalCount, ITEMS_TO_DISPLAY_PER_PAGE);
                setDefaultNewsItems(newsItems);
            },
            (err) => {
                setNewsError(err);
            }
        )
    }, [newsFeedCategoryDetails, ITEMS_TO_DISPLAY_PER_PAGE]);

    // DefaultNewsItems: Index based data fetch.
    useEffect(() => {
        if (defaultNewsItems === null || !usingDefaultNews) return;
        // If it is not complete, fetch more.
        if (!isCompleteSlice) {
            const lastVisibleNewsitem = defaultNewsItems.slice(-1).pop();
            fetchNews(
                NEWS_ITEMS_FETCH_ON_INDEX_CHANGE,
                (newNewsItems) => {
                    // Append new news items onto visible news.
                    const joinedListOfOldAndNewNews = defaultNewsItems.concat(newNewsItems);
                    // There could be some overlap between the end of visiblenews and the start of newnews items.
                    // So we remove duplicates.
                    const joinedNewsWithoutDuplicates: INewsLmdNewsItem[] = [];
                    // Map newsitem.id to boolean. Faster timecomplexity than "visibleNewsWithoutDuplicates.includes()";
                    const hasNewsItemBeenAdded: {
                        [feedId in string]?: boolean
                    } = {};
                    for (const newsItem of joinedListOfOldAndNewNews) {
                        if (newsItem.id === null) continue;
                        if (hasNewsItemBeenAdded[newsItem.id]) continue;
                        joinedNewsWithoutDuplicates.push(newsItem);
                        hasNewsItemBeenAdded[newsItem.id] = true;
                    }
                    setDefaultNewsItems(joinedNewsWithoutDuplicates);
                },
                (err) => {
                    setNewsError(err);
                },
                lastVisibleNewsitem?.publish_timestamp ?? undefined
            )
        }
    }, [isCompleteSlice, usingDefaultNews, defaultNewsItems])
    useEffect(() => {
        resetPageCounter();
    }, [ITEMS_TO_DISPLAY_PER_PAGE])
    // Refresh rate based fetch
    useNewsRefresh({
        refreshRateMs,
        newsState: {
            defaultNewsItems,
            defaultNewsItemsWaitingQueue,
            currentPageIndex,
            usingDefaultNews,
            usingSearchWord,
            searchResultsDoubleMap,
            sourceFilters,
            category: newsFeedCategoryDetails.category,
            highlight: newsItemFeedIdToIsHighlightedMap,
            new: newsItemFeedIdToIsNewMap
        },
        stateSetters: {
            setDefaultNewsItems,
            setDefaultNewsItemsWaitingQueue,
            setSearchResultsDoubleMap,
            setError: setNewsError
        },
        notificationSetters: {
            setHighlight: setNewsItemFeedIdToIsHighlightedMap,
            setNew: setNewsItemFeedIdToIsNewMap
        },
        config: {
            highlightDuration: 15 * 1000,      // 15 seconds
            newItemDuration: 15 * 60 * 1000,   // 15 minutes
            initialFetchCount: INITIAL_REFRESH_RATE_NEWS_ITEMS_TO_FETCH,
            maxRecursionAttempts: 3
        },
        fetchNews: async (count) => {
            return new Promise((resolve, reject) => {
                fetchNews(
                    count,
                    (items) => resolve(items),
                    (error) => reject(error)
                );
            });
        }
    });

    // Reset waiting queues on page index change.
    useEffect(() => {
        if (currentPageIndex === 0) {
            // Reset default queue.
            if (defaultNewsItems !== null) {
                setDefaultNewsItems([...defaultNewsItemsWaitingQueue, ...defaultNewsItems]);
                setDefaultNewsItemsWaitingQueue([]);
            }
            // Reset search/source based queue.
            let newSearchResultsDoubleMap = {
                ...searchResultsDoubleMap
            };
            Object.entries(searchResultsDoubleMap).forEach(([searchInputKey, sourceFilterToResultsMap]) => {
                if (!sourceFilterToResultsMap) return;
                Object.entries(sourceFilterToResultsMap).forEach(([sourceFilterKey, searchResults]) => {
                    if (!searchResults || searchResults.results instanceof Error || !searchResults.waitingQueue) return;
                    const newSearchResult: SearchResultsInfo = {
                        ...searchResults,
                        results: [...searchResults.results, ...searchResults.waitingQueue],
                        waitingQueue: []
                    };
                    newSearchResultsDoubleMap = updateDoubleMapValue(newSearchResultsDoubleMap, searchInputKey, sourceFilterKey, newSearchResult);
                });
            });
            setSearchResultsDoubleMap(newSearchResultsDoubleMap);
        }
    }, [currentPageIndex]);

    // Search based fetch.
    useNewsSearch(
        {
            searchInput,
            category: newsFeedCategoryDetails.category,
            sourceFilters,
            searchResultsDoubleMap,
            usingSourceFilter,
            usingSearchWord,
            usingDefaultNews,
            isCompleteSlice
        },
        {
            setSearchResultsDoubleMap,
            fetchNews
        },
        {
            debounceMs: 400,
            defaultFetchCount: NEWS_ITEMS_FETCH_ON_INDEX_CHANGE
        }
    );

    const displayNewsItems = useMemo(() => {
        if (usingSourceFilter === 'none') {
            return <div className='content no_data'><span>Pick more sources</span></div>
        }
        const dataOrLastData = dataToDisplay ?? lastDisplayableData ?? null;
        if (dataOrLastData === null) {
            if (newsError === null) return <div className='fill center-container'><CircularProgress /></div>
            else return <div className='content no_data'><span>Error</span></div>
        }

        const newsItems = dataOrLastData
        if (newsItems.length === 0) return <div className='content no_data'><span>No data</span></div>
        return (
            <div className='content'>
                {newsItems.map((feedItem, index) => (
                    <NewsItem
                        key={feedItem.id}
                        isNew={feedItem.id !== null && (newsItemFeedIdToIsNewMap[feedItem.id] ?? false)}
                        isHighlighted={feedItem.id !== null && (newsItemFeedIdToIsHighlightedMap[feedItem.id] ?? false)}
                        isSeen={feedItem.id !== null && (newsItemFeedIdToIsSeenMap[feedItem.id] ?? false)}
                        onOpen={() => newsItemOnOpen(feedItem)}
                        item={feedItem}
                    />
                ))}
            </div>
        );
    }, [
        ITEMS_TO_DISPLAY_PER_PAGE,
        dataToDisplay,
        lastDisplayableData,
        usingSourceFilter,
        newsItemFeedIdToIsSeenMap,
        newsItemFeedIdToIsNewMap,
        newsItemFeedIdToIsHighlightedMap,
        newsError,
    ])

    const isNextPageDisabled = useMemo(() => {
        if (typeof totalPages === 'string') return true
        return ((currentPageIndex + 1) >= totalPages)
    }, [currentPageIndex, totalPages]);

    const isPrevPageDisabled = useMemo(() => {
        return (currentPageIndex === 0)
    }, [currentPageIndex]);

    const cleanSourceFilters = () => {
        if (sourceFilters instanceof Error || sourceFilters === null) return []
        return sourceFilters
    }

    return (
        <div className="KCL_news">
            {displayNewsItems}
            <div className="news-list__footer">
                <Filter
                    newsSources={cleanSourceFilters()}
                    setNewsSources={(e): void => { setSourceFilters(e); saveSourceState(e) }
                    } />
                <div className="news-list__pagination">
                    <Select
                        size='small'
                        sx={{
                            color: ' var(--dark-900, #232530)',
                            fontFamily: 'Roboto',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            minHeight: 'unset'
                        }}
                        MenuProps={{ className: "perview-select" }}
                        style={{ height: '20px', marginRight: '7px', lineHeight: 2 }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={ITEMS_TO_DISPLAY_PER_PAGE}
                        onChange={(e) => setQuantityPerPage(Number(e.target.value))}
                    >
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                    <div className="news-list__indicator">
                        {(totalPages === 0) ? 0 : (currentPageIndex + 1)} af {totalPages}
                    </div>
                    <div className="pagination">
                        <span
                            style={{ cursor: 'pointer' }}
                            className="news-list__arrows"
                            onClick={isNextPageDisabled ? undefined : goToNextPage}
                        >
                            <FontAwesomeIcon icon={faChevronDown}
                                className={cx("icon-fa", { "disabled": isNextPageDisabled })}
                            />
                        </span>
                        <span
                            style={{ cursor: 'pointer', marginLeft: '10px' }}
                            className="news-list__arrows"
                            onClick={isPrevPageDisabled ? undefined : goToPreviousPage}
                        >
                            <FontAwesomeIcon icon={faChevronUp}
                                className={cx("icon-fa", { "disabled": isPrevPageDisabled })}
                            />
                        </span>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsContainer;