import React from "react";
import { Subscription } from "rxjs/internal/Subscription";
import { AppStateManager } from "../../../StateManager";
import { PriceInfo } from "../../../Types/Websocket";
import { TranslationManager } from "../../../Translation/Translation";

export interface IPriceInfoCallBack {
    ask: number | undefined,
    bid: number | undefined,
    last: number | undefined,
}

export class SymbolInfo extends React.Component<{ symbol: string | null, onPriceInfoChange(e: IPriceInfoCallBack): void }, { priceInfo?: PriceInfo }> {
    private subscriptions: Subscription[];

    constructor(props) {
        super(props);
        this.state = { priceInfo: undefined };
        this.subscriptions = [];
    }


    async componentDidMount(): Promise<void> {
        await this.subscribeToSymbol();
    }

    async componentDidUpdate(prevProps): Promise<void> {
        if (this.props.symbol !== prevProps.symbol) {
            await this.subscribeToSymbol();
        }
    }

    private clearSubscriptions() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
    }

    subscribeToSymbol = async () => {
        this.clearSubscriptions();
        this.props.symbol !== null && this.subscriptions.push(
            AppStateManager.MF.getHandler("price_info").subscribe(
                this.props.symbol,
                (priceInfo: PriceInfo | undefined) => {
                    this.setState({ priceInfo });
                    this.props.onPriceInfoChange({ ask: priceInfo?.ask_price, bid: priceInfo?.bid_price, last: priceInfo?.last_price ?? priceInfo?.previous_closing_price }); // Call the callback function with the new priceInfo
                }
            )
        );
    }

    render() {

        const { priceInfo } = this.state;
        return (
            (this.props.symbol) ? <>
                <div>
                    <span className="info-title">{TranslationManager.getTranslation().New_Order_Modal.Symbol_info.Last}</span>
                    <span className="info-value">{priceInfo?.last_price ?? priceInfo?.previous_closing_price ?? '-'}</span>
                </div>
                <div>
                    <span className="info-title">{TranslationManager.getTranslation().New_Order_Modal.Symbol_info.Bid}</span>
                    <span className="info-value">{priceInfo?.bid_price ?? '-'} {(priceInfo?.bid_quantity) ? `(${priceInfo.bid_quantity})` : ''}</span>
                </div>
                <div>
                    <span className="info-title">{TranslationManager.getTranslation().New_Order_Modal.Symbol_info.Ask}</span>
                    <span className="info-value">{priceInfo?.ask_price ?? '-'} {(priceInfo?.ask_quantity) ? `(${priceInfo.ask_quantity})` : ''}</span>
                </div>
            </> : <div style={{ height: '21px' }}></div>
        )
    }
}