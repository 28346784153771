// React and Formik imports
import React, { useMemo } from "react";
import { Formik, Form } from "formik";

// Material-UI components import
import { Dialog } from "@mui/material";

// Custom components and utilities import
import { DialogStyleContainer } from "../Main/Main";
import { AppStateManager } from "../../StateManager";

// Types imports
import { FatFingerCheck, OrderMessage } from "../../Types/Websocket";
import { OrderSubmitRequest } from "../../Types/private-data/NewOrderSubmitRequest";

// UI Elements imports
import { cleanValueWithTickerFormatting } from "../UI-Elements/FormikInputs/NumberInput";
import DoubleCheckModal from "./DoubleCheckModal";
import { useOrderValidation } from "./NewOrderModalHelpers/useValidationHook";
import { IPriceInfoCallBack } from "./NewOrderModalHelpers/SymbolInfo";
import { OrderFormHeader } from "./NewOrderModalHelpers/OrderHeader";
import { LastRow, FirstRow, SecondRow, RetailFields, OrderFormValues } from "./NewOrderModalFormComponents";
import { OrderFormFooter } from "./NewOrderModalHelpers/OrderFooter";
import OrderService from "./NewOrderModalHelpers/OrderService";
import { RetailCustomerSuccess } from "../../Types/RetailTradingTypes";

export interface SimpleDialogProps {
  open: boolean;
  onClose: (value: string | null) => void;
  modifyOrder?: OrderMessage | null;

  initialSymbol?: string | null;
  price?: string;
  side?: string;
  quantity?: number;
}
interface SelectOption {
  value: string;
  title: string;
}
const NewOrderModal: React.FC<SimpleDialogProps> = ({
  onClose,
  open,
  initialSymbol,
  modifyOrder = null,
  price,
  side,
  quantity
}) => {
  const [phaseTwo, setPhaseTwo] = React.useState<OrderSubmitRequest | undefined>(undefined);
  const [check, setCheck] = React.useState<FatFingerCheck | undefined>(undefined);
  const [priceInfo, setPriceInfo] = React.useState<IPriceInfoCallBack>({ ask: undefined, bid: undefined, last: undefined });
  const [selectedPortfolio, setSelectedPortfolio] = React.useState<string>(AppStateManager.customerInfo?.portfolios?.[0]?.id.toString() ?? "");
  const private_data: { value: string, title: string }[] = Object.entries(AppStateManager.PDS.PDSsockets).map((item) => ({ value: new URL(item[1].url).host, title: item[1].name ?? "" }))
  const { validationSchema } = useOrderValidation();



  function getPortfolios(customerInfo: RetailCustomerSuccess | undefined): SelectOption[] {
    if (!customerInfo?.portfolios) {
      return [];
    }

    return customerInfo.portfolios.map((portfolio) => ({
      value: portfolio.id.toString(),
      title: portfolio.name
    }));
  }

  function getAccounts(
    customerInfo: RetailCustomerSuccess | undefined,
    selectedPortfolio: string
  ): SelectOption[] {
    if (!customerInfo?.portfolios) {
      return [];
    }

    const portfolio = customerInfo.portfolios.find(
      (p) => p.id.toString() === selectedPortfolio
    );

    if (!portfolio?.accounts) {
      return [];
    }

    return portfolio.accounts.map((account) => ({
      value: account.id.toString(),
      title: account.name
    }));
  }

  function getBrokers(privateData: SelectOption[]): SelectOption[] {
    if (AppStateManager.hasAccessToRetail.getValue()) {
      if (!AppStateManager.Retail.currentSocket?.url) {
        return [];
      }

      return [{
        value: new URL(AppStateManager.Retail.currentSocket.url).host,
        title: AppStateManager.Retail.currentSocket.name || ''
      }];
    }

    if (AppStateManager.hasAccessToPDS) {
      return privateData;
    }

    return [];
  }

  const portfolios = getPortfolios(AppStateManager.customerInfo);
  const accounts = useMemo(
    () => getAccounts(AppStateManager.customerInfo, selectedPortfolio),
    [selectedPortfolio]
  );
  const brokers = getBrokers(private_data);

  function handleModifyOrderBroker(
    modifyOrder: OrderMessage,
    brokers: SelectOption[]
  ): string {
    if (AppStateManager.hasAccessToRetail.getValue()) {
      if (!AppStateManager.Retail.currentSocket?.url) {
        return '';
      }
      return new URL(AppStateManager.Retail.currentSocket.url).host;
    }

    const broker = brokers.find(item => item.title === modifyOrder.broker);
    if (!broker) {
      return '';
    }
    return broker.value;
  }


  function getModifiedOrderValues(
    modifyOrder: OrderMessage,
    brokers: SelectOption[]
  ): OrderFormValues {
    return {
      side: modifyOrder.side,
      quantity: modifyOrder.quantity.toString(),
      symbol: modifyOrder.symbol,
      price: cleanValueWithTickerFormatting(modifyOrder.price),
      order_type: modifyOrder.order_type,
      visible_quantity: getVisibleQuantity(modifyOrder),
      hidden: isHidden(modifyOrder),
      time_in_force: modifyOrder.time_in_force,
      min_quantity: undefined,
      broker: handleModifyOrderBroker(modifyOrder, brokers),
    };
  }

  function getNewOrderValues(
    side: string | undefined,
    quantity: number | undefined,
    price: string | undefined,
    brokers: SelectOption[],
    initialSymbol?: string | null
  ): OrderFormValues {
    const formattedPrice = price ? cleanValueWithTickerFormatting(Number(price)) : undefined;
    const brokerValue = brokers.length > 0 ? brokers[0].value : '';
    const portfolioId = AppStateManager.customerInfo?.portfolios?.[0]?.id.toString() || '';

    return {
      side: side || 'buy',
      quantity: quantity?.toString() || '',
      symbol: initialSymbol || '',
      price: formattedPrice,
      order_type: 'limit',
      visible_quantity: undefined,
      hidden: false,
      time_in_force: 'day',
      min_quantity: undefined,
      broker: brokerValue,
      portfolio_id: portfolioId,
      account_id: ''
    };
  }

  function getVisibleQuantity(order: OrderMessage): string | undefined {
    if (order.visible_quantity === order.quantity) {
      return undefined;
    }
    return order.visible_quantity?.toString();
  }

  function isHidden(order: OrderMessage): boolean {
    return order.visible_quantity !== undefined &&
      order.visible_quantity !== order.quantity;
  }

  const initialValues = modifyOrder ?
    getModifiedOrderValues(modifyOrder, brokers) :
    getNewOrderValues(side, quantity, price, brokers, initialSymbol);

  if (AppStateManager.hasAccessToRetail.getValue()) {
    if (AppStateManager.customerInfo?.portfolios?.[0]?.id) {
      initialValues["portfolio_id"] = AppStateManager.customerInfo.portfolios[0].id.toString();
    }

    if (AppStateManager.customerInfo?.portfolios?.[0]?.accounts?.[0]?.id) {
      initialValues["account_id"] = AppStateManager.customerInfo.portfolios[0].accounts[0].id.toString();
    }
  }
  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open]);

  return (
    <Dialog
      onClose={() => {
        onClose(null);
        setPhaseTwo(undefined);
      }}
      open={open}
    >
      <div className="KM_modal KM_NewOrderModal" style={DialogStyleContainer}>
        <OrderFormHeader modifyOrder={modifyOrder} onClose={() => onClose(null)} />
        {
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async () => { }}
          >
            {({ setFieldValue, values, validateForm, setErrors, setTouched }) => (
              phaseTwo ? (
                <DoubleCheckModal
                  fatFingerCheck={check}
                  brokerName={brokers.find(item => item.value === values.broker)?.title ?? ""}
                  goBack={() => setPhaseTwo(undefined)}
                  confirm={(values, callback) => modifyOrder === null ? OrderService.sendOrder(values, callback) : OrderService.updateOrder({ orderId: modifyOrder.id, ...values }, callback)}
                  close={onClose}
                  data={phaseTwo}
                />
              ) : (
                <Form>
                  <div className="modal_body">
                    <FirstRow
                      modifyOrder={modifyOrder}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                    <SecondRow
                      modifyOrder={modifyOrder}
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                    <LastRow
                      modifyOrder={modifyOrder}
                      brokers={brokers}
                      portfolios={portfolios}
                      accounts={accounts}
                      setSelectedPortfolio={setSelectedPortfolio}
                    />
                    {AppStateManager.hasAccessToRetail.getValue() && (
                      <RetailFields
                        modifyOrder={modifyOrder}
                        portfolios={portfolios}
                        accounts={accounts}
                        setSelectedPortfolio={setSelectedPortfolio}
                      />
                    )}
                  </div>
                  <OrderFormFooter
                    onClose={() => onClose(null)}
                    values={values}
                    validateForm={validateForm}
                    setErrors={setErrors}
                    setTouched={setTouched}
                    setPhaseTwo={setPhaseTwo}
                    setCheck={setCheck}
                    priceInfo={priceInfo}
                    setPriceInfo={setPriceInfo}
                  />
                </Form>
              )
            )}
          </Formik>
        }

      </div>
    </Dialog >
  );
};

export default NewOrderModal;