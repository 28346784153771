import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import {
  MessageType,
  OrderbookState,
  SymbolMessageType,
} from "../../../Types/Websocket";
import { SymbolMessageHandler } from "./AbstractHandler";
// Incoming message
export type MBLMessage = {
  initial: boolean;
  orderbook_state: OrderbookState;
  symbol: string;
  asks: LevelDescriptionMessage[];
  bids: LevelDescriptionMessage[];
};
type LevelDescriptionMessage = {
  level: number;
  orders: number;
  price: number;
  symbol: string;
  type: "entry";
  volume: number;
};

// Stored symbol info
export type MBLInfo = {
  orderbook_state?: OrderbookState;
  asks: Record<number, LevelDescription>;
  bids: Record<number, LevelDescription>;
};
export type LevelDescription = {
  orders: number;
  price: number;
  volume: number;
};

export class MBLHandler extends SymbolMessageHandler<MBLMessage, MBLInfo> {
  messageType: SymbolMessageType = MessageType.market_by_level;

  initialValue(): MBLInfo {
    return { asks: {}, bids: {} };
  }

  messageSymbol(message: MBLMessage): string {
    return message.symbol;
  }

  handleSymbolsMessage(symbol: string, message: MBLMessage): void {
    if (!this.symbolHandlerDetails[symbol]) {
      this.symbolHandlerDetails[symbol] = {
        symbolInfo: this.initialValue(),
        behaviorSubject: new BehaviorSubject(this.initialValue()),
        subscriptionCount: 0,
        waitingOnAction: null
      };
    }
    const { symbolInfo } = this.symbolHandlerDetails[symbol];

    // Update orderbook_state
    if ("orderbook_state" in message)
      symbolInfo["orderbook_state"] = message["orderbook_state"];

    // Update ask and bid sides
    ["asks", "bids"].forEach((side) => {
      if (side in message)
        message[side].forEach((levelDescription) => {
          const { level, orders, price, volume } =
            levelDescription;
          if (volume === 0 || orders === 0) delete symbolInfo[side][level];
          else symbolInfo[side][level] = { orders, price, volume };
        });
    });

    this.pushUpdate(symbol);
  }
}
