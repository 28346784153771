import { format } from "date-fns";
import { TranslationManager } from "../../../Translation/Translation";
import { formatChartNumber } from "../../../Utils/Formatting";
import ISLocale from "date-fns/locale/is";
import enGB from "date-fns/locale/en-GB";

export function generateDefaultTooltip(context) {
    return `
    <div class="chart-tooltip">
      ${context.points?.reduce(
        (s, point) => {
            if (point.series.name === "Volume") {
                return `${s}<p class="tooltip-title">
              <span style="color: ${point.color}">
                ${TranslationManager.getTranslation().Chart.Volume}:
              </span> 
              <span className="tooltip-value">
                ${formatChartNumber(point.y, null, 0)}
              </span>
            </p>`;
            } else {
                return `${s}<p class="tooltip-title">
              <span style="color: ${point.color}">
                ${TranslationManager.getTranslation().Chart.Price}:
              </span> 
              <span className="tooltip-value">
                ${formatChartNumber(point.y, null, 3)}
              </span>
            </p>`;
            }
        },
        `<p class="tooltip-date">
          ${format(
            context.x,
            "EEEE, dd. MMM yyyy",
            {
                locale: TranslationManager.getActiveLanguage() === "IS"
                    ? ISLocale
                    : enGB,
            }
        )}
        </p>`
    )}
    </div>`;
}

export function determineColor(startY: number, endY: number, isViceVersa: boolean): string {
    if (startY === endY) return 'transparent';

    if (isViceVersa) {
        if (startY > endY) return 'var(--green-100)';
        return 'var(--red-100)';
    }

    if (startY < endY) return 'var(--green-100)';
    return 'var(--red-100)';
}

export function getDiffColor(diff: number): string {
    if (diff === 0) return 'var(--dark-400)';

    if (diff > 0) return 'var(--green-600)';
    return 'var(--red-600)';
}

export function formatDate(date: Date, locale: string): string {
    return format(
        date,
        "dd. MMM yyyy",
        { locale: locale === "IS" ? ISLocale : enGB }
    );
}

export function generateDragTooltip(chart: any, formattedDiff: string, diffColor: string, ratio: number, isViceVersa: boolean): string {
    const language = TranslationManager.getActiveLanguage();
    const translations = TranslationManager.getTranslation().Chart;

    let tooltipContent;
    if (!isViceVersa) {
        tooltipContent = `
        <span>
          ${translations.Start}: ${formatDate(new Date(chart.startX), language)} - ${formatChartNumber(chart.startY)}
        </span>
        <span>
          ${translations.End}: ${formatDate(new Date(chart.endX), language)} - ${formatChartNumber(chart.endY)}
        </span>
      `;
    } else {
        tooltipContent = `
        <span>
          ${translations.Start}: ${formatDate(new Date(chart.endX), language)} - ${formatChartNumber(chart.endY)}
        </span>
        <span>
          ${translations.End}: ${formatDate(new Date(chart.startX), language)} - ${formatChartNumber(chart.startY)}
        </span>
      `;
    }

    return `
      <div class="chart-tooltip" style="
        color: var(--dark-900);
        display: flex;
        flex-direction: column;
      ">
        ${tooltipContent}
        <span>
          <span style="color: ${diffColor};">${formattedDiff}</span>
          <span style="color: ${diffColor};">(${(Math.abs(ratio * 100)).toFixed(2)}%)</span>
        </span>
      </div>`;
}