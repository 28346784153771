import React, { useRef } from "react";
import * as Highcharts from "highcharts/highstock";
import { formatChartNumber } from "../../../Utils/Formatting";
import {
    BaseChartComponent,
    baseTheme,
    getBaseChartConfig,
    setupMouseEvents,
    getAreaSeriesConfig
} from "./BaseChart";

type DataPoint = { date: Date; price: number; volume: number };

// Set global theme
Highcharts.setOptions(baseTheme);

export function HighChart({ points }: { points: DataPoint[] }) {
    const divRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<Highcharts.Chart | null>(null);
    const xAxisExtremes = useRef<{ min: number | undefined; max: number | undefined }>({
        min: undefined,
        max: undefined,
    });

    const initChart = (width: number, height: number) => {
        if (!divRef.current) return;

        const priceData = points.map((point) => [point.date.getTime(), point.price]);
        const volumeData = points.map((point) => [point.date.getTime(), point.volume]);
        const chartOptions: Highcharts.Options = {
            ...getBaseChartConfig(width, height),
            chart: {
                ...getBaseChartConfig(width, height).chart,
                renderTo: divRef.current as HTMLElement,
                events: {
                    load: function (this: Highcharts.Chart) {
                        setupMouseEvents(this);

                        if (
                            points[points.length - 2] &&
                            xAxisExtremes.current.max === points[points.length - 2].date.getTime()
                        ) {
                            this.xAxis[0].setExtremes(
                                xAxisExtremes.current.min,
                                points[points.length - 1].date.getTime()
                            );
                        }
                        if (xAxisExtremes.current.min !== undefined && xAxisExtremes.current.max !== undefined) {
                            this.xAxis[0].setExtremes(xAxisExtremes.current.min, xAxisExtremes.current.max + 2);
                        }
                        this.yAxis[0].setExtremes(
                            this.yAxis[0].getExtremes().dataMin,
                            this.yAxis[0].getExtremes().dataMax
                        );
                    }
                }
            },
            navigator: {
                enabled: true,
                adaptToUpdatedData: true,
                handles: {
                    height: 22,
                    width: 6,
                },
                maskFill: "#D4DEFF80",
                series: {
                    lineWidth: 0,
                    opacity: 0,
                },
                height: 20,
                xAxis: {
                    labels: {
                        style: {
                            color: "#7B7C83",
                            fontSize: "10px",
                        },
                        y: -6,
                    },
                    gridLineWidth: 0,
                    minTickInterval: 1000 * 60 * 60 * 24 * 365,
                    lineWidth: 0,
                },
                yAxis: {
                    lineWidth: 0,
                },
                outlineWidth: 0,
            },
            xAxis: {
                labels: {
                    formatter: function () {
                        return Highcharts.dateFormat("%b %d", this.value as number);
                    }
                },
                events: {
                    afterSetExtremes: function (e: any) {
                        if (e.type !== 'afterSetExtremes') {
                            xAxisExtremes.current.min = e.min;
                            xAxisExtremes.current.max = e.max;
                            if (e.target.coll !== 'yAxis') {
                                const yAxis = this.chart.yAxis[0];
                                yAxis.setExtremes(
                                    yAxis.getExtremes().dataMin,
                                    yAxis.getExtremes().dataMax
                                );
                            }
                        }
                    }
                },
            },
            yAxis: [
                {
                    labels: {
                        enabled: true,
                        formatter: function () {
                            return formatChartNumber(this.value as number, null, 3) as string;
                        },
                        style: {
                            color: "var(--chart-axis-text, #000000)",
                            fontFamily: "Roboto",
                            fontSize: "9px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "normal",
                        },
                    },
                    minTickInterval: 0,
                    gridLineDashStyle: "Dash",
                    gridLineWidth: 1,
                    gridLineColor: "#D3D3D6",
                    opposite: true,
                    height: "70%",
                },
                {
                    labels: {
                        enabled: true,
                        style: {
                            color: "var(--chart-axis-text, #000000)",
                            fontFamily: "Roboto",
                            fontSize: "9px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "normal",
                        },
                    },
                    minTickInterval: 1,
                    gridLineDashStyle: "Dash",
                    gridLineWidth: 1,
                    gridLineColor: "#D3D3D6",
                    opposite: true,
                    top: "70%",
                    height: "30%",
                    offset: 0,
                },
            ],
            rangeSelector: {
                buttons: [
                    {
                        type: "day",
                        count: 1,
                        text: "1d",
                    },
                    {
                        type: "week",
                        count: 1,
                        text: "1w",
                    },
                    {
                        type: "month",
                        count: 1,
                        text: "1m",
                    },
                    {
                        type: "month",
                        count: 6,
                        text: "6m",
                    },
                    {
                        type: "year",
                        count: 1,
                        text: "1y",
                    },
                    {
                        type: "all",
                        text: "All",
                    },
                ],
                allButtonsEnabled: true,
                inputEnabled: false,
                selected: 3,
            },
            series: [
                getAreaSeriesConfig(priceData),
                {
                    type: "column" as const,
                    grouping: false,
                    name: "Volume",
                    color: "#232530",
                    data: volumeData,
                    yAxis: 1,
                },
            ]
        };

        if (chartRef.current) {
            chartRef.current.setSize(width, height, false);
        } else {
            chartRef.current = Highcharts.stockChart(chartOptions);
            chartRef.current.setSize(width, height, false);
        }
    };

    React.useEffect(() => {
        Highcharts.setOptions({
            lang: {
                resetZoomTitle: "",
            }
        });

        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
                chartRef.current = null;
            }
        };
    }, []);

    React.useEffect(() => {
        if (chartRef.current && points.length > 0) {
            const priceData = points.map((point) => [point.date.getTime(), point.price]);
            const volumeData = points.map((point) => [point.date.getTime(), point.volume]);
            chartRef.current.series[0].setData(priceData, false);
            chartRef.current.series[1].setData(volumeData, true);
        }
    }, [points, chartRef]);

    return (
        <BaseChartComponent>
            {(dimensions) => {
                initChart(dimensions.width, dimensions.height);
                return <div data-testid="highchart" className="chart" ref={divRef} />;
            }}
        </BaseChartComponent>
    );
}