import { useMemo } from "react";
import { IBondCalculation, IBondPrerequisites } from "../../../KodiInterface/LMD";
import { MoreTradeableInfo } from "../../../Types/LMDTypes";
import { formatNumber } from "../../../Utils/Formatting";
import { formatDate } from "../../../Utils/Date";
import { TranslationManager } from "../../../Translation/Translation";
import {
    calculateAccruedIndex,
    calculateAccruedInterest,
    calculateDiscount,
    calculatePriceAdjustment,
    calculateTotal,
    formatAmortizationType,
    formatSimpleString
} from "./BondUtils";

// Types
interface IBondInfoProps {
    prerequisites: IBondPrerequisites[] | undefined;
    symbolInfo: MoreTradeableInfo | undefined;
    calculation: IBondCalculation | undefined;
    principal?: number;
}

interface FieldConfig {
    label: string;
    key?: string;
    transform?: (data: any) => React.ReactNode;
}



const calculateSalesPrice = (
    calculation: IBondCalculation | undefined,
    priceAdjustment: number
): React.ReactNode => {
    if (!calculation?.principal_with_interest_and_index || !calculation.dirty_price) return '';
    const value = Math.max(
        (calculation.principal_with_interest_and_index * priceAdjustment) -
        ((calculation.principal_with_interest_and_index - calculation.dirty_price) * priceAdjustment),
        0
    );
    return <strong>{formatNumber(value, 0)}</strong>;
};

// Component
const BondInfo: React.FC<IBondInfoProps> = ({
    prerequisites,
    symbolInfo,
    calculation,
    principal,
}) => {
    const translations = TranslationManager.getTranslation().BondsCalculator.BondInfo;

    const left = useMemo(() => [
        {
            label: translations.Issuer,
            key: "symbol",
            transform: () => symbolInfo?.IssuerIdMap ?? ''
        },
        {
            label: translations.Maturity_date,
            key: "maturity_date",
            transform: (prereq: IBondPrerequisites) =>
                prereq && prereq.maturity_date ? formatDate(Date.parse(prereq.maturity_date)) : ''
        },
        {
            label: translations.Coupon_rate,
            key: "coupon_rate",
            transform: (prereq: IBondPrerequisites) => prereq?.coupon_rate ?? ''
        },
        {
            label: translations.Amortization,
            key: "amortization_type",
            transform: (prereq: IBondPrerequisites) =>
                formatAmortizationType(prereq?.amortization_type)
        },
        {
            label: translations.Day_count,
            key: "day_count_method",
            transform: (prereq: IBondPrerequisites) =>
                formatSimpleString(prereq?.day_count_method)
        },
        {
            label: translations.Index,
            key: "reference_index_symbol",
            transform: (prereq: IBondPrerequisites) =>
                formatSimpleString(prereq?.reference_index_symbol)
        },
        {
            label: translations.Index_value,
            key: "base_index_value",
            transform: () => calculation?.daily_index ?? ''
        },
        {
            label: translations.Base_index,
            key: "base_index_value",
            transform: (prereq: IBondPrerequisites) => prereq?.base_index_value ?? ''
        }
    ], [symbolInfo, calculation, translations]);

    const right = useMemo(() => {
        const priceAdjustment = calculatePriceAdjustment(principal, calculation?.remaining_principal);

        return [
            {
                label: translations.Principal,
                key: "symbol",
                transform: () => formatNumber(principal ?? 0)
            },
            {
                label: translations.Accrued_interest,
                key: "interest_from_date",
                transform: (calc: IBondCalculation) =>
                    calculateAccruedInterest(calc, priceAdjustment)
            },
            {
                label: translations.Accrued_index,
                key: "first_ordinary_coupon_date",
                transform: (calc: IBondCalculation) =>
                    calculateAccruedIndex(calc, priceAdjustment)
            },
            {
                label: translations.Total,
                transform: (calc: IBondCalculation) =>
                    calculateTotal(calc, priceAdjustment)
            },
            {
                label: translations.Discount,
                key: "rate_calc_type",
                transform: (calc: IBondCalculation) =>
                    calculateDiscount(calc, priceAdjustment)
            },
            {
                label: translations.Sales_price,
                key: "market_symbol",
                transform: (calc: IBondCalculation) =>
                    calculateSalesPrice(calc, priceAdjustment)
            },
            {
                label: translations.Price_per_point,
                key: "coupon_rate",
                transform: (calc: IBondCalculation) =>
                    calc?.price_per_point
                        ? formatNumber(Math.max(calc.price_per_point * priceAdjustment, 0), 2)
                        : ''
            },
            {
                label: translations.Duration,
                key: "maturity_date",
                transform: (calc: IBondCalculation) =>
                    calc?.duration
                        ? formatNumber(Math.max(calc.duration, 0), 5)
                        : ''
            }
        ];
    }, [calculation, principal, translations]);

    const renderField = ({ label, value }: { label: string; value: React.ReactNode }) => (
        <div
            key={label}
            className="flex-item whitespace-pre"
        >
            <span className="label">{label}</span>
            <span className="value">{value ?? ''}</span>
        </div>
    );

    const renderSection = (fields: FieldConfig[], data: any) => {
        return fields.map(({ label, key, transform }) => {
            let value;
            if (transform) {
                value = transform(data);
            } else if (key && data) {
                value = data[key];
            } else {
                value = '';
            }

            return renderField({ label, value });
        });
    };

    return (
        <div className="bondInfo_container">
            <div className="flex-container">
                <div className="left-container">
                    {renderSection(left, prerequisites?.[0])}
                </div>
                <div className="right-container">
                    {renderSection(right, calculation)}
                </div>
            </div>
        </div>
    );
};

export default BondInfo;