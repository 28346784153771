import { DisplayState, SearchResultsInfo, INewsLmdNewsItem } from "../../../Types/NewsType";
import { getSourceStringFromSourceList } from "../../../Utils/NewsUtils";

export class NewsDisplayService {
    static getSearchResults(
        state: DisplayState
    ): SearchResultsInfo | null {
        if (state.usingSearchWord) {
            const sourceFilterString = state.usingSourceFilter === 'all'
                ? state.category
                : `${getSourceStringFromSourceList(state.sourceFilters, 'on')}`;

            return state.searchResultsDoubleMap?.[state.searchInput]?.[sourceFilterString] || null;
        }

        if (state.usingSourceFilter === 'all') {
            return {
                //@ts-ignore
                results: state.defaultNewsItems,
                totalCount: state.defaultNewsItems?.length || 0,
                waitingQueue: []
            };
        }

        const sourceFilterString = `${getSourceStringFromSourceList(state.sourceFilters, 'on')}`;
        return state.searchResultsDoubleMap?.['']?.[sourceFilterString] || null;
    }

    static getSliceOfData(
        data: INewsLmdNewsItem[] | Error | null | undefined,
        pageIndex: number,
        itemsPerPage: number
    ): { dataSlice: INewsLmdNewsItem[], isSliceFullLength: boolean } {
        if (!data || data instanceof Error) {
            return {
                dataSlice: [],
                isSliceFullLength: false
            };
        }

        const startIndex = pageIndex * itemsPerPage;
        const dataSlice = data.slice(startIndex, startIndex + itemsPerPage);

        return {
            dataSlice,
            isSliceFullLength: dataSlice.length === itemsPerPage
        };
    }

    static calculatePaginationDetails(
        totalItems: number,
        itemsPerPage: number,
        currentPage: number
    ): {
        numberOfPages: number;
        isOnLastPage: boolean;
    } {
        const numberOfPages = Math.ceil(totalItems / itemsPerPage);
        return {
            numberOfPages,
            isOnLastPage: (currentPage + 1) === numberOfPages
        };
    }
}