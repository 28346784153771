import { Subject, Subscription } from "rxjs";
import { MessageHandler } from "./AbstractHandler";
import { MessageType, SubscriptionCommands, Trade } from "../../../Types/Websocket";
import { convertKODITimeStringToDate } from "../../../Utils/Date";
import { AppStateManager } from "../../../StateManager";
import { ManageSubscriptionMessage } from "../WebsocketHelpers";



export type TradeMessage = {
    initial: boolean;

    aggressive_party?: string;
    class: string;
    date: string;
    datetime_exec: string;
    diff_last_price: number;
    is_latest_trade: boolean;
    lmd_number: number;
    mmt: string;
    nano_second_fraction: number;
    outside_spread: boolean;
    price: number;
    symbol: string;
    time_executed: string;
    timestamp_dissemination: string;
    to_be_cleared: boolean;
    top_of_book_ask: number;
    top_of_book_bid: number;
    trade_cancellation: boolean;
    trade_id: string;
    trade_nr: number;
    trade_number: number;
    trade_type_name: string;
    trade_updates_average: boolean;
    trade_updates_high_low: boolean;
    trade_updates_last_paid: boolean;
    trade_updates_turnover: boolean;
    trade_venue: string;
    value: number;
    volume: number;
}
export class TradeTickHandler extends MessageHandler<TradeMessage> {
    symbolHandlerDetails: Record<string, {
        subject: Subject<Trade>
        subscriptionCount: number;
    }>;
    constructor() {
        super();
        this.symbolHandlerDetails = {};
    }


    handleMessage(message: TradeMessage): void {
        const { symbol } = message;
        const time_executed = message.time_executed ? convertKODITimeStringToDate(message.time_executed) : null;
        if (time_executed !== null && !message.initial && symbol in this.symbolHandlerDetails) { // && !message.initial
            const trade: Trade = { ...message, time_executed };
            this.symbolHandlerDetails[symbol].subject.next(trade);
        }
    }

    //counter sem telur hvað eru margir bunir að subscribe-a 

    subscribe(symbol: string, callback: (trade: Trade) => void): Subscription {
        if (!(symbol in this.symbolHandlerDetails)) this.symbolHandlerDetails[symbol] = {
            subscriptionCount: 0,
            subject: new Subject(),
        }

        this.symbolHandlerDetails[symbol].subscriptionCount += 1;
        // if subscriptionCount for symbol went from 0 -> 1
        if (this.symbolHandlerDetails[symbol].subscriptionCount === 1)
            AppStateManager.MF.manageSymbol(symbol, SubscriptionCommands.Subscribe, MessageType.trade);


        const subscription = this.symbolHandlerDetails[symbol].subject.subscribe(callback);
        const tearDownLogic = () => {
            this.symbolHandlerDetails[symbol].subscriptionCount -= 1;
            // if subscriptionCount for symbol went from 1 -> 0
            if (this.symbolHandlerDetails[symbol].subscriptionCount === 0)
                AppStateManager.MF.manageSymbol(symbol, SubscriptionCommands.Unsubscribe, MessageType.trade);
        };
        subscription.add(tearDownLogic);
        return subscription;
    }

    regenerateSubscriptionMessage(): ManageSubscriptionMessage {
        const symbols = Object.keys(this.symbolHandlerDetails).filter(symbol => {
            const { subscriptionCount } = this.symbolHandlerDetails[symbol];
            return (subscriptionCount > 0);
        });

        return {
            type: MessageType.trade,
            command: SubscriptionCommands.Subscribe,
            message: { symbols }
        };
    }
}