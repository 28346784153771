import React, { Component, useEffect } from 'react';
import cx from 'classnames';
import { JSONObject } from '../../../Types/Common';
import { Subscription } from 'rxjs';
import { createPromise } from '../../../Utils/Common';

import { GridRow, GridColDef, DataGridPro, useGridApiRef, GridRenderCellParams, GridEventListener } from '@mui/x-data-grid-pro';
// This import is... I'm lost for words. Lucky?.
// I just guessed names did not find it in any documentation
// Enables subscriptions only of rendered symbols

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { LMDInterface } from '../../../KodiInterface/LMD';
import { WatchListColumns } from './Columns';
import { GLWindowManager } from '../GLWindowManager';
import { hideDefaultModal, openDefaultModal, openPropertiesOfSymbol } from '../../Main/Main';
import { ListInfo, TradeableInfo } from '../../../Types/LMDTypes';
import { formatWatchListCell } from './CellFormat';
import { PriceInfo } from '../../../Types/Websocket';

import { TranslationManager } from '../../../Translation/Translation';
import { AppStateManager, getHandler } from '../../../StateManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faLayerGroup, faChartLine, faMoneyCheckPen } from '@fortawesome/pro-solid-svg-icons';
import NewOrderModal from '../../Modals/NewOrderModal';
import { formatNumber } from '../../../Utils/Formatting';
import { cleanValueWithTickerFormatting } from '../../UI-Elements/FormikInputs/NumberInput';
import { ColumnMenu, MyColumnSortedAscendingIcon, MyColumnSortedDescendingIcon, MyNoRowsOverlay } from '../../Tables/Helpers';
type GridInitialStatePro = JSONObject;

type Row = { id: string } & PriceInfo & TradeableInfo;

type Input = {
    list: ListInfo | undefined,

    tableState: GridInitialStatePro | undefined,
    saveState: (state: GridInitialStatePro) => void,

    rowsSelectable: boolean,
    onRowClick: GridEventListener<'rowClick'>,
}

export function WatchListTable(props: Input) {
    const { tableState, saveState, onRowClick, rowsSelectable } = props;
    const apiRef = useGridApiRef();
    const [rows, updateRows] = React.useState([] as Row[]);
    const [list, updateList] = React.useState(props.list);
    // Context menu
    const [contextMenuPosition, setContextMenuPosition] = React.useState<{ x: number, y: number } | null>(null);
    const [selectedSymbol, setSelectedSymbol] = React.useState<string | null>(null);
    const [selectedRow, setSelectedRow] = React.useState<PriceInfo>();
    const [selectedColumn, setSelectedColumn] = React.useState<string | null>(null);
    const [newOrderModalOpen, setNewOrderModalOpen] = React.useState(false);

    // For resize
    const ref = React.createRef<any>();

    // Set initial rows if they have not already been set
    // Logic:
    // If first time being rendered get all symbols, for each symbol subscribe to priceInfoHandler
    // untill you get a single update and then unsubsribe. Wait untill all symbols have given a single
    // update. Use these updates to create inital rows
    useEffect(() => {
        // Function to fetch price information for a list of symbols
        const fetchSymbols = async (symbols: string[]) => {
            // Fetch price information for each symbol and update the rows state
            const rows = await Promise.all(symbols.map(async symbol => {
                const priceInfo = await fetchSymbolPriceInfo(symbol);
                return { ...priceInfo, id: priceInfo.symbol } as Row;
            }));
            updateRows(rows);
        };

        // If the list is defined and has symbols, and rows are not already set, fetch symbols
        if (list !== undefined && list.symbol !== '' && rows.length === 0) {
            LMDInterface.getTradeableListSymbols(list).then(fetchSymbols);
        } else {
            // Otherwise, clear the rows
            updateRows([]);
        }
    }, [list]);

    // Function to fetch price information for a single symbol
    const fetchSymbolPriceInfo = async (symbol: string): Promise<PriceInfo> => {
        return new Promise<PriceInfo>((resolve) => {
            // Create a promise to handle the subscription
            const [subscriptionPromise, subscriptionResolve] = createPromise<Subscription>();
            // Subscribe to price information updates for the symbol
            subscriptionResolve(getHandler('price_info').subscribe(symbol, async symbolInfo => {
                if (symbolInfo !== undefined) {
                    // If price information is available, resolve with the symbol info
                    if (symbolInfo.last_price === undefined) symbolInfo.last_price = symbolInfo.previous_closing_price;
                    resolve(symbolInfo);
                } else {
                    // If no price information is available, resolve with a default object
                    resolve({ symbol: symbol, orderbook_state: 'not_available' } as PriceInfo);
                }
                // Unsubscribe from the updates after a short delay
                subscriptionPromise.then(subscription => setTimeout(() => subscription.unsubscribe(), 500));
            }));
        });
    };


    // Set default columns and initialize state
    const columnVisibilityModel = {};
    WatchListColumns.forEach(({ name, defaultHidden }) => { if (defaultHidden) columnVisibilityModel[name] = false });
    const state = tableState === undefined ? { columns: { columnVisibilityModel } } : tableState;
    if (tableState === undefined) saveState(state);
    React.useEffect(() => {
        if (apiRef.current !== null) {
            apiRef.current.restoreState(state);
        }
    });

    // Trigger update on listSymbol change
    React.useEffect(() => {
        updateRows([]);
        updateList(props.list);
    }, [props.list]);

    // Save state logic
    function onChange() {
        const state = apiRef.current.exportState() as GridInitialStatePro;
        delete state['preferencePanel'];
        saveState(state);
    }

    // Reset selection on row selection change
    useEffect(() => apiRef.current.selectRows([], true, true), [rowsSelectable]);
    const [valueMemo] = React.useState({});
    const [columns] = React.useState<GridColDef[]>(WatchListColumns.map(({ name, dataType }) => {
        return {
            headerClassName: 'mui-header',
            cellClassName: 'mui-cell',
            hideable: name !== 'symbol',
            field: name,
            type: dataType === undefined ? 'number' : dataType,
            headerName: TranslationManager.getTranslation().Columns[name],
            renderCell: (params: GridRenderCellParams) => {
                const row = params.row; const symbol = row['symbol'];

                if (!(symbol in valueMemo)) valueMemo[symbol] = {};
                if (!(name in valueMemo[symbol])) valueMemo[symbol][name] = { prevValue: row[name], lastAnimationID: 1 };
                const { prevValue, lastAnimationID } = valueMemo[symbol][name];
                valueMemo[symbol][name] = { prevValue: row[name], lastAnimationID: 1 - lastAnimationID };
                return (<div className={cx(`watchTable-cell table-cell-${name}`, {})}>
                    {formatWatchListCell(name, row, prevValue, lastAnimationID, true)}
                </div>);
            },
            valueGetter: (params) => {
                if (name === 'change') return params.row['last_price_diff_closing_percent'];
                else return params.value;
            }
        };
    }));

    const handleBuySellContextMenu = () => {
        const isBidColumn = selectedColumn === 'bid_price' || selectedColumn === 'bid_quantity';
        const isAskColumn = selectedColumn === 'ask_price' || selectedColumn === 'ask_quantity';

        if (isBidColumn) {
            return (selectedRow?.bid_price && selectedRow?.bid_quantity && selectedRow.bid_price !== 0 && selectedRow.bid_quantity !== 0) ?
                <OrderMenu side='bid' quantity={selectedRow.bid_quantity} price={selectedRow.bid_price.toString()} /> :
                <DefaultOrderMenu />;
        }

        if (isAskColumn) {
            return (selectedRow?.ask_price && selectedRow?.ask_quantity && selectedRow.ask_price !== 0 && selectedRow.ask_quantity !== 0) ?
                <OrderMenu side='ask' quantity={selectedRow.ask_quantity} price={selectedRow.ask_price.toString()} /> :
                <DefaultOrderMenu />;
        }

        return null;
    };


    return (<>
        <DataGridPro
            ref={ref}
            apiRef={apiRef}
            rows={rows}
            columns={columns}
            /* this is for the resize button to work better*/
            /* could be conditionally toggled for better performance*/
            columnBuffer={100}
            /*disableVirtualization*/
            rowHeight={25}
            columnHeaderHeight={25}
            throttleRowsMs={500}
            onSortModelChange={onChange}
            onColumnWidthChange={onChange}
            onColumnOrderChange={onChange}
            onColumnVisibilityModelChange={onChange}
            sx={{
                border: 'none',
                "& .MuiDataGrid-row:hover": {
                    backgroundColor: "var(--blue-100)"
                }
            }}
            disableRowSelectionOnClick={!rowsSelectable}
            onRowClick={onRowClick}
            hideFooter
            slots={{
                columnSortedAscendingIcon: MyColumnSortedAscendingIcon,
                columnSortedDescendingIcon: MyColumnSortedDescendingIcon,
                noRowsOverlay: MyNoRowsOverlay,
                columnMenu: ColumnMenu({ saveState, ref, state }),
                row: (class MyRow extends Component<any> {
                    subscriptions: Subscription[];
                    hasUnmounted: boolean;
                    constructor(props) {
                        super(props);
                        this.subscriptions = [];
                        this.hasUnmounted = false;
                    }
                    componentDidMount(): void {
                        const symbol = this.props.rowId;
                        let row = {};
                        LMDInterface.getTradeableInfo(symbol).then(tradeableInfo => {
                            row = { ...row, ...tradeableInfo, id: symbol };
                            if (apiRef.current !== null && !this.hasUnmounted) apiRef.current.updateRows([row]);
                        });

                        this.subscriptions.push(getHandler('price_info').subscribe(symbol, symbolInfo => {
                            row = { ...row, ...symbolInfo, id: symbol };
                            if (apiRef.current !== null && !this.hasUnmounted) apiRef.current.updateRows([row]);
                        }));
                    }
                    componentWillUnmount(): void {
                        this.hasUnmounted = true;
                        // const symbol = this.props.rowId;
                        // In the event of closing many tabs really fast this function does
                        // not always seem to trigger, causing a memory leak
                        setTimeout(() => this.subscriptions.forEach(subscription => subscription.unsubscribe()), 1000);
                    }
                    render(): React.ReactNode { return renderGridRow(this.props) }
                }),
            }}

            slotProps={{
                cell: {
                    onContextMenu: (event: React.MouseEvent) => {
                        event.preventDefault();

                        const columnIndex = event.currentTarget.getAttribute("data-field");
                        if (columnIndex) {
                            // Get the column details using the column index
                            const column = apiRef.current.getColumn(columnIndex);
                            setSelectedColumn(column.field);
                        }
                        // setSelectedSymbol(id as string);
                    },
                },
                row: {
                    onContextMenu: (event: React.MouseEvent) => {
                        event.preventDefault();
                        setContextMenuPosition({ x: event.clientX - 2, y: event.clientY - 4 });
                        const id = event.currentTarget.getAttribute("data-id");
                        setSelectedSymbol(id);

                        const rowData = apiRef.current.getRowParams(id as string).row;
                        setSelectedRow(rowData);
                    },
                    style: { cursor: 'context-menu' },
                },
            }}
        />
        <Menu
            elevation={2}
            open={contextMenuPosition !== null}
            onClose={() => setContextMenuPosition(null)}

            anchorReference="anchorPosition"
            anchorPosition={contextMenuPosition !== null ? { top: contextMenuPosition.y, left: contextMenuPosition.x } : undefined}

            slotProps={{
                root: {
                    onContextMenu: (e) => {
                        e.preventDefault();
                        setContextMenuPosition(null);
                    }
                }
            }}
        >
            <div className='menu-symbol'>
                <span>{selectedSymbol}</span>
            </div>
            {
                AppStateManager.hasAccessToPDS || AppStateManager.hasAccessToRetail.getValue() && handleBuySellContextMenu()
            }
            {
                AppStateManager.hasAccessToPDS &&
                <MenuItem
                    onClick={() => {
                        if (selectedSymbol !== null) {
                            setNewOrderModalOpen(true);
                        }
                        setContextMenuPosition(null);
                    }}>
                    <FontAwesomeIcon icon={faMoneyCheckPen} style={{ marginRight: 8 }} />
                    {TranslationManager.getTranslation().WatchList.RightClickTableMenu.NewOrder}
                </MenuItem>
            }
            <MenuItem
                onClick={() => {
                    if (selectedSymbol !== null) openPropertiesOfSymbol(selectedSymbol);
                    setContextMenuPosition(null);
                }}>
                <FontAwesomeIcon icon={faGear} style={{ marginRight: 8 }} />
                {TranslationManager.getTranslation().WatchList.RightClickTableMenu.OrderbookProperties}
            </MenuItem>

            <MenuItem
                onClick={() => {
                    GLWindowManager.addComponentToRoot('MarketDepth', 'left', { symbol: selectedSymbol });
                    setContextMenuPosition(null);
                }}>
                <FontAwesomeIcon icon={faLayerGroup} style={{ marginRight: 8 }} />
                {TranslationManager.getTranslation().WatchList.RightClickTableMenu.MarketDepth}
            </MenuItem>

            <MenuItem
                onClick={() => {
                    GLWindowManager.addComponentToRoot('Chart', 'left', { symbol: selectedSymbol });
                    setContextMenuPosition(null);
                }}>
                <FontAwesomeIcon icon={faChartLine} style={{ marginRight: 8 }} />
                {TranslationManager.getTranslation().WatchList.RightClickTableMenu.Chart}
            </MenuItem>
        </Menu>
        <NewOrderModal
            open={newOrderModalOpen}
            onClose={() => {
                setNewOrderModalOpen(false);
            }}
            initialSymbol={selectedSymbol}
        />
    </>);
}


const OrderMenuItem: React.FC<{
    side: string,
    quantity?: number,
    price?: number,
    label: string,
    color: string,
    selectedSymbol?: string,
    setContextMenuPosition?: (e: null) => void
}> = ({
    side,
    quantity,
    price,
    label,
    color,
    selectedSymbol,
    setContextMenuPosition }) => {
        return (
            <MenuItem onClick={() => {
                openDefaultModal(
                    <NewOrderModal
                        side={side}
                        initialSymbol={selectedSymbol}
                        quantity={quantity}
                        price={price?.toString()}
                        open={true} onClose={hideDefaultModal}
                    />
                );
                setContextMenuPosition && setContextMenuPosition(null)
            }
            }>
                <span style={{ color }}>{label}</span>
            </MenuItem >
        )
    };

const OrderMenu = ({ side, quantity, price }) => {
    return (
        <div style={{
            borderBottom: '1px solid var(--dark-100)',
            borderTop: '1px solid var(--dark-100)',
            margin: '4px 0'
        }}>
            <OrderMenuItem
                side='buy'
                quantity={quantity}
                price={price}
                label={TranslationManager.getTranslation().Basic.Buy + ' ' + formatNumber(quantity) + '@' + cleanValueWithTickerFormatting(Number(price)) + (side !== 'ask' ? ' - ' + TranslationManager.getTranslation().Basic.Fill.toLocaleUpperCase() : '')}
                color='var(--green-600)'
            />
            <OrderMenuItem
                side='sell'
                quantity={quantity}
                price={price}
                label={TranslationManager.getTranslation().Basic.Sell + ' ' + formatNumber(quantity) + '@' + cleanValueWithTickerFormatting(Number(price)) + (side !== 'bid' ? ' - ' + TranslationManager.getTranslation().Basic.Fill.toLocaleUpperCase() : '')}
                color='var(--red-600)'
            />
        </div>
    );
}

const DefaultOrderMenu = () => (
    <div style={{
        borderBottom: '1px solid var(--dark-100)',
        borderTop: '1px solid var(--dark-100)',
        margin: '4px 0'
    }}>
        <OrderMenuItem
            side='buy'
            label={TranslationManager.getTranslation().Basic.Buy}
            color='var(--green-600)'
        />
        <OrderMenuItem
            side='sell'
            label={TranslationManager.getTranslation().Basic.Sell}
            color='var(--red-600)'
        />
    </div>
);

function renderGridRow(props) {
    return <GridRow {...props} />;
}