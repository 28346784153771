// Package imports:
import React, { useState } from 'react';
import cx from 'classnames';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TranslationManager } from '../../Translation/Translation';
import ClearInputIcon from './ClearInputIcon';
// Service imports:

type InputType = 'input' | 'textArea' | 'password' | 'number' | 'numberFormat';
type InputSizes = 'xsm' | 'sm' | 'lg';
type TextAlign = 'left' | 'center' | 'right';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;
type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;
interface IOwnProps {
    inputType?: InputType,
    inputSize?: InputSizes,
    textAlign?: TextAlign,
    label?: string,
    shortLabel?: string,
    visible?: boolean,
    toggleVisible?(): void,
    currency?: string,
    fullWidth?: boolean,
    decimalScale?: number,
    withClearIcon?: boolean,
    hasError?: boolean,
    fakeDisabled?: boolean,
};

type Props = IOwnProps & InputProps & TextAreaProps;

const Input: React.FC<Props> = ({
    // Custom props
    inputType = 'input',
    inputSize = 'lg',
    textAlign = 'left',
    label,
    shortLabel,
    currency,
    fullWidth,

    // Element props
    id,
    className,
    style,
    onFocus,
    onBlur,
    value,
    decimalScale,

    withClearIcon,

    visible,
    toggleVisible,

    hasError = false,

    ...props
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const focus = () => setIsFocused(true);
    const blur = () => setIsFocused(false);

    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => setShowPassword(!showPassword);

    // Props that overwrite native element props.
    const elementProps = {
        id,
        value,
        style: {
            ...style,
            textAlign: textAlign
        },
        onFocus: (e: React.FocusEvent<HTMLInputElement & HTMLTextAreaElement, Element>) => {
            if (onFocus) onFocus(e);
            focus();
        },
        onBlur: (e: React.FocusEvent<HTMLInputElement & HTMLTextAreaElement, Element>) => {
            if (onBlur) onBlur(e);
            blur();
        }
    }

    const getLabel = () => (
        label && (
            <label
                className="input__label"
                htmlFor={id}
            >
                <span className="main-label">{label}</span>
                <span className="short-label">{shortLabel ?? label}</span>
            </label>
        )
    )

    const renderInput = () => {
        switch (inputType) {
            case 'numberFormat':
                return (
                    <>
                        <NumericFormat
                            {...elementProps}
                            {...(props as NumericFormatProps)}
                            value={value as string}
                            thousandSeparator={TranslationManager.getActiveLanguage() === 'EN' ? "," : "."}
                            decimalSeparator={TranslationManager.getActiveLanguage() === 'EN' ? "." : ","}
                            allowedDecimalSeparators={[',', '.']}
                            decimalScale={decimalScale}
                        />
                        {getLabel()}
                    </>
                );
            case 'input':
                return (
                    <>
                        <input
                            {...elementProps}
                            {...(props as InputProps)}
                        />
                        {
                            withClearIcon && value &&
                            <ClearInputIcon isFocused={isFocused} size="20px" style={{
                                position: 'absolute',
                                right: '16px',
                                top: '23px'
                            }} onClick={function (): void {
                                if (props.onChange) {
                                    const event = {
                                        ...new Event('input', { bubbles: true }),
                                        target: { value: '' }
                                    };
                                    props.onChange(event as unknown as React.ChangeEvent<HTMLInputElement>);
                                }
                            }} />
                        }
                    </>
                );
            case 'number':
                return (
                    <input
                        type="number"
                        {...elementProps}
                        {...(props as InputProps)}
                    />
                );
            case 'password':
                return (
                    <>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            {...elementProps}
                            {...(props as InputProps)}
                        />
                        {
                            (elementProps.value !== '') && <>
                                <FontAwesomeIcon icon={faEyeSlash} className={cx("icon-fa", "eye-icon", { 'visible': !showPassword })} onClick={togglePassword} />
                                <FontAwesomeIcon icon={faEye} className={cx("icon-fa", "eye-icon", { 'visible': showPassword })} onClick={togglePassword} />
                            </>
                        }
                    </>
                );
            default:
                return (
                    <textarea
                        {...elementProps}
                        {...(props as TextAreaProps)}
                    />
                );
        }
    }

    return (
        <div className={cx('KCL_input', inputSize, className, {
            'is-focused': isFocused,
            'has-value': (value !== undefined && value !== null && value.toString().length > 0),
            'is-disabled': props.disabled || props.fakeDisabled,
            'currency_input': currency,
            'full-width': fullWidth,
            'has-error': hasError
        })}>
            {renderInput()}
            {label && (
                <label
                    className="input__label"
                    htmlFor={id}
                >
                    <span className="main-label">{label}</span>
                    <span className="short-label">{shortLabel ?? label}</span>
                </label>
            )}
        </div>
    );
}

export default (Input);